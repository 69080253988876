body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.react-spinner {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 50%;
    left: 50%;
    z-index: 2000000;
  }
  
  .react-spinner_bar {
    -webkit-animation: react-spinner_spin 1.2s linear infinite;
    -moz-animation: react-spinner_spin 1.2s linear infinite;
    animation: react-spinner_spin 1.2s linear infinite;
    border-radius: 5px;
    background-color: black;
    position: absolute;
    width: 22px;
    height: 5px;
    top: -3.9%;
    left: -10%;
  }
  
  @keyframes react-spinner_spin {
   0% { opacity: 1; }
   100% { opacity: 0.15; }
  }
  
  @-moz-keyframes react-spinner_spin {
   0% { opacity: 1; }
   100% { opacity: 0.15; }
  }
  
  @-webkit-keyframes react-spinner_spin {
   0% { opacity: 1; }
   100% { opacity: 0.15; }
  }
