@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300);
/* html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  font-family: 'Open Sans', sans-serif;
}
body.green {
  background-color: #26ac82;
} */

#bg {
    background: #dce6e7;
    /* Old browsers */
    background: -moz-linear-gradient(top, #dce6e7 0%, #afbcc5 100%);
    /* FF3.6+ */
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, #dce6e7),
        color-stop(100%, #afbcc5)
    );
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #dce6e7 0%, #afbcc5 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #dce6e7 0%, #afbcc5 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #dce6e7 0%, #afbcc5 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #dce6e7 0%, #afbcc5 100%);
    /* W3C */
    -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#dce6e7', endColorstr='#afbcc5', GradientType=0);
    /* IE6-9 */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.mg-0 {
    margin: 0;
}

.gcn-table {
    width: 100%;
}
.gcn-table tr:nth-child(even) {
    background-color: #f0f8ff;
}
.gcn-table tr:nth-child(odd) {
    background-color: #e6f3ff;
}

.gs-col-header {
    background-color: #3cd3d5 !important;
    text-align: center !important;
}

.gs-odd-row {
    background-color: #f0f8ff;
    text-align: center !important;
}

.gs-even-row {
    background-color: #e6f3ff;
    text-align: center !important;
}

.gcn-bg {
    background-color: #2f3e4c;
}

.no-click {
    pointer-events: none;
}

.greyed-out,
.es-datetime--greyed-out,
#timezone-selector .tz-greyed-out {
    opacity: 0.2;
}
.greyed-out.no-click,
.no-click.es-datetime--greyed-out,
#timezone-selector .no-click.tz-greyed-out {
    pointer-events: none;
}

.underline {
    text-decoration: underline;
}

.legend-greyed-out {
    opacity: 0.5;
}

h2,
h3,
h4,
h5,
h6 {
    color: #fff;
    font-weight: 100;
    margin: 0;
}

.selected {
    background-color: #6e8ba6;
    border-radius: 1px;
}

.selected-optimizer {
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid #3cd3d5;
}

.pdt-5 {
    padding-top: 5px;
}

.pdl-20 {
    padding-left: 20px;
}

.pd-10 {
    padding: 10px;
}

.pd-3 {
    padding: 3px;
}

.pd-0 {
    padding: 0;
}

.fw-500 {
    font-weight: 500;
}

.fw-400 {
    font-weight: 400;
}

.fw-100 {
    font-weight: 100;
}

.fsz-40 {
    font-size: 2.5em;
}

.fsz-15 {
    font-size: 15px;
}

.fsz-14 {
    font-size: 14px;
}

.fsz-13 {
    font-size: 13px;
}

.fsz-12 {
    font-size: 12px;
}

.fsz-11 {
    font-size: 11px;
}

.clr-blue {
    color: #31d7d9;
}

.lh-3 {
    line-height: 3em;
}

.mt-7 {
    margin-top: 7px;
}

.mt-5 {
    margin-top: 5px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-5 {
    margin-right: 5px;
}

.ml-8 {
    margin-left: 8px;
}

.fl {
    float: left;
}

.italic {
    font-style: italic;
}

.clickable {
    cursor: pointer;
}

.right,
.vitals-main .arrow.right-expanded-view,
#carousel-container .arrow.right-expanded-view,
#carousel-container .right-expanded-view.arrow-expanded,
#vpp-ops-overview-graph-container .arrow.right-expanded-view,
#vitals-health-container .arrow.right-expanded-view {
    text-align: right;
}

.center {
    text-align: center !important;
}

.white {
    color: white;
}

.black {
    color: black;
}

.gray {
    color: gray;
}

.red-border {
    border: 1px solid red !important;
}

.pie-chart-negative-value {
    stroke: red;
    stroke-dasharray: 5;
}

.red {
    color: red;
}

.red.lighten {
    color: #ff6666;
}

.black.lighten {
    color: #333333;
}

.bg-green {
    color: #26ac82;
}

.clear {
    clear: both;
}

.has-error {
    border: 1px solid #ff0000 !important;
}

.disable-button {
    display: inline-block;
    background-color: #a3a8b5;
    padding: 0px 10px;
}

.enable-button {
    display: inline-block;
    background-color: #26ac82;
    padding: 0px 10px;
}

.text-left {
    text-align: left;
}

.bold {
    font-weight: 600;
}

/* .dim-gray {
  background-color: #4d6573;
} */

.hide-scroll-bar {
    overflow: hidden;
}

a.colSort {
    text-decoration: none;
    color: #ffffff;
}

p.success {
    color: #26ac82;
    font-size: 14px;
    margin: 10px 0 0 0;
}

.validation-error__text {
    font-size: 0.7em;
}
.validation-error__text--red {
    color: #ff0000;
}

.no-permission__text {
    text-align: center;
    vertical-align: middle;
    font-size: 3.5rem;
    color: white;
}

#header-container {
    height: 100%;
}

#content-container {
    height: 100%;
}

#footer-container {
    height: 100%;
}

.input__no_margin input[type="text"] {
    margin: 0;
}

.input__search input[type="text"] {
    background-image: url("../img/icons/search.png");
    background-repeat: no-repeat;
    background-position: 0 4px;
    background-color: #d5d8db;
}

.selected-selector {
    background-color: #aed9ff !important;
    text-align: center !important;
}
.selected-selector__selected {
    border-top: 1px solid #001324 !important;
    border-left: 1px solid #001324 !important;
    border-bottom: 1px solid #001324 !important;
    border-right: 1px solid #001324 !important;
    background-color: #aed9ff !important;
    text-align: center !important;
}
.selected-selector__first {
    text-align: center !important;
    background-color: #aed9ff !important;
    border-top: 1px solid #001324 !important;
    border-left: 1px solid #001324 !important;
    border-bottom: 1px solid #001324 !important;
}
.selected-selector__mid {
    background-color: #aed9ff !important;
    text-align: center !important;
    border-top: 1px solid #001324 !important;
    border-bottom: 1px solid #001324 !important;
}
.selected-selector__last {
    background-color: #aed9ff !important;
    text-align: center !important;
    border-top: 1px solid #001324 !important;
    border-right: 1px solid #001324 !important;
    border-bottom: 1px solid #001324 !important;
}

.export-excel {
    width: 100%;
    height: 30px;
    border-radius: 4px 0 0 4px;
    background-color: inherit;
    margin-right: 0px;
    background-image: url("../img/icons/Excel-off.png");
    background-repeat: no-repeat;
    background-position: center;
}
.export-excel.on {
    background-image: url("../img/icons/Excel-on.png");
    cursor: pointer;
}

.readonly-bg {
    background-color: #e5e5e5;
    opacity: 0.6;
}

.right-to-left li {
    float: right;
}

.top-gap {
    margin-top: 0.5em;
}

.bottom-gap {
    margin-bottom: 0.5em;
}

.algn-right {
    text-align: right;
}

.gs-tooltip {
    width: 100px;
}
.gs-tooltip--large {
    width: 200px;
}

.pmtWizard-tooltip {
    width: 200px;
}

.proposals-tooltip {
    width: 100px;
}

.tooltip.customClass,
.tooltip.tooltip-inner {
    color: #ffffff;
    text-align: left;
}
.tooltip.wide,
.tooltip.tooltip-inner {
    white-space: pre;
    color: #ffffff;
    text-align: left;
    max-width: 650px !important;
    width: 650px;
    background-color: black;
}

.bottom-border-transparent {
    border-bottom: 1px solid transparent;
}

.st-sort-ascent:before {
    content: "\25B2";
}

.st-sort-descent:before {
    content: "\25BC";
}

.st-selected {
    background: #32cc8d !important;
    color: white !important;
}

.gs-popover {
    text-decoration: none;
    color: black;
    position: absolute;
    left: 100%;
    top: 0;
    display: block;
    padding: 0 20px;
    width: 200px;
}

.gs-hide-show {
    -webkit-transition: 1x linear all;
    transition: 1s linear all;
}

.gs-button {
    background-color: white;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    line-height: 1.428;
    text-align: center;
    vertical-align: middle;
    padding: 6px 12px;
    border-radius: 4px;
    border: 1px solid #ccc;
    cursor: pointer;
}
.gs-button:hover {
    background-color: lightgrey;
}
.gs-button.active {
    background-color: lightgrey;
}

.delete-button {
    background-color: #d33611;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 1em;
    font-weight: 300;
    padding: 10px 20px;
    -webkit-appearance: none;
}
.delete-button:hover {
    background-color: #a42a0d;
}

/* .green-button {
  background-color: #26AC82;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 1.0em;
  font-weight: 300;
  padding: 10px 20px;
  -webkit-appearance: none;
}
.green-button:hover {
  background-color: #1d8262;
}
.green-button--std-width {
  width: 145px;
}

.grey-button {
  background-color: #a3a8b5;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 1.0em;
  font-weight: 300;
  padding: 10px 20px;
  -webkit-appearance: none;
}
.grey-button:hover {
  background-color: #848a9c;
}

.red-button {
  background-color: red;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 1.0em;
  font-weight: 300;
  padding: 10px 20px;
  -webkit-appearance: none;
}
.red-button:hover {
  background-color: #cc0000;
}
 */
.gcn-legend {
    cursor: pointer;
    border: none;
    background-color: #ffffff;
    font-size: 1.4rem;
    font-weight: bolder;
}
.gcn-legend__inactive {
    opacity: 0.2;
}
.gcn-legend__active {
    border: 1px solid #ccc;
    border-radius: 10px !important;
}

.total-count {
    background-color: #4d6573;
    height: 5%;
}

.arrow-right {
    margin-top: 23px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid black;
}

.form-horizontal .radio {
    padding-top: 0;
}

.ng-hide {
    display: none !important;
}

[uib-typeahead-popup].dropdown-menu {
    display: block;
}

/* ngIf animation */
.animationIf.ng-enter,
.animationIf.ng-leave {
    -webkit-transition: opacity ease-in-out 1s;
    -moz-transition: opacity ease-in-out 1s;
    -ms-transition: opacity ease-in-out 1s;
    -o-transition: opacity ease-in-out 1s;
    transition: opacity ease-in-out 1s;
}

.animationIf.ng-enter,
.animationIf.ng-leave.ng-leave-active {
    opacity: 0;
}

.animationIf.ng-leave,
.animationIf.ng-enter.ng-enter-active {
    opacity: 1;
}

@-webkit-keyframes slide-right {
}
@-webkit-keyframes slide-left {
    0% {
        right: 0;
    }
    100% {
        top: 0;
    }
}
/* Chrome, Safari, Opera */
@-webkit-keyframes slide-up {
}
/* Standard syntax */
/* Chrome, Safari, Opera */
@-webkit-keyframes slide-down {
}
/* Standard syntax */
.gcn-button {
    background-color: #3cd3d5;
    border-radius: 5px;
    padding: 6%;
    font-size: 17px;
    font-weight: bold;
    color: black;
    text-align: center;
}

.gcn-checkbox {
    width: 20px;
    position: relative;
    margin: 20px auto;
}
.gcn-checkbox label {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    background: #fcfff4;
    background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
    border-radius: 4px;
    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.gcn-checkbox label:after {
    content: "";
    width: 9px;
    height: 5px;
    position: absolute;
    top: 4px;
    left: 4px;
    border: 3px solid #333;
    border-top: none;
    border-right: none;
    background: transparent;
    opacity: 0;
    transform: rotate(-45deg);
}
.gcn-checkbox label:hover::after {
    opacity: 0.5;
}
.gcn-checkbox input[type="checkbox"] {
    visibility: hidden;
}
.gcn-checkbox input[type="checkbox"]:checked + label:after {
    opacity: 1;
}

.gcn-badge {
    display: inline-block;
    min-width: 30px;
    padding: 5px 5px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.5;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: black;
    border-radius: 15px;
    margin-bottom: 5px;
    cursor: pointer;
}

select.gcn-ft-size {
    font-size: 0.75rem;
    padding: 0.2rem;
}

.opaque_modal-backdrop {
    opacity: 1 !important;
}

/* ----------- standard monitor size 1920x1080 ----------- */
/* ----------- standard monitor size 1920x1080 ----------- */
.gc-tbl-container {
    padding: 0;
    transition: height 500ms ease;
    -ms-transition: height 500ms ease;
    -moz-transition: height 500ms ease;
    border: none;
    font-family: "Open Sans", sans-serif;
}
.gc-tbl-container .gc-fixed-header {
    position: fixed;
    margin: auto;
    z-index: 100000;
    width: 100%;
}
.gc-tbl-container .gc-col-header {
    font-size: 1.2em;
    border: 1px solid #ccc;
    padding-top: 0.5%;
    text-align: center;
    color: #fff;
    background-color: #3cd3d5;
}
.gc-tbl-container .gc-row {
    margin-top: 3.3%;
}
.gc-tbl-container .gc-col-title {
    color: #fff;
}
.gc-tbl-container .gc-col-data {
    font-size: 15px;
    font-weight: 400;
    border-top: 1px solid #acc5c5;
    border-left: 1px solid #acc5c5;
    border-bottom: 1px solid #acc5c5;
    text-align: center;
    background-color: #eaf0f0;
}
.gc-tbl-container .selected {
    background-color: #32cc8d !important;
}

.proj-tbl-container {
    padding: 0;
    transition: height 500ms ease;
    -ms-transition: height 500ms ease;
    -moz-transition: height 500ms ease;
    border: none;
    font-family: "Open Sans", sans-serif;
}
.proj-tbl-container table {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.proj-tbl-container table * {
    box-sizing: inherit;
    -moz-box-sizing: inherit;
}
.proj-tbl-container thead {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: #3cd3d5 !important;
}
.proj-tbl-container tbody {
    display: inline-block;
}
.proj-tbl-container thead > tr,
.proj-tbl-container tbody > tr,
.proj-tbl-container tfoot > tr {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.proj-tbl-container thead tr th {
    width: 100%;
    color: #fff !important;
    font-size: 0.9em !important;
    text-align: center;
    border: 1px solid #ccc;
    font-weight: 400 !important;
}
.proj-tbl-container thead,
.proj-tbl-container tfoot {
    flex-shrink: 0;
}
.proj-tbl-container th,
.proj-tbl-container tbody td {
    text-overflow: ellipsis;
    display: inline-block;
    text-align: center;
}
.proj-tbl-container tbody {
    display: inline-block;
}
.proj-tbl-container tbody tr td {
    width: 100%;
    border-right: 0.5px solid #ccc;
    font-size: 0.8em !important;
}
.proj-tbl-container tbody tr:nth-child(even) {
    background-color: #f0f8ff;
}
.proj-tbl-container tbody tr:nth-child(odd) {
    background-color: #e6f3ff;
}
.proj-tbl-container tfoot {
    display: inline-block;
}
.proj-tbl-container tfoot td {
    width: 100%;
    display: inline-block;
}

.gs-flag-btn {
    position: absolute;
    top: 3px;
    left: 2px;
    border-radius: 50%;
    padding: 3px;
}

.gs-flag-btn-edit {
    visibility: hidden;
}

.gs-flag-btn-off {
    color: lavenderblush;
    visibility: hidden;
}

.selected-selector__selected:hover .gs-flag-btn-display,
.selected-selector__last:hover .gs-flag-btn-display {
    visibility: hidden;
}
.selected-selector__selected:hover .gs-flag-btn-edit,
.selected-selector__last:hover .gs-flag-btn-edit {
    visibility: visible;
    cursor: pointer;
}
.selected-selector__selected:hover .gs-flag-btn,
.selected-selector__last:hover .gs-flag-btn {
    cursor: pointer;
}
.selected-selector__selected:hover .gs-flag-btn:hover,
.selected-selector__last:hover .gs-flag-btn:hover {
    background: #76bbf7;
}
.selected-selector__selected:hover .gs-flag-btn-off:hover,
.selected-selector__last:hover .gs-flag-btn-off:hover {
    background: #76bbf7;
}

.ui-grid-cell-contents_withflag {
    position: relative;
}
.ui-grid-cell-contents_withflag .ui-grid-cell-contents {
    margin-left: 25px;
}

/* .status_bar, #header_container .status_bar-small, #vpp-header-container .status_bar-small {
  background-color: #FFFFFF;
  border-bottom: 1px solid #c3d3d3;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  color: #96b6b6;
  line-height: 3.6em;
}
.status_bar--primary {
  color: #3cd3d5;
  cursor: pointer;
}
.status_bar .settings, #header_container .status_bar-small .settings, #vpp-header-container .status_bar-small .settings {
  color: #3cd3d5;
} */

.status_bar,
#header_container .status_bar-small {
    background-color: #ffffff;
    border-bottom: 1px solid #c3d3d3;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    color: #96b6b6;
    line-height: 3.6em;
}
.status_bar--primary {
    color: #3cd3d5;
    cursor: pointer;
}
.status_bar .settings,
#header_container .status_bar-small .settings {
    color: #3cd3d5;
}

#header_container .portfolio-icons-container {
    display: flex;
    justify-content: flex-end;
}
#header_container .portfolio-icons-container .portfolio-icon {
    flex: 1 0 99px;
}
#header_container .project {
    background-image: url("../img/icons/project_icon.png");
    background-repeat: no-repeat;
    background-position: center center;
    width: 99px;
    height: 60px;
    text-align: center;
    color: #323232;
    font-size: 11px;
    font-weight: 400;
    padding-top: 31%;
}
#header_container .project.active,
#header_container .project:hover {
    background-image: url("../img/icons/project_icon_white.png");
    background-color: #3cd3d5;
}
#header_container .site {
    background-image: url("../img/icons/site_icon.png");
    background-repeat: no-repeat;
    background-position: center center;
    width: 99px;
    height: 60px;
    text-align: center;
    color: #323232;
    font-size: 11px;
    font-weight: 400;
    padding-top: 31%;
}
#header_container .site.active,
#header_container .site:hover {
    background-image: url("../img/icons/site_icon_white.png");
    background-color: #3cd3d5;
}
#header_container .meter {
    float: left;
    background-image: url("../img/icons/meter_icon.png");
    background-repeat: no-repeat;
    background-position: center center;
    width: 99px;
    height: 60px;
    text-align: center;
    color: #323232;
    font-size: 11px;
    font-weight: 400;
    padding-top: 31%;
}
#header_container .meter.active,
#header_container .meter:hover {
    background-image: url("../img/icons/meter_icon_white.png");
    background-color: #3cd3d5;
}
#header_container select#soflow,
#header_container select#soflow-color {
    -webkit-appearance: button;
    -webkit-border-radius: 2px;
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    -webkit-padding-end: 20px;
    -webkit-padding-start: 2px;
    -webkit-user-select: none;
    background-image: url("../img/header/white_downpointing_triangle.png"),
        -webkit-linear-gradient(#fafafa, #f4f4f4 40%, #e5e5e5);
    background-position: 97% center;
    background-repeat: no-repeat;
    border: 0px solid #aaa;
    background-color: #3cd3d5;
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 214px;
    height: 1.8em;
    padding: 0;
}
#header_container select#soflow-color {
    color: #fff;
    background-image: url("../img/header/white_downpointing_triangle.png");
    background-color: #3cd3d5;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}
#header_container .status_bar-small {
    height: 2.5em;
}

.header,
.header-small {
    background: #3cd3d5;
    /* Old browsers */
    background: -moz-linear-gradient(top, #3cd3d5 0%, #31c5c8 100%);
    /* FF3.6+ */
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, #3cd3d5),
        color-stop(100%, #31c5c8)
    );
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #3cd3d5 0%, #31c5c8 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #3cd3d5 0%, #31c5c8 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #3cd3d5 0%, #31c5c8 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #3cd3d5 0%, #31c5c8 100%);
    /* W3C */
    -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3cd3d5', endColorstr='#31c5c8', GradientType=0);
    /* IE6-9 */
    color: #ffffff;
    height: 4.3em;
    padding: 0 30px;
}
.header__logo--full {
    padding: 5px 0;
}
.header__logo--truncated {
    display: none;
    margin-top: 20px;
}
.header__links {
    display: inline-block;
    list-style-type: none;
    margin: 0;
    padding: 0 0 0 30px;
    vertical-align: top;
}
.header__links li {
    background-repeat: no-repeat;
    border-right: 1px solid #5fd9db;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    height: 60px;
    text-align: center;
    width: 100px;
    margin-left: -4px;
    padding-top: 36px;
}
.header__links li:hover,
.header__links li.active {
    background-color: #303e4b;
}
.header__links li:first-child {
    border-left: 1px solid #5fd9db;
    margin-left: 0;
}
.header__links li.overview {
    background-image: url("../img/header/overview-off.png");
    background-position: center 15px;
}
.header__links li.overview.active,
.header__links li.overview:hover {
    background-image: url("../img/header/overview-on.png");
}
.header__links li.vitals {
    background-image: url("../img/header/vitals-off.png");
    background-position: center 12px;
}
.header__links li.vitals.active,
.header__links li.vitals:hover {
    background-image: url("../img/header/vitals-on.png");
}
.header__links li.billing {
    background-position: center 10px;
    background-image: url("../img/header/billing-off.png");
}
.header__links li.billing.active,
.header__links li.billing:hover {
    background-image: url("../img/header/billing-on.png");
}
.header__links li.portfolio {
    background-position: center 10px;
    background-image: url("../img/header/Portfolio-Off.png");
}
.header__links li.portfolio.active,
.header__links li.portfolio:hover {
    background-image: url("../img/header/Portfolio-On.png");
}
.header__links li.analysis {
    background-position: center 10px;
    background-image: url("../img/header/billing-off.png");
}
.header__links li.analysis.active,
.header__links li.analysis:hover {
    background-image: url("../img/header/billing-on.png");
}
.header__links li.support {
    background-position: center 10px;
    background-image: url("../img/header/support-off.png");
}
.header__links li.support.active,
.header__links li.support:hover {
    background-image: url("../img/header/support-on.png");
}
.header__menu {
    border-left: 1px solid #5fd9db;
    font-size: 16px;
    padding: 10px 34px 0;
}
.header__menu:hover {
    background-color: #5fd9db;
}
.header__menu__username {
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
}
.header__menu__email {
    font-size: 12px;
}
.header__menu p {
    margin: 0;
}
.header__menu p a {
    color: #fff;
    text-decoration: none;
}
.header__menu-small {
    border-left: 1px solid #5fd9db;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 16px;
    height: 60px;
    vertical-align: top;
}
.header__menu-small:hover {
    background-color: #5fd9db;
}
.header__menu-small__username {
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
}
.header__menu-small__email {
    font-size: 12px;
}
.header__menu-small p {
    margin: 0;
}
.header__menu-small p a {
    color: #fff;
    text-decoration: none;
}
.header .org-small,
.header-small .org-small {
    background-color: #3cd3d5;
}
.header .settings-small,
.header-small .settings-small {
    background-color: white;
}

.header-small {
    height: 2.8em;
    padding: 0;
}
.header-small__logo--full {
    padding: 0;
}
.header-small .tab-bar {
    height: inherit;
    background: #3cd3d5;
}
.header-small .tab-bar .left-small {
    height: 4.3em;
    top: inherit;
    padding-top: 1%;
    border-right: 1px solid white;
}
.header-small .left-off-canvas-menu {
    height: 11em;
    width: 11em;
    background: #3cd3d5;
}
.header-small .left-off-canvas-menu label {
    color: white;
    font-size: 1.3em;
    font-weight: 200;
}
.header-small .left-off-canvas-menu ul {
    list-style-type: none;
}
.header-small .left-off-canvas-menu ul li a {
    color: black;
    font-size: 1.1em;
    font-weight: 200;
}

.operationNavItem{
    width: 95px;
}

.vitalsGraphOn {
    background-image: url("../img/icons/icon-monitor-on.png");
}

.vitalsGraphOff {
    background-image: url("../img/icons/icon-monitor.png");
}

.freqOn {
    background-image: url("../img/icons/icon-freq-on.png");
}

.freqOff {
    background-image: url("../img/icons/icon-freq-off.png");
}

.itcOn {
    background-image: url("../img/icons/icon-freq-on.png");
}

.itcOff {
    background-image: url("../img/icons/icon-freq-off.png");
}

.siteDetailsOn {
    background-image: url("../img/icons/icon-details-on.png");
}

.siteDetailsOff {
    background-image: url("../img/icons/icon-details.png");
}

.financialOn {
    background-image: url("../img/icons/icon-billing-on.png");
}

.financialOff {
    background-image: url("../img/icons/icon-billing.png");
}

/* .scheduleOn {
  background-image: url("../img/icons/setpoint-on-big.png");
} */
/* 
.scheduleOff {
  background-image: url("../img/icons/setpoint-off-big.png");
} */

.healthOn {
    background-image: url("../img/icons/icon-health-on.png");
}

.healthOff {
    background-image: url("../img/icons/icon-health.png");
}

.alertsOn {
    background-image: url("../img/icons/icon-alerts-on.png");
}

.alertsOff {
    background-image: url("../img/icons/icon-alerts.png");
}

.scheduleOn {
    background-image: url("../img/icons/icon-schedule-on.png");
}

.scheduleOff {
    background-image: url("../img/icons/icon-schedule.png");
}

.flexForecastOn {
    background-image: url("../img/icons/icon-flex-forecast-on.png");
}

.flexForecastOff {
    background-image: url("../img/icons/icon-flex-forecast-off.png");
}

.forecastOn {
    background-image: url("../img/icons/icon-forecast-on.png");
}

.forecastOff {
    background-image: url("../img/icons/icon-forecast.png");
}

.performanceOn {
    background-image: url("../img/icons/icon-performance-on.png");
}

.performanceOff {
    background-image: url("../img/icons/icon-performance-off.png");
}

.projectsOn {
    background-image: url("../img/header/Portfolio-On.png");
}

.projectsOff {
    background-image: url("../img/header/Portfolio-Off.png");
}

.managerOn {
    background-image: url("../img/header/manager-on.png");
}

.managerOff {
    background-image: url("../img/header/manager.png");
}

.optimizerOn {
    background-image: url("../img/header/optimizer-on.png");
}

.optimizerOff {
    background-image: url("../img/header/optimizer.png");
}

.settingsOn {
    background-image: url("../img/icons/Settings-On-Big.png");
    background-color: #31d7d9;
}

.settingsOff {
    background-image: url("../img/icons/Settings-Off-Big.png");
}

.setpointsViewOn {
    background-image: url("../img/header/setpoint-settings-on.png");
    background-color: #31d7d9;
}

.setpointsViewOff {
    background-image: url("../img/header/setpoint-settings-off.png");
}

.mapOn {
    background-image: url("../img/header/icon-map-on.png");
}

.mapOff {
    background-image: url("../img/header/icon-map.png");
}

.barOn {
    background-image: url("../img/header/icon-savings-on.png");
}

.barOff {
    background-image: url("../img/header/icon-savings.png");
}

.supportOn {
    background-image: url("../img/header/support-on.png");
}

.supportOff {
    background-image: url("../img/header/support-off.png");
}

.support50On {
    background-image: url("../img/header/support-on-50.png");
}

.support50Off {
    background-image: url("../img/header/support-off-50.png");
}

.pieOn {
    background-image: url("../img/header/pie-on.png");
    background-color: #31d7d9;
}

.pieOff {
    background-image: url("../img/header/pie-off.png");
}

.measurer-grid-header {
    background-color: #2f3e4c;
}

.measurer-grid-billing-period-cell {
    color: #ffffff;
    background-color: #2f3e4c;
    text-align: left !important;
}

.measurer-grid-cell {
    color: #ffffff;
    background-color: #2f3e4c;
}

.measurer-row {
    border-bottom: 1px solid #dae1e1;
}

.measurer-total-row {
    border-top: 4px double #dae1e1;
}

.measurer-header-row {
    border-bottom: 4px double #dae1e1;
}

.stats,
.stats-medium {
    background-color: #f7f8fa;
    border-top: 1px solid #2fd2d1;
    color: #6c8e8d;
    position: fixed;
    bottom: 3.5em;
    left: 0;
    right: 0;
}
.stats .col-container,
.stats-medium .col-container {
    display: inline-block;
    text-align: left;
}
.stats .column,
.stats-medium .column {
    box-sizing: border-box;
    display: inline-block;
    padding: 10px 0;
    text-align: center;
    width: 33%;
}
.stats .title,
.stats-medium .title,
.stats #vpp-ops-container .right-panel .scrollable-panel .title,
#vpp-ops-container .right-panel .scrollable-panel .stats .title,
.stats-medium #vpp-ops-container .right-panel .scrollable-panel .title,
#vpp-ops-container .right-panel .scrollable-panel .stats-medium .title {
    font-size: 12px;
}
.stats .content,
.stats-medium .content {
    font-size: 25px;
}
.stats .content-medium,
.stats-medium .content-medium {
    font-size: 20px;
}
.stats .col1,
.stats-medium .col1 {
    border-right: 1px solid #d4dedf;
}
.stats .col2,
.stats-medium .col2 {
    border-right: 1px solid #d4dedf;
    margin-left: -5px;
}
.stats .col3,
.stats-medium .col3 {
    margin-left: -5px;
    width: 34%;
}

.stats-medium {
    bottom: 3.5em;
}

#overview_container {
    height: 100%;
}
#overview_container .subtoolbar {
    border-bottom: 1px solid #d4dedf;
    background-color: #f3f7f7;
    height: 50px;
}
#overview_container .subtoolbar h2 {
    color: #3cd3d5;
    margin: 0 0 0 30px;
    display: inline-block;
    font-weight: normal;
    padding: 0;
    line-height: 50px;
}
#overview_container .subtoolbar .title,
#overview_container
    .subtoolbar
    #vpp-ops-container
    .right-panel
    .scrollable-panel
    .title,
#vpp-ops-container
    .right-panel
    .scrollable-panel
    #overview_container
    .subtoolbar
    .title {
    color: #6c8e8d;
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
    font-weight: 700;
    line-height: 50px;
}
#overview_container .subtoolbar ul {
    float: right;
    list-style-type: none;
    margin: 0;
    padding: 0;
}
#overview_container .subtoolbar ul li {
    cursor: pointer;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
    height: 50px;
    width: 99px;
}
#overview_container .subtoolbar ul li:hover,
#overview_container .subtoolbar ul li.active {
    background-color: #3cd3d5;
}
#overview_container .subtoolbar ul li.map {
    background-image: url("../img/header/map-off.png");
}
#overview_container .subtoolbar ul li.map:hover,
#overview_container .subtoolbar ul li.map.active {
    background-image: url("../img/header/map-on.png");
}
#overview_container .subtoolbar ul li.bar,
#overview_container .subtoolbar ul #vpp-ops-container li.light-bar,
#vpp-ops-container #overview_container .subtoolbar ul li.light-bar {
    background-image: url("../img/header/bar-off.png");
}
#overview_container .subtoolbar ul li.bar:hover,
#overview_container .subtoolbar ul #vpp-ops-container li.light-bar:hover,
#vpp-ops-container #overview_container .subtoolbar ul li.light-bar:hover,
#overview_container .subtoolbar ul li.bar.active,
#overview_container .subtoolbar ul #vpp-ops-container li.active.light-bar,
#vpp-ops-container #overview_container .subtoolbar ul li.active.light-bar {
    background-image: url("../img/header/bar-on.png");
}
#overview_container .subtoolbar ul li.graph {
    background-image: url("../img/header/graph-off.png");
}
#overview_container .subtoolbar ul li.graph:hover,
#overview_container .subtoolbar ul li.graph.active {
    background-image: url("../img/header/graph-on.png");
}
#overview_container .subtoolbar ul li.pie {
    background-image: url("../img/header/pie-off.png");
}
#overview_container .subtoolbar ul li.pie:hover,
#overview_container .subtoolbar ul li.pie.active {
    background-image: url("../img/header/pie-on.png");
}
#overview_container .map-extender {
    width: 98%;
}
#overview_container .map-extender.expand {
    width: 99%;
}
#overview_container .marker-popup-window {
    border-radius: 4px;
    border: 1px solid white;
    color: white;
    background-color: #3d5260;
    font-size: 17px;
    text-align: center;
    white-space: nowrap;
    padding: 10px;
}
#overview_container .marker-popup-window .address-info h6 {
    font-family: inherit;
    font-size: 13px;
    font-weight: 200;
}
#overview_container .marker-popup-window .total-info h4 {
    font-family: inherit;
    font-size: 25px;
    font-weight: 300;
    color: #3cd3d5;
}
#overview_container .marker-popup-window .view-vitals {
    border-radius: 5px;
    background-color: #23aa80;
    color: #ffffff;
    font-size: 15px;
    padding: 7px 7px;
    width: 130px;
    margin-left: 15%;
}
#overview_container .map-right,
#overview_container .map-right-small {
    position: absolute;
    background-color: white;
    height: 100%;
    top: 101px;
    bottom: 130px;
    right: 0;
    border: 1px solid #dfe1e3;
}
#overview_container .map-right-small {
    top: 42em;
}
#overview_container .panel-retract-left-arrow {
    padding-top: 25%;
    width: 3em;
}
#overview_container .panel-left-arrow {
    position: absolute;
    left: 0;
}
#overview_container .panel-left-arrow.off {
    padding-top: 100%;
}
#overview_container .open-close-stacked-pie-charts {
    width: 100%;
    top: 105px;
    text-align: right;
    background-color: white;
    float: left;
    cursor: pointer;
}
#overview_container .stacked-column-container {
    height: 45%;
    padding-top: 1em;
}
#overview_container .stacked-column-container .highcharts-container {
    padding-left: 5px;
}
#overview_container
    .stacked-column-container
    .highcharts-container
    .highcharts-button {
    display: none;
}
#overview_container .stacked-column-container.hidden {
    visibility: hidden;
}
#overview_container .piechart-container {
    height: 30%;
}
#overview_container .piechart-container.hidden {
    visibility: hidden;
}
#overview_container .piechart-container .highcharts-button {
    display: none;
}
#overview_container .project-type-container {
    border: 1px solid black;
    border-radius: 5px;
}
#overview_container .project-type-container .perf-label {
    width: 50%;
    display: inline-block;
    font-size: 16px;
    border-right: 1px solid black;
    text-align: center;
}
#overview_container .project-type-container .perf-label.active {
    background-color: #3cd3d5;
}
#overview_container .project-type-container .health-label {
    width: 50%;
    display: inline-block;
    font-size: 16px;
    margin-left: -5px;
    text-align: center;
}
#overview_container .project-type-container .health-label.active {
    background-color: #3cd3d5;
}
#overview_container .angular-google-map,
#overview_container .angular-google-map-container {
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}
#overview_container .map-right.ng-hide-add,
#overview_container .ng-hide-add.map-right-small,
#overview_container .map-right.ng-hide-remove,
#overview_container .ng-hide-remove.map-right-small {
    display: block !important;
}
#overview_container .map-right.ng-hide-remove.ng-hide-remove-active,
#overview_container .ng-hide-remove.ng-hide-remove-active.map-right-small {
    -webkit-animation: 0.5s slide-right;
    animation: 0.5s slide-right;
}
#overview_container .map-right.ng-hide,
#overview_container .ng-hide.map-right-small {
    right: -23%;
    background-color: white;
}

.barchart-container {
    margin-top: 101px;
    border-radius: 5px;
}
.barchart-container .highcharts-container {
    border-radius: 5px;
}
.barchart-container .highcharts-container .highcharts-button {
    display: none;
}
.barchart-container .bar-title,
.barchart-container .bar-subtitle {
    text-align: center;
    color: black;
    background-color: white;
    font-weight: 400;
}
.barchart-container .bar-subtitle {
    padding-bottom: 5px;
}

/* ----------- iPad 3 and 4 ----------- */
/* Portrait and Landscape */
/* Portrait */
/* Landscape */
/* ----------- laptops ----------- */
@media only screen and (min-device-width: 1280px) and (max-device-height: 800px) {
    #overview_container .barchart-container {
        margin-top: 101px;
    }
    #overview_container .all-sites .sort input[type="text"],
    #overview_container
        #footer_container
        .all-sites-small
        .sort
        input[type="text"],
    #footer_container
        #overview_container
        .all-sites-small
        .sort
        input[type="text"],
    #overview_container
        #analysis_footer_container
        .all-sites-small
        .sort
        input[type="text"],
    #analysis_footer_container
        #overview_container
        .all-sites-small
        .sort
        input[type="text"],
    #overview_container
        #vpp_footer_container
        .all-sites-small
        .sort
        input[type="text"],
    #vpp_footer_container
        #overview_container
        .all-sites-small
        .sort
        input[type="text"],
    #overview_container .all-sites .sort select,
    #overview_container #footer_container .all-sites-small .sort select,
    #footer_container #overview_container .all-sites-small .sort select,
    #overview_container
        #analysis_footer_container
        .all-sites-small
        .sort
        select,
    #analysis_footer_container
        #overview_container
        .all-sites-small
        .sort
        select,
    #overview_container #vpp_footer_container .all-sites-small .sort select,
    #vpp_footer_container #overview_container .all-sites-small .sort select {
        min-width: 200px;
    }
}
@media only screen and (min-device-width: 1280px) and (max-device-height: 720px) {
    #overview_container .barchart-container {
        margin-top: 101px;
    }
    #overview_container .all-sites .sort input[type="text"],
    #overview_container
        #footer_container
        .all-sites-small
        .sort
        input[type="text"],
    #footer_container
        #overview_container
        .all-sites-small
        .sort
        input[type="text"],
    #overview_container
        #analysis_footer_container
        .all-sites-small
        .sort
        input[type="text"],
    #analysis_footer_container
        #overview_container
        .all-sites-small
        .sort
        input[type="text"],
    #overview_container
        #vpp_footer_container
        .all-sites-small
        .sort
        input[type="text"],
    #vpp_footer_container
        #overview_container
        .all-sites-small
        .sort
        input[type="text"],
    #overview_container .all-sites .sort select,
    #overview_container #footer_container .all-sites-small .sort select,
    #footer_container #overview_container .all-sites-small .sort select,
    #overview_container
        #analysis_footer_container
        .all-sites-small
        .sort
        select,
    #analysis_footer_container
        #overview_container
        .all-sites-small
        .sort
        select,
    #overview_container #vpp_footer_container .all-sites-small .sort select,
    #vpp_footer_container #overview_container .all-sites-small .sort select {
        min-width: 200px;
    }
}
/* ----------- iPad 1 & 2 ----------- */
@media only screen and (min-device-width: 1024px) and (max-device-height: 768px) {
    #overview_container .barchart-container {
        margin-top: 101px;
    }
    #overview_container .all-sites .sort input[type="text"],
    #overview_container
        #footer_container
        .all-sites-small
        .sort
        input[type="text"],
    #footer_container
        #overview_container
        .all-sites-small
        .sort
        input[type="text"],
    #overview_container
        #analysis_footer_container
        .all-sites-small
        .sort
        input[type="text"],
    #analysis_footer_container
        #overview_container
        .all-sites-small
        .sort
        input[type="text"],
    #overview_container
        #vpp_footer_container
        .all-sites-small
        .sort
        input[type="text"],
    #vpp_footer_container
        #overview_container
        .all-sites-small
        .sort
        input[type="text"],
    #overview_container .all-sites .sort select,
    #overview_container #footer_container .all-sites-small .sort select,
    #footer_container #overview_container .all-sites-small .sort select,
    #overview_container
        #analysis_footer_container
        .all-sites-small
        .sort
        select,
    #analysis_footer_container
        #overview_container
        .all-sites-small
        .sort
        select,
    #overview_container #vpp_footer_container .all-sites-small .sort select,
    #vpp_footer_container #overview_container .all-sites-small .sort select {
        min-width: 200px;
    }
}
/* This CSS here is bad... */
.site-details {
    margin-top: 4%;
}
.site-details .quad-info .first,
.site-details .quad-info .second,
.site-details .quad-info .third,
.site-details .quad-info .fourth {
    box-sizing: border-box;
    float: left;
    padding: 15px 15px 10px 15px;
    text-align: left;
    width: 25%;
}
.site-details .quad-info .first h6,
.site-details .quad-info .second h6,
.site-details .quad-info .third h6,
.site-details .quad-info .fourth h6 {
    font-family: inherit;
    font-size: 12px;
    font-weight: 300;
}
.site-details .quad-info .first {
    width: 25%;
}
.site-details .quad-info .third {
    width: 25%;
}
.site-details .quad-info .fourth {
    width: 25%;
}
.site-details .quad-info .num,
.site-details .quad-info .num-medium,
.site-details .quad-info.bordered .num-medium,
.site-details .quad-info .unit {
    font-weight: 100;
}
.site-details .quad-info .num,
.site-details .quad-info .num-medium,
.site-details .quad-info.bordered .num-medium {
    display: inline-block;
    margin-top: 5px;
    font-size: 24px;
}
.site-details .quad-info .num-medium {
    font-size: 15px;
}
.site-details .quad-info .site-addr {
    font-size: 13px;
    font-weight: 100;
}
.site-details .quad-info.bordered {
    border-top: 1px solid #31d7d9;
    border-bottom: 1px solid #2e967a;
}
.site-details .quad-info.bordered .first {
    border-right: 1px solid #4a616a;
}
.site-details .quad-info.bordered .third {
    border-left: 1px solid #4a616a;
}
.site-details .quad-info.bordered .fourth {
    border-left: 1px solid #4a616a;
}
.site-details .quad-info.bordered .num,
.site-details .quad-info.bordered .num-medium {
    font-size: 26px;
    margin-top: 0;
}
.site-details .quad-info.bordered .num-medium {
    font-size: 15px;
}
.site-details .left-2 {
    color: #d5e2e2;
    background-color: #2f3e4c;
    border-radius: 10px;
}
.site-details .left-2 .demand-savings-container {
    margin-top: 10px;
    background-color: #2f3e4c;
    border-radius: 10px;
}
.site-details .left-2 .demand-savings-container .highcharts-container {
    border-radius: 10px;
}
.site-details
    .left-2
    .demand-savings-container
    .highcharts-container
    .highcharts-button {
    display: none;
}
.site-details .left-2 .savings-selector,
.site-details
    .left-2
    #billing_container
    .billing-table-container
    .inv-grh-container
    .savings-selector-small,
#billing_container
    .billing-table-container
    .inv-grh-container
    .site-details
    .left-2
    .savings-selector-small {
    border: 1px solid grey;
    margin-bottom: 5px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
}
.site-details .left-2 .savings-selector-label {
    color: #908585;
    display: inline-block;
}
.site-details .left-2 .savings-selector-label.selected {
    color: #ffffff !important;
}
.site-details .left-2 .site-tariff {
    box-sizing: border-box;
    float: left;
    padding: 15px 15px 10px 15px;
    text-align: left;
}
.site-details .left-2 .site-tariff h6 {
    font-family: inherit;
    font-size: 13px;
    font-weight: 100;
}
.site-details .left-2 .site-tariff .tariff-rates {
    color: white;
    font-weight: 100;
    font-size: 13px;
    padding-bottom: 2px;
}
.site-details .demand-savings {
    padding: 5px 30px 0 30px;
    border-radius: 10px;
    background-color: white;
}
.site-details .demand-savings .current-savings,
.site-details .demand-savings .last-years-savings,
.site-details .demand-savings .total-savings {
    padding-bottom: 15px;
    text-align: left;
}
.site-details .demand-savings .current-savings .savings-label,
.site-details .demand-savings .last-years-savings .savings-label,
.site-details .demand-savings .total-savings .savings-label {
    font-size: 11px;
    color: #7e7e7e;
}
.site-details .demand-savings .current-savings .savings-val,
.site-details .demand-savings .last-years-savings .savings-val,
.site-details .demand-savings .total-savings .savings-val {
    font-size: 25px;
    color: #6c8e8d;
    font-weight: 300;
}
.site-details .onsite-power-system {
    margin-top: 10px;
    border-radius: 10px;
    background-color: #2f3e4c;
}
.site-details .onsite-power-system h6 {
    font-family: inherit;
    color: white;
    font-size: 13px;
    padding: 15px;
}
.site-details .onsite-power-system .map-selector {
    border-radius: 10px;
    background-color: #2f3e4c;
    border-bottom: 0px solid #c3d3d3;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
    color: #96b6b6;
    padding: 0 10px;
    height: 46px;
    line-height: 45px;
    cursor: pointer;
}
.site-details .onsite-power-system .map-selector .title,
.site-details
    .onsite-power-system
    .map-selector
    #vpp-ops-container
    .right-panel
    .scrollable-panel
    .title,
#vpp-ops-container
    .right-panel
    .scrollable-panel
    .site-details
    .onsite-power-system
    .map-selector
    .title {
    float: left;
    color: white;
    font-size: 13px;
}
.site-details .onsite-power-system .map-selector ul {
    float: right;
    list-style-type: none;
    margin: 0;
    padding: 0;
    border: 15px;
}
.site-details .onsite-power-system .map-selector ul li {
    padding: 15px;
    cursor: pointer;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
    height: 44px;
}
.site-details .onsite-power-system .map-selector ul li:hover,
.site-details .onsite-power-system .map-selector ul li.active {
    background-color: #31d7d9;
}
.site-details .onsite-power-system .system-size {
    color: white;
    padding: 15px 15px 80px 15px;
}
.site-details .onsite-power-system .system-size .gs-version {
    font-size: 15px;
    margin: 0;
    font-weight: 100;
}
.site-details .onsite-power-system .system-size .battery-size {
    font-size: 24px;
    font-weight: 200;
}
.site-details .onsite-power-system .system-size .installed-date {
    font-size: 15px;
    margin: 0;
    font-weight: 100;
}
.site-details .onsite-power-system .system-size.bordered {
    border-top: 1px solid #31d7d9;
    border-bottom: 1px solid #2e967a;
}
.site-details .onsite-power-system .system-size.bordered .first {
    border-right: 1px solid #4a616a;
}
.site-details .onsite-power-system .system-size.bordered .third {
    border-left: 1px solid #4a616a;
}
.site-details .onsite-power-system .system-size.bordered .num,
.site-details .onsite-power-system .system-size.bordered .quad-info .num-medium,
.site-details
    .quad-info
    .onsite-power-system
    .system-size.bordered
    .num-medium {
    font-size: 26px;
    margin-top: 0;
}
.site-details .onsite-power-system p {
    color: white;
    font-size: 16px;
    margin: 10px 0;
    padding: 0;
}

.vitals-main .arrow,
.vitals-main #carousel-container .arrow-expanded,
#carousel-container .vitals-main .arrow-expanded {
    margin-top: 70px;
    background-color: #2f3e4c;
    background-position: center center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    border: 1px solid #4e6a7b;
    cursor: pointer;
    height: 150px;
    width: 46px;
    z-index: 10;
}
.vitals-main .arrow:hover,
.vitals-main #carousel-container .arrow-expanded:hover,
#carousel-container .vitals-main .arrow-expanded:hover {
    background-color: #4e6a7b;
}
.vitals-main .arrow.left,
.vitals-main .arrow.left-expanded-view,
.vitals-main #carousel-container .arrow.left-expanded-view,
#carousel-container .vitals-main .arrow.left-expanded-view,
.vitals-main #carousel-container .left-expanded-view.arrow-expanded,
#carousel-container .vitals-main .left-expanded-view.arrow-expanded,
.vitals-main #carousel-container .left.arrow-expanded,
#carousel-container .vitals-main .left.arrow-expanded,
.vitals-main #vpp-ops-overview-graph-container .arrow.left-expanded-view,
#vpp-ops-overview-graph-container .vitals-main .arrow.left-expanded-view,
.vitals-main #vitals-health-container .arrow.left-expanded-view,
#vitals-health-container .vitals-main .arrow.left-expanded-view {
    border-radius: 4px;
    background-image: url("../img/icons/arrow-left.png");
    left: 17px;
    top: 75px;
    width: 20px;
}
.vitals-main .arrow.left-expanded-view,
.vitals-main #carousel-container .left-expanded-view.arrow-expanded,
#carousel-container .vitals-main .left-expanded-view.arrow-expanded {
    top: 215px;
}
.vitals-main .arrow.right,
.vitals-main .arrow.right-expanded-view,
.vitals-main #carousel-container .arrow.right-expanded-view,
#carousel-container .vitals-main .arrow.right-expanded-view,
.vitals-main #carousel-container .right-expanded-view.arrow-expanded,
#carousel-container .vitals-main .right-expanded-view.arrow-expanded,
.vitals-main #carousel-container .right.arrow-expanded,
#carousel-container .vitals-main .right.arrow-expanded,
.vitals-main #vpp-ops-overview-graph-container .arrow.right-expanded-view,
#vpp-ops-overview-graph-container .vitals-main .arrow.right-expanded-view,
.vitals-main #vitals-health-container .arrow.right-expanded-view,
#vitals-health-container .vitals-main .arrow.right-expanded-view {
    border-radius: 4px;
    background-image: url("../img/icons/arrow-right.png");
    top: 75px;
    width: 20px;
}
.vitals-main .arrow.right-expanded-view,
.vitals-main #carousel-container .right-expanded-view.arrow-expanded,
#carousel-container .vitals-main .right-expanded-view.arrow-expanded {
    top: 215px;
}
.vitals-main .flexbox {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
}
.vitals-main .flexbox .col {
    flex: 1;
    margin: 0 5px 0 5px;
    border-radius: 10px;
    padding: 0;
    background: #2f3e4c;
}
.vitals-main .flexbox .col:nth-child(1) {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 0;
}
.vitals-main .flexbox .col:nth-child(2) {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
}
.vitals-main .subtab-container {
    margin-top: 10px;
}
.vitals-main .subtab-container .view-details {
    margin-left: 1%;
    float: left;
    color: #545454;
    font-size: 14px;
    font-weight: 300;
}
.vitals-main .subtab-container .view-site-details-button {
    float: right;
    color: white;
    font-weight: 300;
    cursor: pointer;
}
.vitals-main table {
    margin-bottom: 0;
}
.vitals-main .current_site {
    background-color: #f1f6f6;
    width: 100%;
    color: #3e7075;
    border-spacing: 0;
    font-family: inherit;
}
.vitals-main .current_site tr {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
.vitals-main .current_site th {
    display: none;
}
.vitals-main .current_site td {
    display: block;
}
.vitals-main .current_site td:first-child {
    padding-top: 0.5em;
}
.vitals-main .current_site td:last-child {
    padding-bottom: 0.5em;
}
.vitals-main .current_site td:before {
    font-weight: bold;
    width: 3.5em;
    display: inline-block;
}
@media (min-width: 480px) {
    .vitals-main .current_site td:before {
        display: none;
    }
}
.vitals-main .current_site th,
.vitals-main .current_site td {
    color: #3e7075;
    border-right: 1px solid #bbcdcd;
    border-bottom: 1px solid #bbcdcd;
    padding: 10px 17px 0;
    vertical-align: top;
}
.vitals-main .current_site th.ev,
.vitals-main .current_site th.solar,
.vitals-main .current_site th.stations,
.vitals-main .current_site th.installed-date,
.vitals-main .current_site th.local-time,
.vitals-main .current_site td.ev,
.vitals-main .current_site td.solar,
.vitals-main .current_site td.stations,
.vitals-main .current_site td.installed-date,
.vitals-main .current_site td.local-time {
    text-align: center;
}
.vitals-main .current_site th.ev h1,
.vitals-main .current_site th.solar h1,
.vitals-main .current_site th.stations h1,
.vitals-main .current_site th.installed-date h1,
.vitals-main .current_site th.local-time h1,
.vitals-main .current_site td.ev h1,
.vitals-main .current_site td.solar h1,
.vitals-main .current_site td.stations h1,
.vitals-main .current_site td.installed-date h1,
.vitals-main .current_site td.local-time h1 {
    color: #3e7075;
    font-family: inherit;
}
.vitals-main .current_site th.ev p,
.vitals-main .current_site th.solar p,
.vitals-main .current_site th.stations p,
.vitals-main .current_site th.installed-date p,
.vitals-main .current_site th.local-time p,
.vitals-main .current_site td.ev p,
.vitals-main .current_site td.solar p,
.vitals-main .current_site td.stations p,
.vitals-main .current_site td.installed-date p,
.vitals-main .current_site td.local-time p {
    font-size: 15px;
    margin: 0;
}
.vitals-main .current_site th.photo,
.vitals-main .current_site td.photo {
    border-right: none;
    padding: 0;
    width: 200px;
}
.vitals-main .current_site th.photo img,
.vitals-main .current_site td.photo img {
    height: 71px;
}
.vitals-main .current_site th.site-info h1,
.vitals-main
    .current_site
    #billing_container
    .billing-table-container
    th.site-info-medium
    h1,
#billing_container
    .billing-table-container
    .vitals-main
    .current_site
    th.site-info-medium
    h1,
.vitals-main .current_site td.site-info h1,
.vitals-main
    .current_site
    #billing_container
    .billing-table-container
    td.site-info-medium
    h1,
#billing_container
    .billing-table-container
    .vitals-main
    .current_site
    td.site-info-medium
    h1 {
    color: #3e7075;
    margin-bottom: 5px;
    font-family: inherit;
}
.vitals-main .current_site th.site-info p,
.vitals-main
    .current_site
    #billing_container
    .billing-table-container
    th.site-info-medium
    p,
#billing_container
    .billing-table-container
    .vitals-main
    .current_site
    th.site-info-medium
    p,
.vitals-main .current_site td.site-info p,
.vitals-main
    .current_site
    #billing_container
    .billing-table-container
    td.site-info-medium
    p,
#billing_container
    .billing-table-container
    .vitals-main
    .current_site
    td.site-info-medium
    p {
    font-size: 12px;
    margin: 0;
}
.vitals-main .current_site th:last-child,
.vitals-main .current_site td:last-child {
    border-right: none;
}
.vitals-main .current_site th h1,
.vitals-main .current_site td h1 {
    font-size: 12px;
}
.vitals-main .current_site th p,
.vitals-main .current_site td p {
    font-size: 16px;
    margin: 10px 0;
    padding: 0;
}
@media (min-width: 480px) {
    .vitals-main .current_site th,
    .vitals-main .current_site td {
        display: table-cell;
        padding: 0.25em 0.5em;
    }
    .vitals-main .current_site th:first-child,
    .vitals-main .current_site td:first-child {
        padding-left: 0;
    }
    .vitals-main .current_site th:last-child,
    .vitals-main .current_site td:last-child {
        padding-right: 0;
    }
}
.vitals-main .left,
.vitals-main .arrow.left-expanded-view,
.vitals-main #carousel-container .arrow.left-expanded-view,
#carousel-container .vitals-main .arrow.left-expanded-view,
.vitals-main #carousel-container .left-expanded-view.arrow-expanded,
#carousel-container .vitals-main .left-expanded-view.arrow-expanded,
.vitals-main #vpp-ops-overview-graph-container .arrow.left-expanded-view,
#vpp-ops-overview-graph-container .vitals-main .arrow.left-expanded-view,
.vitals-main #vitals-health-container .arrow.left-expanded-view,
#vitals-health-container .vitals-main .arrow.left-expanded-view {
    color: #d5e2e2;
}
.vitals-main .left .container,
.vitals-main .arrow.left-expanded-view .container,
.vitals-main #carousel-container .arrow.left-expanded-view .container,
#carousel-container .vitals-main .arrow.left-expanded-view .container,
.vitals-main #carousel-container .left-expanded-view.arrow-expanded .container,
#carousel-container .vitals-main .left-expanded-view.arrow-expanded .container,
.vitals-main
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .container,
#vpp-ops-overview-graph-container
    .vitals-main
    .arrow.left-expanded-view
    .container,
.vitals-main #vitals-health-container .arrow.left-expanded-view .container,
#vitals-health-container .vitals-main .arrow.left-expanded-view .container {
    box-sizing: border-box;
    margin: 3px 0;
    background-color: #2f3e4c;
    text-align: center;
    border-radius: 10px;
}
.vitals-main .left__header {
    height: 50px;
}
.vitals-main .left__header h1 {
    font-weight: 100;
    margin: 0;
}
.vitals-main .left__header h6 {
    font-family: inherit;
}
.vitals-main .left__header_title {
    cursor: pointer;
    margin-bottom: 10px;
}
.vitals-main .left .power-demand,
.vitals-main .arrow.left-expanded-view .power-demand,
.vitals-main #carousel-container .arrow.left-expanded-view .power-demand,
#carousel-container .vitals-main .arrow.left-expanded-view .power-demand,
.vitals-main
    #carousel-container
    .left-expanded-view.arrow-expanded
    .power-demand,
#carousel-container
    .vitals-main
    .left-expanded-view.arrow-expanded
    .power-demand,
.vitals-main
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .power-demand,
#vpp-ops-overview-graph-container
    .vitals-main
    .arrow.left-expanded-view
    .power-demand,
.vitals-main #vitals-health-container .arrow.left-expanded-view .power-demand,
#vitals-health-container .vitals-main .arrow.left-expanded-view .power-demand {
    padding-left: 50px;
}
.vitals-main .left .time-selector,
.vitals-main .arrow.left-expanded-view .time-selector,
.vitals-main #carousel-container .arrow.left-expanded-view .time-selector,
#carousel-container .vitals-main .arrow.left-expanded-view .time-selector,
.vitals-main
    #carousel-container
    .left-expanded-view.arrow-expanded
    .time-selector,
#carousel-container
    .vitals-main
    .left-expanded-view.arrow-expanded
    .time-selector,
.vitals-main
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .time-selector,
#vpp-ops-overview-graph-container
    .vitals-main
    .arrow.left-expanded-view
    .time-selector,
.vitals-main #vitals-health-container .arrow.left-expanded-view .time-selector,
#vitals-health-container .vitals-main .arrow.left-expanded-view .time-selector {
    border: 1px solid grey;
    width: 450px;
    margin-left: auto;
    margin-right: auto;
    height: 25px;
}
.vitals-main .left .time-selector-label,
.vitals-main .arrow.left-expanded-view .time-selector-label,
.vitals-main #carousel-container .arrow.left-expanded-view .time-selector-label,
#carousel-container .vitals-main .arrow.left-expanded-view .time-selector-label,
.vitals-main
    #carousel-container
    .left-expanded-view.arrow-expanded
    .time-selector-label,
#carousel-container
    .vitals-main
    .left-expanded-view.arrow-expanded
    .time-selector-label,
.vitals-main
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .time-selector-label,
#vpp-ops-overview-graph-container
    .vitals-main
    .arrow.left-expanded-view
    .time-selector-label,
.vitals-main
    #vitals-health-container
    .arrow.left-expanded-view
    .time-selector-label,
#vitals-health-container
    .vitals-main
    .arrow.left-expanded-view
    .time-selector-label,
.vitals-main
    .left
    #billing_container
    .billing-table-container
    .inv-grh-container
    .savings-selector-label,
#billing_container
    .billing-table-container
    .inv-grh-container
    .vitals-main
    .left
    .savings-selector-label,
.vitals-main
    .arrow.left-expanded-view
    #billing_container
    .billing-table-container
    .inv-grh-container
    .savings-selector-label,
#billing_container
    .billing-table-container
    .inv-grh-container
    .vitals-main
    .arrow.left-expanded-view
    .savings-selector-label,
.vitals-main
    #carousel-container
    .left-expanded-view.arrow-expanded
    #billing_container
    .billing-table-container
    .inv-grh-container
    .savings-selector-label,
#billing_container
    .billing-table-container
    .inv-grh-container
    .vitals-main
    #carousel-container
    .left-expanded-view.arrow-expanded
    .savings-selector-label,
#carousel-container
    .vitals-main
    .left-expanded-view.arrow-expanded
    #billing_container
    .billing-table-container
    .inv-grh-container
    .savings-selector-label,
#billing_container
    .billing-table-container
    .inv-grh-container
    #carousel-container
    .vitals-main
    .left-expanded-view.arrow-expanded
    .savings-selector-label {
    cursor: pointer;
    color: #908585;
    font-size: 13px;
    padding: 5px;
    display: inline-block;
}
.vitals-main .left .time-selector-label.selected,
.vitals-main .arrow.left-expanded-view .time-selector-label.selected,
.vitals-main
    #carousel-container
    .arrow.left-expanded-view
    .time-selector-label.selected,
#carousel-container
    .vitals-main
    .arrow.left-expanded-view
    .time-selector-label.selected,
.vitals-main
    #carousel-container
    .left-expanded-view.arrow-expanded
    .time-selector-label.selected,
#carousel-container
    .vitals-main
    .left-expanded-view.arrow-expanded
    .time-selector-label.selected,
.vitals-main
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .time-selector-label.selected,
#vpp-ops-overview-graph-container
    .vitals-main
    .arrow.left-expanded-view
    .time-selector-label.selected,
.vitals-main
    #vitals-health-container
    .arrow.left-expanded-view
    .time-selector-label.selected,
#vitals-health-container
    .vitals-main
    .arrow.left-expanded-view
    .time-selector-label.selected,
.vitals-main
    .left
    #billing_container
    .billing-table-container
    .inv-grh-container
    .selected.savings-selector-label,
#billing_container
    .billing-table-container
    .inv-grh-container
    .vitals-main
    .left
    .selected.savings-selector-label,
.vitals-main
    .arrow.left-expanded-view
    #billing_container
    .billing-table-container
    .inv-grh-container
    .selected.savings-selector-label,
#billing_container
    .billing-table-container
    .inv-grh-container
    .vitals-main
    .arrow.left-expanded-view
    .selected.savings-selector-label,
.vitals-main
    #carousel-container
    .left-expanded-view.arrow-expanded
    #billing_container
    .billing-table-container
    .inv-grh-container
    .selected.savings-selector-label,
#billing_container
    .billing-table-container
    .inv-grh-container
    .vitals-main
    #carousel-container
    .left-expanded-view.arrow-expanded
    .selected.savings-selector-label,
#carousel-container
    .vitals-main
    .left-expanded-view.arrow-expanded
    #billing_container
    .billing-table-container
    .inv-grh-container
    .selected.savings-selector-label,
#billing_container
    .billing-table-container
    .inv-grh-container
    #carousel-container
    .vitals-main
    .left-expanded-view.arrow-expanded
    .selected.savings-selector-label {
    color: #ffffff !important;
}
.vitals-main .left-large {
    color: #d5e2e2;
}
.vitals-main .left-large .left-panel-container {
    box-sizing: border-box;
    margin: 3px 0;
    background-color: #2f3e4c;
    text-align: center;
    border-radius: 10px;
}
.vitals-main .left-large .left-panel-container .reset-zoom {
    border: 1px solid white;
    border-radius: 0.2em;
    background-color: #f2f2f2;
}
.vitals-main .left-large .left-panel-container .reset-zoom:hover {
    background-color: #41739d;
}
.vitals-main .left-large .left-panel-container .left-arrow {
    margin-top: 120px;
}
.vitals-main .left-large .left-panel-container .right-arrow {
    margin-top: 120px;
}
.vitals-main .left-large .power-demand {
    padding: 0;
}
.vitals-main
    .left-large
    .power-demand
    #highchart-power-demand
    .highcharts-button {
    display: none;
}
.vitals-main .left-large .time-selector {
    border: 1px solid grey;
    margin-left: auto;
    margin-right: auto;
}
.vitals-main .left-large .time-selector-label,
.vitals-main
    .left-large
    #billing_container
    .billing-table-container
    .inv-grh-container
    .savings-selector-label,
#billing_container
    .billing-table-container
    .inv-grh-container
    .vitals-main
    .left-large
    .savings-selector-label {
    cursor: pointer;
    color: #908585;
    font-size: 0.7em;
    padding: 5px;
}
.vitals-main .left-large .time-selector-label.selected,
.vitals-main
    .left-large
    #billing_container
    .billing-table-container
    .inv-grh-container
    .selected.savings-selector-label,
#billing_container
    .billing-table-container
    .inv-grh-container
    .vitals-main
    .left-large
    .selected.savings-selector-label {
    color: #ffffff !important;
}
.vitals-main .left-large .peak-legend {
    position: absolute;
    top: 390px;
    left: 20.5%;
    width: 350px;
    height: 40px;
}
.vitals-main .left-large .peak-legend .on_peak {
    position: relative;
    top: 0px;
    left: 3px;
}
.vitals-main .left-large .peak-legend .partial_peak {
    position: relative;
    left: 57px;
}
.vitals-main .left-large .peak-legend .off_peak {
    position: relative;
    left: 47px;
}
.vitals-main .right-panel .filler {
    background-color: #acb8ba;
    border-radius: 0;
    width: 140%;
    left: -10px;
    height: 10px;
}
.vitals-main .right-panel label {
    color: white;
    font-size: 14px;
    font-weight: 300;
}
.vitals-main .system-power-container {
    margin-top: 3px;
    color: #d5e2e2;
    background-color: #2f3e4c;
    padding: 12px;
    border-radius: 10px;
    text-align: center;
}
.vitals-main .system-power-container .system-power label {
    color: white;
    font-size: 12px;
    font-weight: 300;
}
.vitals-main .system-power-container .battery label {
    color: white;
    font-size: 12px;
    font-weight: 300;
}
.vitals-main .system-power-container .power-gauge {
    background-color: #2f3e4c;
    color: #d5e2e2;
}
.vitals-main
    .system-power-container
    .power-gauge
    .power-gauge-chart
    .highcharts-button {
    display: none;
}
.vitals-main .system-power-container .power-gauge h6 {
    font-family: inherit;
}
.vitals-main .system-power-container .peak-label-medium {
    font-size: 1rem;
    font-weight: 100;
}
.vitals-main .system-power-container .peak-label-small {
    font-size: small;
}
.vitals-main .site-tariff-container,
.vitals-main .site-tariff-medium-container {
    margin-top: 10px;
    background-color: #2f3e4c;
    border-radius: 10px;
    text-align: center;
    color: white;
}
.vitals-main .site-tariff-container h6,
.vitals-main .site-tariff-medium-container h6 {
    font-family: inherit;
}
.vitals-main .site-tariff-container .tariff-rates,
.vitals-main .site-tariff-medium-container .tariff-rates {
    color: white;
    font-weight: 100;
    font-size: 13px;
    padding-bottom: 2px;
}
.vitals-main .site-tariff-container .small-text-size,
.vitals-main .site-tariff-medium-container .small-text-size {
    font-size: small;
}
.vitals-main .site-tariff-medium-container {
    margin-top: 5px;
    min-height: 150px;
    margin-bottom: 70px;
}
.vitals-main .tri-info,
.vitals-main .tri-info-xlarge,
.vitals-main .tri-info-large {
    box-sizing: border-box;
    padding: 15px 15px 5px 15px;
    text-align: left;
}
.vitals-main .tri-info .first,
.vitals-main .tri-info-xlarge .first,
.vitals-main .tri-info-large .first,
.vitals-main .tri-info .second,
.vitals-main .tri-info-xlarge .second,
.vitals-main .tri-info-large .second,
.vitals-main .tri-info .third,
.vitals-main .tri-info-xlarge .third,
.vitals-main .tri-info-large .third {
    box-sizing: border-box;
    padding: 15px 15px 5px 15px;
    text-align: left;
}
.vitals-main .tri-info .first,
.vitals-main .tri-info-xlarge .first,
.vitals-main .tri-info-large .first {
    width: 33%;
}
.vitals-main .tri-info .third,
.vitals-main .tri-info-xlarge .third,
.vitals-main .tri-info-large .third {
    width: 33%;
}
.vitals-main .tri-info .num,
.vitals-main .tri-info-xlarge .num,
.vitals-main .tri-info-large .num,
.vitals-main .tri-info .site-details .quad-info .num-medium,
.site-details .quad-info .vitals-main .tri-info .num-medium,
.vitals-main .tri-info-xlarge .site-details .quad-info .num-medium,
.site-details .quad-info .vitals-main .tri-info-xlarge .num-medium,
.vitals-main .tri-info-large .site-details .quad-info .num-medium,
.site-details .quad-info .vitals-main .tri-info-large .num-medium,
.vitals-main .tri-info .unit,
.vitals-main .tri-info-xlarge .unit,
.vitals-main .tri-info-large .unit {
    font-weight: 100;
}
.vitals-main .tri-info .num-sm,
.vitals-main .tri-info-xlarge .num-sm,
.vitals-main .tri-info-large .num-sm {
    margin-top: 5px;
    font-size: 1.1em;
    font-weight: 100;
    color: white;
}
.vitals-main .tri-info .num,
.vitals-main .tri-info-xlarge .num,
.vitals-main .tri-info-large .num,
.vitals-main .tri-info .site-details .quad-info .num-medium,
.site-details .quad-info .vitals-main .tri-info .num-medium,
.vitals-main .tri-info-xlarge .site-details .quad-info .num-medium,
.site-details .quad-info .vitals-main .tri-info-xlarge .num-medium,
.vitals-main .tri-info-large .site-details .quad-info .num-medium,
.site-details .quad-info .vitals-main .tri-info-large .num-medium {
    margin-top: 5px;
    font-size: 24px;
    color: white;
}
.vitals-main .tri-info .num-medium,
.vitals-main .tri-info-xlarge .num-medium,
.vitals-main .tri-info-large .num-medium {
    font-size: medium;
}
.vitals-main .tri-info.bordered,
.vitals-main .bordered.tri-info-xlarge,
.vitals-main .bordered.tri-info-large {
    border-top: 1px solid #31d7d9;
    border-bottom: 1px solid #2e967a;
}
.vitals-main .tri-info.bordered .first,
.vitals-main .bordered.tri-info-xlarge .first,
.vitals-main .bordered.tri-info-large .first {
    border-right: 1px solid #4a616a;
}
.vitals-main .tri-info.bordered .third,
.vitals-main .bordered.tri-info-xlarge .third,
.vitals-main .bordered.tri-info-large .third {
    border-left: 1px solid #4a616a;
}
.vitals-main .tri-info.bordered .num,
.vitals-main .bordered.tri-info-xlarge .num,
.vitals-main .bordered.tri-info-large .num,
.vitals-main .tri-info.bordered .site-details .quad-info .num-medium,
.site-details .quad-info .vitals-main .tri-info.bordered .num-medium,
.vitals-main .bordered.tri-info-xlarge .site-details .quad-info .num-medium,
.site-details .quad-info .vitals-main .bordered.tri-info-xlarge .num-medium,
.vitals-main .bordered.tri-info-large .site-details .quad-info .num-medium,
.site-details .quad-info .vitals-main .bordered.tri-info-large .num-medium {
    font-size: 26px;
    margin-top: 0;
}
.vitals-main .tri-info.bordered .num-medium,
.vitals-main .bordered.tri-info-xlarge .num-medium,
.vitals-main .bordered.tri-info-large .num-medium {
    font-size: medium;
}
.vitals-main .tri-info.bordered .num-small,
.vitals-main .bordered.tri-info-xlarge .num-small,
.vitals-main .bordered.tri-info-large .num-small {
    font-size: small;
}
.vitals-main .tri-info.bordered .small-text-size,
.vitals-main .bordered.tri-info-xlarge .small-text-size,
.vitals-main .bordered.tri-info-large .small-text-size {
    font-size: small;
}
.vitals-main .tri-info-xlarge {
    padding: 15px 15px 35px 15px;
}
.vitals-main .tri-info-large {
    padding: 10px 10px 14px 10px;
}
.vitals-main .vitals_bottom_bar {
    background-color: #f1f6f6;
    border-top: 1px solid #8c9d9d;
    color: #407074;
    margin-top: 50px;
    position: fixed;
    bottom: 60px;
    left: 0;
    right: 0;
}
.vitals-main .vitals_bottom_bar h5 {
    color: #407074;
    font-weight: 700;
}
.vitals-main .vitals_bottom_bar .first,
.vitals-main .vitals_bottom_bar .second,
.vitals-main .vitals_bottom_bar .third {
    box-sizing: border-box;
    float: left;
    height: 90px;
    padding: 15px 30px;
    width: 34%;
}
.vitals-main .vitals_bottom_bar .first {
    border-right: 1px solid #8c9d9d;
    width: 33%;
}
.vitals-main .vitals_bottom_bar .first h5 {
    margin-bottom: 10px;
}
.vitals-main .vitals_bottom_bar .first p {
    margin: 0;
}
.vitals-main .vitals_bottom_bar .first p:last-child {
    font-size: 12px;
}
.vitals-main .vitals_bottom_bar .second .left,
.vitals-main .vitals_bottom_bar .second .arrow.left-expanded-view,
.vitals-main
    #carousel-container
    .vitals_bottom_bar
    .second
    .left-expanded-view.arrow-expanded,
.vitals-main
    .vitals_bottom_bar
    .second
    #carousel-container
    .arrow.left-expanded-view,
#carousel-container
    .vitals-main
    .vitals_bottom_bar
    .second
    .arrow.left-expanded-view,
.vitals-main
    .vitals_bottom_bar
    .second
    #carousel-container
    .left-expanded-view.arrow-expanded,
#carousel-container
    .vitals-main
    .vitals_bottom_bar
    .second
    .left-expanded-view.arrow-expanded,
.vitals-main
    .vitals_bottom_bar
    .second
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view,
#vpp-ops-overview-graph-container
    .vitals-main
    .vitals_bottom_bar
    .second
    .arrow.left-expanded-view,
.vitals-main
    .vitals_bottom_bar
    .second
    #vitals-health-container
    .arrow.left-expanded-view,
#vitals-health-container
    .vitals-main
    .vitals_bottom_bar
    .second
    .arrow.left-expanded-view,
.vitals-main .vitals_bottom_bar .second .right,
.vitals-main .vitals_bottom_bar .second .arrow.right-expanded-view,
.vitals-main
    #carousel-container
    .vitals_bottom_bar
    .second
    .right-expanded-view.arrow-expanded,
.vitals-main
    .vitals_bottom_bar
    .second
    #carousel-container
    .arrow.right-expanded-view,
#carousel-container
    .vitals-main
    .vitals_bottom_bar
    .second
    .arrow.right-expanded-view,
.vitals-main
    .vitals_bottom_bar
    .second
    #carousel-container
    .right-expanded-view.arrow-expanded,
#carousel-container
    .vitals-main
    .vitals_bottom_bar
    .second
    .right-expanded-view.arrow-expanded,
.vitals-main
    .vitals_bottom_bar
    .second
    #vpp-ops-overview-graph-container
    .arrow.right-expanded-view,
#vpp-ops-overview-graph-container
    .vitals-main
    .vitals_bottom_bar
    .second
    .arrow.right-expanded-view,
.vitals-main
    .vitals_bottom_bar
    .second
    #vitals-health-container
    .arrow.right-expanded-view,
#vitals-health-container
    .vitals-main
    .vitals_bottom_bar
    .second
    .arrow.right-expanded-view {
    box-sizing: border-box;
    float: left;
    font-size: 20px;
    margin-top: 15px;
    text-align: left;
}
.vitals-main .vitals_bottom_bar .second .left,
.vitals-main .vitals_bottom_bar .second .arrow.left-expanded-view,
.vitals-main
    #carousel-container
    .vitals_bottom_bar
    .second
    .left-expanded-view.arrow-expanded,
.vitals-main
    .vitals_bottom_bar
    .second
    #carousel-container
    .arrow.left-expanded-view,
#carousel-container
    .vitals-main
    .vitals_bottom_bar
    .second
    .arrow.left-expanded-view,
.vitals-main
    .vitals_bottom_bar
    .second
    #carousel-container
    .left-expanded-view.arrow-expanded,
#carousel-container
    .vitals-main
    .vitals_bottom_bar
    .second
    .left-expanded-view.arrow-expanded,
.vitals-main
    .vitals_bottom_bar
    .second
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view,
#vpp-ops-overview-graph-container
    .vitals-main
    .vitals_bottom_bar
    .second
    .arrow.left-expanded-view,
.vitals-main
    .vitals_bottom_bar
    .second
    #vitals-health-container
    .arrow.left-expanded-view,
#vitals-health-container
    .vitals-main
    .vitals_bottom_bar
    .second
    .arrow.left-expanded-view {
    color: #407074;
    width: 35%;
    border-right: 1px solid #8c9d9d;
}
.vitals-main .vitals_bottom_bar .second .right,
.vitals-main .vitals_bottom_bar .second .arrow.right-expanded-view,
.vitals-main
    #carousel-container
    .vitals_bottom_bar
    .second
    .right-expanded-view.arrow-expanded,
.vitals-main
    .vitals_bottom_bar
    .second
    #carousel-container
    .arrow.right-expanded-view,
#carousel-container
    .vitals-main
    .vitals_bottom_bar
    .second
    .arrow.right-expanded-view,
.vitals-main
    .vitals_bottom_bar
    .second
    #carousel-container
    .right-expanded-view.arrow-expanded,
#carousel-container
    .vitals-main
    .vitals_bottom_bar
    .second
    .right-expanded-view.arrow-expanded,
.vitals-main
    .vitals_bottom_bar
    .second
    #vpp-ops-overview-graph-container
    .arrow.right-expanded-view,
#vpp-ops-overview-graph-container
    .vitals-main
    .vitals_bottom_bar
    .second
    .arrow.right-expanded-view,
.vitals-main
    .vitals_bottom_bar
    .second
    #vitals-health-container
    .arrow.right-expanded-view,
#vitals-health-container
    .vitals-main
    .vitals_bottom_bar
    .second
    .arrow.right-expanded-view {
    color: #407074;
    padding-left: 20px;
    width: 50%;
}
.vitals-main .vitals_bottom_bar .third {
    border-left: 1px solid #8c9d9d;
    width: 33%;
}
.vitals-main .vitals_bottom_bar .third h3 {
    color: #407074;
    font-size: 24px;
    margin-top: 10px;
}
.vitals-main .angular-google-map,
.vitals-main .angular-google-map-container {
    width: 98%;
    height: 330px;
    position: absolute;
    border-radius: 10px;
    margin-left: 1px;
}
.vitals-main .batteryOn {
    background-image: url("../img/icons/icon-battery-on.png");
}
.vitals-main .batteryOff {
    background-image: url("../img/icons/icon-battery-off.png");
}
.vitals-main .solarOn {
    background-image: url("../img/icons/icon-solar-on.png");
}
.vitals-main .solarOff {
    background-image: url("../img/icons/icon-solar-off.png");
}
.vitals-main .evOn {
    background-image: url("../img/icons/icon-ev-on.png");
}
.vitals-main .evOff {
    background-image: url("../img/icons/icon-ev-off.png");
}

/* ----------- responsive ----------- */
/* ----------- laptops ----------- */
/* ----------- iPad 1 & 2 ----------- */
/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
#vitals_container {
    margin-top: 110px;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
}
#vitals_container .main-nav {
    height: 400px;
    background-color: #2f3e4c;
    font-size: 10px;
    padding-left: 0;
}
#vitals_container .main-nav .heading {
    margin-right: 0;
    padding: 10px;
    background-color: #2f3e4c;
    font-size: 1.2em;
    font-weight: 100;
    color: #a4c8c8;
    position: relative;
}
#vitals_container .main-nav .heading.active,
#vitals_container .main-nav .heading:hover {
    color: white;
    background-color: black;
    border: 1px solid black;
    border-radius: 0;
}
#vitals_container .main-nav .heading.active:after {
    border-style: solid;
    border-width: 6px 0 6px 10px;
    border-color: transparent transparent transparent #000000;
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    right: -5%;
    top: 35%;
    clear: both;
}

#footer_container {
    margin-top: 1px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}
#footer_container .detail-grid-expand {
    height: 580px;
}
#footer_container .detail-grid-retract {
    height: 350px;
}
#footer_container .all-sites,
#footer_container .all-sites-small {
    background-color: #3d5260;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3.8em;
}
#footer_container .all-sites h2,
#footer_container .all-sites-small h2 {
    color: #31d7d9;
    font-weight: 100;
    margin-left: 40px;
}
#footer_container .all-sites .nav,
#footer_container .all-sites-small .nav {
    float: right;
    margin-right: 45px;
}
#footer_container .all-sites .nav .right,
#footer_container .all-sites-small .nav .right,
#footer_container .all-sites .nav .vitals-main .arrow.right-expanded-view,
.vitals-main #footer_container .all-sites .nav .arrow.right-expanded-view,
#footer_container .all-sites-small .nav .vitals-main .arrow.right-expanded-view,
.vitals-main #footer_container .all-sites-small .nav .arrow.right-expanded-view,
#footer_container
    .all-sites
    .nav
    #carousel-container
    .arrow.right-expanded-view,
#carousel-container
    #footer_container
    .all-sites
    .nav
    .arrow.right-expanded-view,
#footer_container
    .all-sites-small
    .nav
    #carousel-container
    .arrow.right-expanded-view,
#carousel-container
    #footer_container
    .all-sites-small
    .nav
    .arrow.right-expanded-view,
#footer_container
    .all-sites
    .nav
    #carousel-container
    .right-expanded-view.arrow-expanded,
#carousel-container
    #footer_container
    .all-sites
    .nav
    .right-expanded-view.arrow-expanded,
#footer_container
    .all-sites-small
    .nav
    #carousel-container
    .right-expanded-view.arrow-expanded,
#carousel-container
    #footer_container
    .all-sites-small
    .nav
    .right-expanded-view.arrow-expanded,
#footer_container
    .all-sites
    .nav
    #vpp-ops-overview-graph-container
    .arrow.right-expanded-view,
#vpp-ops-overview-graph-container
    #footer_container
    .all-sites
    .nav
    .arrow.right-expanded-view,
#footer_container
    .all-sites-small
    .nav
    #vpp-ops-overview-graph-container
    .arrow.right-expanded-view,
#vpp-ops-overview-graph-container
    #footer_container
    .all-sites-small
    .nav
    .arrow.right-expanded-view,
#footer_container
    .all-sites
    .nav
    #vitals-health-container
    .arrow.right-expanded-view,
#vitals-health-container
    #footer_container
    .all-sites
    .nav
    .arrow.right-expanded-view,
#footer_container
    .all-sites-small
    .nav
    #vitals-health-container
    .arrow.right-expanded-view,
#vitals-health-container
    #footer_container
    .all-sites-small
    .nav
    .arrow.right-expanded-view {
    margin-left: 48px;
}
#footer_container .all-sites .nav img,
#footer_container .all-sites-small .nav img {
    cursor: pointer;
}
#footer_container .all-sites .view-selector,
#footer_container .all-sites-small .view-selector {
    margin-top: 0.9em;
    text-align: right;
}
#footer_container .all-sites .view-selector .list,
#footer_container .all-sites-small .view-selector .list,
#footer_container .all-sites .view-selector .grid,
#footer_container .all-sites-small .view-selector .grid,
#footer_container .all-sites .view-selector .upload,
#footer_container .all-sites-small .view-selector .upload,
#footer_container .all-sites .view-selector .expander,
#footer_container .all-sites-small .view-selector .expander,
#footer_container .all-sites .view-selector .detail,
#footer_container .all-sites-small .view-selector .detail,
#footer_container .all-sites .view-selector .retract,
#footer_container .all-sites-small .view-selector .retract,
#footer_container .all-sites .view-selector .export-excel,
#footer_container .all-sites-small .view-selector .export-excel,
#footer_container .all-sites .view-selector .plus,
#footer_container .all-sites-small .view-selector .plus,
#footer_container .all-sites .view-selector .minus,
#footer_container .all-sites-small .view-selector .minus,
#footer_container .all-sites .view-selector .configuration,
#footer_container .all-sites-small .view-selector .configuration,
#footer_container .all-sites .view-selector .setpoint,
#footer_container .all-sites-small .view-selector .setpoint {
    background-color: #1f2a31;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
    box-sizing: border-box;
    display: inline-block;
    width: 36px;
    height: 33px;
    border: 1px solid #334550;
}
#footer_container .all-sites .view-selector .list,
#footer_container .all-sites-small .view-selector .list {
    background-image: url("../img/icons/list-off.png");
    margin-left: -3px;
}
#footer_container .all-sites .view-selector .list.on,
#footer_container .all-sites-small .view-selector .list.on {
    background-color: #4dc2c6;
    background-image: url("../img/icons/list-on.png");
}
#footer_container .all-sites .view-selector .list:hover,
#footer_container .all-sites-small .view-selector .list:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#footer_container .all-sites .view-selector .grid,
#footer_container .all-sites-small .view-selector .grid {
    background-image: url("../img/icons/grid-off.png");
    border-radius: 4px 0 0 4px;
}
#footer_container .all-sites .view-selector .grid.on,
#footer_container .all-sites-small .view-selector .grid.on {
    background-color: #4dc2c6;
    background-image: url("../img/icons/grid-on.png");
}
#footer_container .all-sites .view-selector .grid:hover,
#footer_container .all-sites-small .view-selector .grid:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#footer_container .all-sites .view-selector .upload,
#footer_container .all-sites-small .view-selector .upload {
    margin-right: 1em;
    margin-left: -0.4em;
    border-radius: 0 4px 4px 0;
    background-image: url("../img/icons/Camera-Grey.png");
    cursor: default;
}
#footer_container .all-sites .view-selector .upload.on,
#footer_container .all-sites-small .view-selector .upload.on {
    background-image: url("../img/icons/Camera-White.png");
    cursor: pointer;
}
#footer_container .all-sites .view-selector .upload:hover,
#footer_container .all-sites-small .view-selector .upload:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#footer_container .all-sites .view-selector .detail,
#footer_container .all-sites-small .view-selector .detail {
    border-radius: 0 4px 4px 0;
    margin-right: 1em;
    margin-left: -4px;
    background-image: url("../img/icons/Detailed-List-Off.png");
}
#footer_container .all-sites .view-selector .detail.on,
#footer_container .all-sites-small .view-selector .detail.on {
    background-image: url("../img/icons/Detailed-List-On.png");
    background-color: #4dc2c6;
}
#footer_container .all-sites .view-selector .detail:hover,
#footer_container .all-sites-small .view-selector .detail:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#footer_container .all-sites .view-selector .expander,
#footer_container .all-sites-small .view-selector .expander {
    border-radius: 4px 0 0 4px;
    margin-right: 2px;
    background-image: url("../img/icons/Open-Full-Screen-Grey.png");
}
#footer_container .all-sites .view-selector .expander.on,
#footer_container .all-sites-small .view-selector .expander.on {
    background-image: url("../img/icons/Close-Full-Screen-Grey.png");
}
#footer_container .all-sites .view-selector .expander:hover,
#footer_container .all-sites-small .view-selector .expander:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#footer_container .all-sites .view-selector .retract,
#footer_container .all-sites-small .view-selector .retract {
    border-radius: 0 4px 4px 0;
    margin-left: -0.4em;
    background-image: url("../img/icons/Show-Down.png");
}
#footer_container .all-sites .view-selector .retract.on,
#footer_container .all-sites-small .view-selector .retract.on {
    background-image: url("../img/icons/Show-Up.png");
    cursor: pointer;
}
#footer_container .all-sites .view-selector .retract:hover,
#footer_container .all-sites-small .view-selector .retract:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#footer_container .all-sites .view-selector .export-excel,
#footer_container .all-sites-small .view-selector .export-excel {
    border-radius: 4px 0 0 4px;
    margin-right: 2px;
    background-image: url("../img/icons/Excel-off.png");
    cursor: default;
}
#footer_container .all-sites .view-selector .export-excel.on,
#footer_container .all-sites-small .view-selector .export-excel.on {
    background-image: url("../img/icons/Excel-on.png");
    cursor: pointer;
}
#footer_container .all-sites .view-selector .export-excel:hover,
#footer_container .all-sites-small .view-selector .export-excel:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#footer_container .all-sites .view-selector .plus,
#footer_container .all-sites-small .view-selector .plus {
    width: 36px;
    margin-right: -10px;
    background-color: inherit;
    background-image: url("../img/icons/plus-off.png");
    cursor: default;
}
#footer_container .all-sites .view-selector .plus.on,
#footer_container .all-sites-small .view-selector .plus.on {
    background-image: url("../img/icons/plus-on.png");
    cursor: pointer;
}
#footer_container .all-sites .view-selector .minus,
#footer_container .all-sites-small .view-selector .minus {
    width: 36px;
    margin-right: -10px;
    background-color: inherit;
    background-image: url("../img/icons/minus-off.png");
    cursor: default;
}
#footer_container .all-sites .view-selector .minus.on,
#footer_container .all-sites-small .view-selector .minus.on {
    background-image: url("../img/icons/minus-on.png");
    cursor: pointer;
}
#footer_container .all-sites .view-selector .configuration,
#footer_container .all-sites-small .view-selector .configuration {
    width: 36px;
    margin-right: -10px;
    background-color: inherit;
    background-image: url("../img/icons/configuration-off.png");
    cursor: default;
}
#footer_container .all-sites .view-selector .configuration.on,
#footer_container .all-sites-small .view-selector .configuration.on {
    background-image: url("../img/icons/configuration-on.png");
    cursor: pointer;
}
#footer_container .all-sites .view-selector .setpoint,
#footer_container .all-sites-small .view-selector .setpoint {
    width: 36px;
    margin-right: -10px;
    background-color: inherit;
    background-image: url("../img/icons/setpoint-off.png");
    cursor: default;
}
#footer_container .all-sites .view-selector .setpoint.on,
#footer_container .all-sites-small .view-selector .setpoint.on {
    background-image: url("../img/icons/setpoint-on.png");
    cursor: pointer;
}
#footer_container .all-sites .hide-arrow-selector,
#footer_container .all-sites-small .hide-arrow-selector {
    background-color: #23aa80;
    background-image: url("../img/dashboard/hide-arrows.png");
    background-repeat: no-repeat;
    background-position: 70px center;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    float: right;
    padding: 7px 51px 7px 10px;
    margin: 12px 12px 0 0;
}
#footer_container .all-sites .hide-arrow-selector.show-bg,
#footer_container .all-sites-small .hide-arrow-selector.show-bg {
    background-image: url("../img/dashboard/show-arrows.png");
}
#footer_container .all-sites-small {
    height: 5.3em;
}
#footer_container .all-sites-small .view-selector {
    margin-top: 0;
    margin-left: 1.7em;
    text-align: right;
}
#footer_container .upload-container .upload {
    background-color: #4dc2c6;
    background-image: url("../img/icons/upload.png");
}
#footer_container .sites-detail-list {
    overflow-y: auto;
    position: relative;
    padding: 0 1px;
    transition: height 500ms ease;
    -ms-transition: height 500ms ease;
    -moz-transition: height 500ms ease;
}
#footer_container .sites-detail-list.hidden {
    height: 0;
}
#footer_container .sites-table-container {
    overflow-y: auto;
    position: relative;
    padding: 0 1px;
    transition: height 500ms ease;
    -ms-transition: height 500ms ease;
    -moz-transition: height 500ms ease;
}
#footer_container .sites-table-container.hidden {
    height: 0;
}
#footer_container .sites-table-container .battery,
#footer_container .sites-table-container .total-savings {
    text-align: center;
    font-size: 1.6rem;
    font-weight: 400;
}
#footer_container .sites-table-container .site-info,
#footer_container
    .sites-table-container
    #billing_container
    .billing-table-container
    .site-info-medium,
#billing_container
    .billing-table-container
    #footer_container
    .sites-table-container
    .site-info-medium {
    margin-left: 5px;
    display: inline-block;
    vertical-align: top;
}
#footer_container .sites-table-container .site-info .name,
#footer_container
    .sites-table-container
    #billing_container
    .billing-table-container
    .site-info-medium
    .name,
#billing_container
    .billing-table-container
    #footer_container
    .sites-table-container
    .site-info-medium
    .name {
    font-size: 1.6rem;
}
#footer_container .sites-table-container .site-info h1,
#footer_container
    .sites-table-container
    #billing_container
    .billing-table-container
    .site-info-medium
    h1,
#billing_container
    .billing-table-container
    #footer_container
    .sites-table-container
    .site-info-medium
    h1 {
    font-size: 16px;
    margin-bottom: 0;
}
#footer_container .sites-table-container .site-info p,
#footer_container
    .sites-table-container
    #billing_container
    .billing-table-container
    .site-info-medium
    p,
#billing_container
    .billing-table-container
    #footer_container
    .sites-table-container
    .site-info-medium
    p {
    margin: 0;
}
#footer_container .sites-table-container .site-image {
    width: 320px;
    height: 120px;
}
#footer_container .sites-table-container .sites-container-list {
    background-color: #f1f6f6;
    border-spacing: 0;
    color: #3e7075;
    position: relative;
    width: 100%;
    border-collapse: collapse;
}
#footer_container .sites-table-container .sites-container-list .current_site {
    color: #ffffff !important;
    background-color: #32cc8d !important;
}
#footer_container .sites-table-container .sites-container-list .site {
    cursor: pointer;
}
#footer_container .sites-table-container .sites-container-list thead {
    width: 100%;
    height: 100px;
    font-size: 12px;
    font-weight: 600;
}
#footer_container .sites-table-container .sites-container-list thead tr {
    background: #3cd3d5;
    color: #ffffff;
}
#footer_container .sites-table-container .sites-container-list thead tr th {
    color: #ffffff;
    border-bottom: 1px solid #d5e1e1;
    border-right: 1px solid #dae5e5;
    padding: 17px 32px 17px 12px;
    position: relative;
    white-space: nowrap;
    font-size: 1.2em;
    font-weight: 500;
}
#footer_container
    .sites-table-container
    .sites-container-list
    thead
    tr
    th:last-child {
    border-right: none;
}
#footer_container .sites-table-container .sites-container-list .__sort-true {
    background: no-repeat right center url("../img/icons/sort_desc.gif");
}
#footer_container .sites-table-container .sites-container-list .__sort-false {
    background: no-repeat right center url("../img/icons/sort_asc.gif");
    background-color: #3cd3d5;
}
#footer_container .sites-container-carousel,
#footer_container .sites-container-carousel-large {
    padding: 20px 35px;
    position: relative;
    overflow-x: hidden;
    background-color: #3d5260;
}
#footer_container .sites-container-carousel.hidden,
#footer_container .hidden.sites-container-carousel-large {
    height: 0;
}

tr.site td {
    border-bottom: 1px solid #d5e1e1;
    border-top: 1px solid #acc5c5;
    box-sizing: border-box;
}
tr.site .info,
tr.site #billing_container .billing-table-container .info-medium,
#billing_container .billing-table-container tr.site .info-medium,
tr.site .total-savings,
tr.site .battery {
    border-right: 1px solid #dae5e5;
    padding: 0 0;
}
tr.site .total-savings {
    font-size: 24px;
    font-weight: 100;
}
tr.site .status,
tr.site #carousel-container .site-info .status-all,
#carousel-container .site-info tr.site .status-all,
tr.site
    #carousel-container
    #billing_container
    .billing-table-container
    .site-info-medium
    .status-all,
#carousel-container
    #billing_container
    .billing-table-container
    .site-info-medium
    tr.site
    .status-all,
tr.site
    #billing_container
    .billing-table-container
    #carousel-container
    .site-info-medium
    .status-all,
#billing_container
    .billing-table-container
    #carousel-container
    .site-info-medium
    tr.site
    .status-all {
    padding: 0 24px;
}
tr.site .battery {
    font-size: 20px;
    font-weight: bold;
}
tr.site .site-info,
tr.site #billing_container .billing-table-container .site-info-medium,
#billing_container .billing-table-container tr.site .site-info-medium {
    margin-left: 5px;
    display: inline-block;
    vertical-align: top;
}
tr.site .site-info h1,
tr.site #billing_container .billing-table-container .site-info-medium h1,
#billing_container .billing-table-container tr.site .site-info-medium h1 {
    font-size: 16px;
    margin-bottom: 0;
}
tr.site .site-info p,
tr.site #billing_container .billing-table-container .site-info-medium p,
#billing_container .billing-table-container tr.site .site-info-medium p {
    margin: 0;
    max-width: 230px;
}
tr.site .site-image {
    width: 320px;
    height: 120px;
}
tr.site:nth-child(2n) {
    background-color: #eaf0f0;
}

/* ----------- iPad 1 & 2 ----------- */
#sites-list-container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin-top: 110px;
    margin-left: 5px;
}
#sites-list-container .list_view {
    border: 1px solid green;
    background-color: white;
    height: 80%;
}

.hide-grid,
.hide {
    display: none;
}

#sites-list-directive-container .sites-list-filter input[type="text"] {
    background-image: url("../img/icons/search.png");
    background-repeat: no-repeat;
    background-position: 8px 9px;
    border-radius: 4px;
    border: 1px solid #58788b;
    box-sizing: border-box;
    color: #fff;
    background-color: #2f3e4c;
    padding: 10px 10px 10px 32px;
    min-width: 270px;
    outline: none;
    display: inline-block;
    width: 15%;
}
#sites-list-directive-container .up::before {
    content: "\c359";
}
#sites-list-directive-container .sites-list-table {
    font-family: "Arial";
    border-collapse: collapse;
    border: 1px solid #eee;
    border-bottom: 2px solid #00cccc;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1),
        0px 10px 20px rgba(0, 0, 0, 0.05), 0px 20px 20px rgba(0, 0, 0, 0.05),
        0px 30px 20px rgba(0, 0, 0, 0.05);
}
#sites-list-directive-container .sites-list-table-container2 table {
    border-collapse: separate;
    background: #fff;
    border-radius: 5px;
    border-spacing: 0px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}
#sites-list-directive-container .sites-list-table-container2 thead {
    border-radius: 5px;
}
#sites-list-directive-container .sites-list-table-container2 thead th {
    font-size: 13px;
    font-weight: 400;
    color: #fff;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
    text-align: left;
    padding: 5px;
    background-image: linear-gradient(#646f7f, #4a5564);
    border-top: 1px solid #858d99;
}
#sites-list-directive-container
    .sites-list-table-container2
    thead
    th:first-child {
    border-top-left-radius: 5px;
}
#sites-list-directive-container
    .sites-list-table-container2
    thead
    th:last-child {
    border-top-right-radius: 5px;
}
#sites-list-directive-container .sites-list-table-container2 tbody tr td {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    color: #5f6062;
    font-size: 12px;
    border-bottom: 1px solid #e0e0e0;
}
#sites-list-directive-container
    .sites-list-table-container2
    tbody
    tr:nth-child(2n) {
    background: #f0f3f5;
}
#sites-list-directive-container
    .sites-list-table-container2
    tbody
    tr:last-child
    td {
    border-bottom: none;
}
#sites-list-directive-container
    .sites-list-table-container2
    tbody
    tr:last-child
    td:first-child {
    border-bottom-left-radius: 5px;
}
#sites-list-directive-container
    .sites-list-table-container2
    tbody
    tr:last-child
    td:last-child {
    border-bottom-right-radius: 5px;
}
#sites-list-directive-container
    .sites-list-table-container2
    tbody:hover
    > tr
    td {
    opacity: 0.5;
    /* uncomment for blur effect */
    /* color:transparent;
  //@include text-shadow(0px 0px 2px rgba(0,0,0,0.8));*/
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.8);
}
#sites-list-directive-container
    .sites-list-table-container2
    tbody:hover
    > tr:hover
    td {
    text-shadow: none;
    color: #2d2d2d;
    opacity: 1;
}
#sites-list-directive-container .sites-list-table2 {
    width: 100%;
    border-spacing: 0;
    background: #34495e;
    color: #fff;
    border-radius: 0.4em;
    overflow: hidden;
}
#sites-list-directive-container .sites-list-table2 td {
    display: block;
}
#sites-list-directive-container .sites-list-table2 td:first-child {
    padding-top: 0.5em;
}
#sites-list-directive-container .sites-list-table2 td:last-child {
    padding-bottom: 0.5em;
}
#sites-list-directive-container .sites-list-table2 td:before {
    content: attr(data-th) ": ";
    font-weight: bold;
    width: 6.5em;
    display: inline-block;
}
#sites-list-directive-container .sites-list-table2 th,
#sites-list-directive-container .sites-list-table2 td {
    text-align: left;
}
#sites-list-directive-container .sites-list-table2 tr {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-color: #46637f;
}
#sites-list-directive-container .sites-list-table2 th,
#sites-list-directive-container .sites-list-table2 td {
    margin: 0.5em 1em;
    padding: 1em !important;
}
#sites-list-directive-container .sites-list-table2 th,
#sites-list-directive-container .sites-list-table2 td:before {
    color: #dd5;
}

#portfolio-container .overview {
    padding: 4px;
}

#system-config-form-container .system-config-form label {
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: #666;
    font-weight: 500;
    text-align: left;
}
#system-config-form-container .system-config-form input {
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 40px;
    line-height: 20px;
    padding: 2px 0 0 12px;
    font-size: 15px;
}
#system-config-form-container .system-config-form select {
    border-radius: 4px;
}
#system-config-form-container .form-horizontal .form-group {
    margin-left: -5px;
    margin-right: 5px;
}
#system-config-form-container .form-horizontal .form-group label {
    display: block;
    font-size: 12px;
    line-height: 20px;
    color: #666;
    font-weight: 500;
}
#system-config-form-container .form-horizontal .form-group input {
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 40px;
    line-height: 20px;
    padding: 2px 0 0 12px;
    font-size: 14px;
}
#system-config-form-container .form-horizontal .control-label {
    text-align: right;
    margin-top: 0px;
    margin-bottom: 0;
    padding-top: 7px;
}
#system-config-form-container .form-horizontal .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
#system-config-form-container .form-group {
    margin-bottom: 15px;
}

#setpoint-config-form .amount {
    padding-top: 4%;
}
#setpoint-config-form .level {
    padding-top: 5%;
}
#setpoint-config-form .custom {
    margin-left: 5px;
}

#setpoint-config-form-container {
    width: 450px;
}
#setpoint-config-form-container .setpoint-dialog {
    background-color: white;
    width: 20%;
    height: 200px;
}
#setpoint-config-form-container .setpoint-config-form label {
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: #666;
    font-weight: 500;
    text-align: left;
    margin-top: 5px;
}
#setpoint-config-form-container .setpoint-config-form input {
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 25px;
    line-height: 20px;
    font-size: 15px;
}
#setpoint-config-form-container .setpoint-config-form select {
    border-radius: 4px;
}
#setpoint-config-form-container .setpoint-config-form .save-btn {
    background-color: white;
    right: -155px;
    font-size: 14px;
}
#setpoint-config-form-container .setpoint-config-form .cancel-btn {
    background-color: white;
    float: right;
    font-size: 14px;
}

#site-config-form-container .site-config-form legend {
    font-size: 15px;
    font-weight: normal;
}
#site-config-form-container .site-config-form label {
    display: block;
    font-size: 14px;
    height: 25px;
    color: #666;
    font-weight: 500;
    text-align: left;
    margin: 0 0 1.1rem 0;
}
#site-config-form-container .site-config-form input {
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 25px;
    font-size: 14px;
    margin: 0;
}
#site-config-form-container .site-config-form select {
    border-radius: 4px;
}
#site-config-form-container .site-config-form .close-btn {
    background-color: white;
    float: right;
    font-size: 14px;
}

#site-info-container {
    border: 2px solid black;
}
#site-info-container table {
    background: none;
    border: none;
    margin: inherit;
    border-collapse: collapse;
}
#site-info-container td,
#site-info-container th {
    padding: 0.5rem;
}
#site-info-container tr:nth-child(odd) {
    background-color: #eee;
}
#site-info-container tr:nth-child(even) {
    background-color: #eee;
}

.flex-config-form {
    /* 1- positionning the 2 divs */
    /* form > div + div {
      margin-left: 1em;
  } */
    /* 2- fluid inputs */
    /* 3- fluid textarea */
}
.flex-config-form form {
    display: flex;
}
.flex-config-form form > div {
    flex: 1;
    min-width: 0;
    /* fix for min-width: auto */
}
.flex-config-form div > p {
    display: flex;
    margin: 0;
}
.flex-config-form form input:not([type="submit"]) {
    flex: 1;
    /* fluid width */
    display: block;
    /* IE fix */
    min-width: 0;
    /* fix for min-width: auto */
}
.flex-config-form label {
    width: 12em;
    margin-right: 0.5em;
    font-size: 1.2em;
}
.flex-config-form input[type="text"] {
    font-size: 0.8em;
    height: 2.2em;
    border-radius: 4px;
    width: 300px;
}
.flex-config-form input[type="text"]:focus,
.flex-config-form input[type="text"]:hover {
    box-shadow: 0 0 5px #43d1af;
    padding: 2%;
    border: 1px solid #43d1af;
}
.flex-config-form input {
    border-radius: 4px;
}
.flex-config-form .header-flex {
    display: flex;
    justify-content: space-between;
    margin-left: 2%;
    margin-bottom: 1%;
    background-color: #6e7781;
    border-radius: 4px;
}
.flex-config-form .header-flex .header-section {
    display: flex;
    align-items: center;
}
.flex-config-form .header-flex .header-item {
    padding: 5px 15px;
    font-size: 12px;
}
.flex-config-form .form-section {
    margin-left: 4%;
    background-color: #eaebed;
}

.flex-config-form {
    /* 1- positionning the 2 divs */
    /* form > div + div {
      margin-left: 1em;
  } */
    /* 2- fluid inputs */
    /* 3- fluid textarea */
}
.flex-config-form form {
    display: flex;
}
.flex-config-form form > div {
    flex: 1;
    min-width: 0;
    /* fix for min-width: auto */
}
.flex-config-form div > p {
    display: flex;
    margin: 0;
}
.flex-config-form form input:not([type="submit"]) {
    flex: 1;
    /* fluid width */
    display: block;
    /* IE fix */
    min-width: 0;
    /* fix for min-width: auto */
}
.flex-config-form label {
    width: 12em;
    margin-right: 0.5em;
    font-size: 1.2em;
}
.flex-config-form input[type="text"] {
    font-size: 0.8em;
    height: 2.2em;
    border-radius: 4px;
    width: 300px;
}
.flex-config-form input[type="text"]:focus,
.flex-config-form input[type="text"]:hover {
    box-shadow: 0 0 5px #43d1af;
    padding: 2%;
    border: 1px solid #43d1af;
}
.flex-config-form input {
    border-radius: 4px;
}
.flex-config-form .header-flex {
    display: flex;
    justify-content: space-between;
    margin-left: 2%;
    margin-bottom: 1%;
    background-color: #6e7781;
    border-radius: 4px;
}
.flex-config-form .header-flex .header-section {
    display: flex;
    align-items: center;
}
.flex-config-form .header-flex .header-item {
    padding: 5px 15px;
    font-size: 12px;
}
.flex-config-form .form-section {
    margin-left: 4%;
    background-color: #eaebed;
    padding: 5px;
}

.config-form {
    padding: 1em;
    background: #eee;
}
.config-form .watermark {
    background-image: url("../img/icons/watermark.png");
    background-repeat: no-repeat;
    background-position: center center;
    width: 74px;
    height: 30px;
}
.config-form .panel-heading {
    height: 2.5em;
}
.config-form .required {
    padding-left: 2rem !important;
}
.config-form .cal {
    padding-top: 7px;
}
.config-form legend {
    background: #eee;
    margin-bottom: 10px;
}
.config-form select {
    font-size: 0.8em;
    margin-bottom: 0.5em;
    border-radius: 4px;
    height: 3em;
}
.config-form select.error {
    border: 1px solid red;
}
.config-form label {
    font-size: 1em;
    font-weight: bolder;
}
.config-form input {
    padding: 0.4em;
    box-sizing: border-box;
}
.config-form input[type="text"] {
    font-size: 0.8em;
    height: 3em;
    border-radius: 4px;
}
.config-form input[type="text"].error {
    border: 1px solid red;
}
.config-form input[type="text"]:focus,
.config-form input[type="text"]:hover {
    box-shadow: 0 0 5px #43d1af;
    padding: 2%;
    border: 1px solid #43d1af;
}
.config-form input[readonly] {
    border: 0;
    color: gray;
}
.config-form input[readonly="readonly"] {
    border: 0;
    color: gray;
}
.config-form .__heading {
    font-size: 1em;
    font-weight: normal;
}
.config-form .__icon {
    float: right;
}
.config-form .form-group .help-block {
    display: none;
}
.config-form .form-group.has-error .help-block {
    display: inline-block;
}
.config-form .help-block {
    font-size: 10px;
}

.green-button {
    background-color: #26ac82;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    font-weight: 300;
    padding: 10px 20px;
    -webkit-appearance: none;
}
.green-button:hover {
    background-color: #1d8262;
}

.grey-button {
    background-color: #a3a8b5;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    font-weight: 300;
    padding: 10px 20px;
    -webkit-appearance: none;
}
.grey-button:hover {
    background-color: #848a9c;
}

.grey-button-no-anchor:hover {
    color: #fff !important;
    text-decoration: none !important;
}

#fixed-header {
    width: 100%;
    overflow-y: scroll;
}
#fixed-header table {
    overflow-y: scroll;
    border-collapse: collapse;
}
#fixed-header tbody {
    overflow-x: scroll;
    display: block;
    height: 45%;
}
#fixed-header thead {
    display: table-row;
}
#fixed-header th,
#fixed-header td {
    min-width: 160px;
}

.list-table {
    font-size: 0.8em;
    text-align: left;
    border: 1px solid #ccc;
}
.list-table .__current_site {
    color: #ffffff !important;
    background-color: #32cc8d !important;
}
.list-table thead {
    border: 0.1em solid white;
}
.list-table .__header {
    border-bottom: 1px solid #d5e1e1;
    border-right: 1px solid #dae5e5;
    padding: 17px 32px 17px 12px;
    background: #3cd3d5;
    font-size: 1.2em;
    color: #ffffff;
    line-height: 1.125em;
}
.list-table .__sort-true {
    background: no-repeat right center url("../img/icons/sort_desc.gif");
    background-color: #3cd3d5;
}
.list-table .__sort-false {
    background: no-repeat right center url("../img/icons/sort_asc.gif");
    background-color: #3cd3d5;
}
.list-table tbody tr {
    background: #f0f8ff;
    border-left: 0.1em solid white;
}
.list-table tbody tr:nth-child(odd) {
    background: #f0f8ff;
}
.list-table tbody tr:nth-child(even) {
    background: #e6f3ff;
}
.list-table tbody tr:hover,
.list-table tbody tr.selected {
    background: #32cc8d;
}
.list-table tbody td {
    font-size: 1em;
    border-right: 1px solid #dae5e5;
    border-bottom: 1px solid #dae1e1;
    border-top: 1px solid #acc5c5;
}

.filter-by-container {
    margin-top: 1%;
    color: #95b6b6;
    font-size: 10px;
    height: 3em;
    box-sizing: border-box;
}
.filter-by-container .filterBy {
    color: white;
}
.filter-by-container label {
    color: white;
    margin-top: 5px;
    font-size: 11px;
    font-weight: 100;
}
.filter-by-container select {
    -webkit-appearance: none;
    border-radius: 4px;
    border: 1px solid #58788b;
    box-sizing: border-box;
    color: #fff;
    background-color: #2f3e4c;
    outline: none;
    height: 2.6em;
}
.filter-by-container input[type="text"] {
    background-image: url("../img/icons/search.png");
    background-repeat: no-repeat;
    background-position: 0 4px;
    border-radius: 4px;
    border: 1px solid #58788b;
    box-sizing: border-box;
    color: #fff;
    background-color: #2f3e4c;
    padding-left: 2.3em;
    outline: none;
    height: 2.3em;
    font-size: 11px;
}

.modal .modal-dialog .modal-content .config-icon-container-2 {
    width: 52em;
}

.modal .modal-dialog .modal-content .config-icon-container {
    width: 40%;
}

.error-modal-window .modal-dialog {
    background-color: #ccc;
    width: 60em;
}

.create-analysis-app-modal-window .modal-dialog {
    width: 100%;
}

.compare-analysis-app-modal-window .modal-dialog {
    width: 80%;
}

.are-you-sure-app-modal-window .modal-dialog {
    width: 35em;
    margin-top: 20%;
}

.pmt-upload-app-modal-window .modal-dialog {
    width: 70em;
}

.app-modal-window .modal-dialog {
    width: 127em;
}

.config-icon-container-2,
.config-icon-container {
    margin-top: 0.5em;
    text-align: right;
}
.config-icon-container-2 .plus,
.config-icon-container-2 .minus,
.config-icon-container-2 .configuration,
.config-icon-container-2 .export-excel,
.config-icon-container-2 .import-grid,
.config-icon-container-2 .rerun,
.config-icon-container-2 .open-folder,
.config-icon-container .plus,
.config-icon-container .minus,
.config-icon-container .configuration,
.config-icon-container .export-excel,
.config-icon-container .import-grid,
.config-icon-container .rerun,
.config-icon-container .open-folder {
    width: 45px;
    height: 33px;
    background-color: #2f3e4c;
    background-position: center center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    display: inline-block;
    cursor: pointer;
}
.config-icon-container-2 .rerun,
.config-icon-container .rerun {
    margin-right: -4px;
    background-image: url("../img/icons/rerun.png");
    cursor: pointer;
}
.config-icon-container-2 .rerun.on,
.config-icon-container .rerun.on {
    background-image: url("../img/icons/rerun.png");
    cursor: pointer;
}
.config-icon-container-2 .rerun:hover,
.config-icon-container .rerun:hover {
    border: 1px solid white;
    border-radius: 1px;
}
.config-icon-container-2 .plus,
.config-icon-container .plus {
    margin-right: -4px;
    background-image: url("../img/icons/plus-off.png");
    cursor: pointer;
}
.config-icon-container-2 .plus.on,
.config-icon-container .plus.on {
    background-image: url("../img/icons/plus-on.png");
    cursor: pointer;
}
.config-icon-container-2 .plus:hover,
.config-icon-container .plus:hover {
    border: 1px solid white;
    border-radius: 1px;
}
.config-icon-container-2 .minus,
.config-icon-container .minus {
    background-image: url("../img/icons/minus-off.png");
    cursor: pointer;
}
.config-icon-container-2 .minus.on,
.config-icon-container .minus.on {
    background-image: url("../img/icons/minus-on.png");
    cursor: pointer;
}
.config-icon-container-2 .minus:hover,
.config-icon-container .minus:hover {
    border: 1px solid white;
    border-radius: 1px;
}
.config-icon-container-2 .open-folder,
.config-icon-container .open-folder {
    margin-left: -4px;
    background-image: url("../img/icons/folder-icon.png");
    cursor: pointer;
    border-radius: 0 4px 4px 0;
}
.config-icon-container-2 .open-folder.on,
.config-icon-container .open-folder.on {
    background-image: url("../img/icons/folder-icon-white.png");
    cursor: pointer;
}
.config-icon-container-2 .open-folder:hover,
.config-icon-container .open-folder:hover {
    border: 1px solid white;
    border-radius: 1px;
}
.config-icon-container-2 .configuration,
.config-icon-container .configuration {
    margin-right: -4px;
    background-image: url("../img/icons/configuration-off.png");
    cursor: pointer;
}
.config-icon-container-2 .configuration.on,
.config-icon-container .configuration.on {
    background-image: url("../img/icons/configuration-on.png");
    cursor: pointer;
}
.config-icon-container-2 .configuration:hover,
.config-icon-container .configuration:hover {
    border: 1px solid white;
    border-radius: 1px;
}
.config-icon-container-2 .export-excel,
.config-icon-container .export-excel {
    width: 34px;
    height: 33px;
    margin-right: -4px;
    border-right: 1px solid #2f3e4c;
    border-radius: 4px 0 0 4px;
    background-image: url("../img/icons/Excel-off.png");
    cursor: pointer;
}
.config-icon-container-2 .export-excel.on,
.config-icon-container .export-excel.on {
    background-image: url("../img/icons/Excel-on.png");
    cursor: pointer;
}
.config-icon-container-2 .export-excel:hover,
.config-icon-container .export-excel:hover {
    border: 1px solid white;
    border-radius: 1px;
}
.config-icon-container-2 .import-grid,
.config-icon-container .import-grid {
    width: 34px;
    height: 33px;
    margin-right: -4px;
    border-right: 1px solid #2f3e4c;
    border-radius: 4px 0 0 4px;
    background-image: url("../img/icons/Excel-off.png");
    background-image: url("../img/icons/Camera-Grey.png");
    cursor: pointer;
}
.config-icon-container-2 .import-grid.on,
.config-icon-container .import-grid.on {
    background-image: url("../img/icons/Camera-White.png");
    cursor: pointer;
}
.config-icon-container-2 .import-grid:hover,
.config-icon-container .import-grid:hover {
    border: 1px solid white;
    border-radius: 1px;
}

.bread-crumbs {
    padding-left: 1em;
}
.bread-crumbs li {
    cursor: pointer;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 1em;
    font-size: 14px;
    font-weight: normal;
}
.bread-crumbs li .active {
    color: #3cd3d5;
}
.bread-crumbs li a {
    text-decoration: none;
}

.breadcrumbs-txt {
    height: 100%;
    color: #fff;
}

.breadcrumbs-align {
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
}
.breadcrumbs-align--first {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.breadcrumbs,
.breadcrumbs-telstra,
.breadcrumbs-small {
    margin-top: 0.2%;
    background-color: #303e4b;
    border-bottom: 0px solid #c3d3d3;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
    color: #96b6b6;
    padding: 0 10px;
    height: 56px;
    line-height: 45px;
}
.breadcrumbs--primary {
    color: #3cd3d5;
    cursor: pointer;
}
.breadcrumbs ul,
.breadcrumbs-telstra ul,
.breadcrumbs-small ul {
    float: right;
    list-style-type: none;
    margin: 0;
    padding: 0;
    border: 15px;
}
.breadcrumbs ul li,
.breadcrumbs-telstra ul li,
.breadcrumbs-small ul li {
    cursor: pointer;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center 0px;
    font-size: 9px;
    padding-top: 38px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    color: #fff;
}
.breadcrumbs ul li:hover,
.breadcrumbs-telstra ul li:hover,
.breadcrumbs-small ul li:hover,
.breadcrumbs ul li.active,
.breadcrumbs-telstra ul li.active,
.breadcrumbs-small ul li.active {
    background-color: none;
    color: white;
    border: 2px solid #3cd3d5;
    border-radius: 5px;
}

.breadcrumbs-small ul li {
    height: 35px;
}

.edit-project-dialog .modal-content {
    width: 80em;
    min-width: 80em;
}

.tooltipTable {
    display: table;
}

.tooltipRow {
    display: table-row;
}

.tooltipCell {
    display: table-cell;
    border-width: thin;
    text-align: left;
    font-size: 9px;
}

table thead tr th {
    text-align: center;
}

.fixed-table-container {
    height: 570px;
    border: 1px solid black;
    margin: 10px auto;
    background-color: white;
    position: relative;
    padding-top: 30px;
}
.fixed-table-container table {
    background-color: white;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}
.fixed-table-container table tbody tr td {
    border-bottom: 1px solid #ccc;
    padding: 5px;
    text-align: left;
}
.fixed-table-container th {
    padding: 0 5px;
    text-align: left;
}
.fixed-table-container .th-inner {
    position: absolute;
    top: 0;
    line-height: 30px;
    text-align: left;
}

.header-background {
    background-color: #d5ecff;
    height: 30px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border-bottom: 1px solid black;
}

.fixed-table-container-inner {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
}

.fixed-header-container {
    overflow: auto;
}
.fixed-header-container thead {
    display: block;
}
.fixed-header-container thead tr th {
    width: 160px;
}
.fixed-header-container tbody {
    display: block;
    height: 500px;
    overflow-y: scroll;
    overflow-x: visible;
}
.fixed-header-container tbody tr td {
    width: 160px;
}

.fixed_headers {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    font-size: 0.8em;
    text-align: left;
}
.fixed_headers th {
    text-decoration: underline;
}
.fixed_headers th,
.fixed_headers td {
    padding: 5px;
    text-align: left;
}
.fixed_headers td:nth-child(1),
.fixed_headers th:nth-child(1) {
    min-width: 100px;
}
.fixed_headers td:nth-child(2),
.fixed_headers th:nth-child(2) {
    min-width: 100px;
}
.fixed_headers td:nth-child(3),
.fixed_headers th:nth-child(3) {
    width: 100px;
}
.fixed_headers td:nth-child(4),
.fixed_headers th:nth-child(4) {
    min-width: 100px;
}
.fixed_headers td:nth-child(5),
.fixed_headers th:nth-child(5) {
    min-width: 100px;
}
.fixed_headers td:nth-child(6),
.fixed_headers th:nth-child(6) {
    width: 100px;
}
.fixed_headers td:nth-child(7),
.fixed_headers th:nth-child(7) {
    min-width: 100px;
}
.fixed_headers td:nth-child(8),
.fixed_headers th:nth-child(8) {
    min-width: 100px;
}
.fixed_headers td:nth-child(9),
.fixed_headers th:nth-child(9) {
    width: 100px;
}
.fixed_headers td:nth-child(10),
.fixed_headers th:nth-child(10) {
    min-width: 100px;
}
.fixed_headers td:nth-child(11),
.fixed_headers th:nth-child(11) {
    min-width: 100px;
}
.fixed_headers td:nth-child(12),
.fixed_headers th:nth-child(12) {
    width: 100px;
}
.fixed_headers .__current_site {
    color: #ffffff !important;
    background-color: #32cc8d !important;
}
.fixed_headers thead {
    background-color: #3cd3d5;
    border: 0.1em solid white;
    color: white;
}
.fixed_headers thead tr {
    display: block;
    position: relative;
}
.fixed_headers .__sort-true {
    background: no-repeat right center url("../img/icons/sort_desc.gif");
    background-color: #3cd3d5;
}
.fixed_headers .__sort-false {
    background: no-repeat right center url("../img/icons/sort_asc.gif");
    background-color: #3cd3d5;
}
.fixed_headers tbody {
    display: block;
    overflow: auto;
    width: 100%;
    height: 575px;
}
.fixed_headers tbody tr {
    background: #f0f8ff;
    border-left: 0.1em solid white;
}
.fixed_headers tbody tr:nth-child(odd) {
    background: #f0f8ff;
}
.fixed_headers tbody tr:nth-child(even) {
    background: #e6f3ff;
}
.fixed_headers tbody tr:hover,
.fixed_headers tbody tr.selected {
    background: #32cc8d;
}
.fixed_headers tbody td {
    font-size: 1em;
    border-right: 1px solid #dae5e5;
    border-bottom: 1px solid #dae1e1;
    border-top: 1px solid #acc5c5;
}

.fixed_headers1 {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}
.fixed_headers1 th {
    text-decoration: underline;
}
.fixed_headers1 th,
.fixed_headers1 td {
    padding: 5px;
    text-align: left;
}
.fixed_headers1 td:nth-child(1),
.fixed_headers1 th:nth-child(1) {
    min-width: 100px;
}
.fixed_headers1 td:nth-child(2),
.fixed_headers1 th:nth-child(2) {
    min-width: 100px;
}
.fixed_headers1 td:nth-child(3),
.fixed_headers1 th:nth-child(3) {
    width: 100px;
}
.fixed_headers1 td:nth-child(4),
.fixed_headers1 th:nth-child(4) {
    min-width: 100px;
}
.fixed_headers1 td:nth-child(5),
.fixed_headers1 th:nth-child(5) {
    min-width: 100px;
}
.fixed_headers1 td:nth-child(6),
.fixed_headers1 th:nth-child(6) {
    width: 100px;
}
.fixed_headers1 td:nth-child(7),
.fixed_headers1 th:nth-child(7) {
    min-width: 100px;
}
.fixed_headers1 td:nth-child(8),
.fixed_headers1 th:nth-child(8) {
    min-width: 100px;
}
.fixed_headers1 td:nth-child(9),
.fixed_headers1 th:nth-child(9) {
    width: 100px;
}
.fixed_headers1 td:nth-child(10),
.fixed_headers1 th:nth-child(10) {
    min-width: 100px;
}
.fixed_headers1 td:nth-child(11),
.fixed_headers1 th:nth-child(11) {
    min-width: 100px;
}
.fixed_headers1 td:nth-child(12),
.fixed_headers1 th:nth-child(12) {
    width: 100px;
}
.fixed_headers1 thead {
    background-color: #3cd3d5;
    color: white;
}
.fixed_headers1 thead tr {
    display: block;
    position: relative;
}
.fixed_headers1 tbody {
    display: block;
    overflow: auto;
    width: 100%;
    height: 575px;
}
.fixed_headers1 tbody tr:nth-child(even) {
    background-color: #ddd;
}

.fixed_headers2 {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    font-size: 0.8em;
    text-align: left;
}
.fixed_headers2 th {
    text-decoration: none;
    line-height: 30px;
}
.fixed_headers2 th,
.fixed_headers2 td {
    padding: 5px;
    text-align: left;
}
.fixed_headers2 thead {
    background-color: #3cd3d5;
}
.fixed_headers2 thead tr {
    display: block;
    position: relative;
    background: #f0f8ff;
    border-left: 0.1em solid white;
}
.fixed_headers2 thead tr th {
    color: white;
    border-bottom: 1px solid #d5e1e1;
    border-right: 1px solid #dae5e5;
    background: #3cd3d5;
    font-size: 0.9em;
    font-weight: 500;
}
.fixed_headers2 tbody {
    display: block;
    overflow: auto;
    height: 575px;
}
.fixed_headers2 tbody tr {
    background: #f0f8ff;
    border-left: 0.1em solid white;
}
.fixed_headers2 tbody tr:nth-child(odd) {
    background: #f0f8ff;
}
.fixed_headers2 tbody tr:nth-child(even) {
    background: #e6f3ff;
}
.fixed_headers2 tbody tr:hover,
.fixed_headers2 tbody tr.selected {
    background: #32cc8d;
}
.fixed_headers2 tbody td {
    font-size: 1em;
    border-right: 1px solid #dae5e5;
    border-bottom: 1px solid #dae1e1;
    border-top: 1px solid #acc5c5;
}

.project-detail-overview {
    margin-left: -10px;
    padding-bottom: 0.8em;
    background-color: #eee;
    border: 1px solid #d2d2d2;
}
.project-detail-overview legend {
    width: 103%;
}
.project-detail-overview label {
    padding-left: 2em;
}

.ag-gcn .ag-root {
    border: 1px solid #808080;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.ag-gcn .ag-cell {
    font-size: 0.875rem;
    border: 1px solid #ccc;
    padding: 2px;
    background-color: #fff;
}

.ag-gcn .ag-cell-focus {
    border: 1px solid #dae1e1;
}

.ag-gcn .ag-cell-no-focus {
    border-right: 1px solid #dae1e1;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-bottom: 1px solid transparent;
}

.ag-gcn .ag-pinned-header {
    background: -webkit-linear-gradient(#fff, #d3d3d3);
    background: -moz-linear-gradient(#fff, #d3d3d3);
    background: -o-linear-gradient(#fff, #d3d3d3);
    background: -ms-linear-gradient(#fff, #d3d3d3);
    background: linear-gradient(#fff, #d3d3d3);
    border-bottom: 1px solid #808080;
}

.ag-gcn .ag-pinned-left-header {
    background: -webkit-linear-gradient(#3cd3d5, #3cd3d5);
    background: -moz-linear-gradient(#3cd3d5, #3cd3d5);
    background: -o-linear-gradient(#3cd3d5, #3cd3d5);
    background: -ms-linear-gradient(#3cd3d5, #3cd3d5);
    background: linear-gradient(#3cd3d5, #3cd3d5);
    border-bottom: 1px solid #ffffff;
    font-size: 0.875rem;
    font-weight: 300;
    color: #ffffff;
    line-height: 1.125em;
}

.ag-gcn .ag-header-container {
    background: -webkit-linear-gradient(#3cd3d5, #3cd3d5);
    background: -moz-linear-gradient(#3cd3d5, #3cd3d5);
    background: -o-linear-gradient(#3cd3d5, #3cd3d5);
    background: -ms-linear-gradient(#3cd3d5, #3cd3d5);
    background: linear-gradient(#3cd3d5, #3cd3d5);
    border-bottom: 1px solid #ffffff;
    font-size: 0.875rem;
    font-weight: 300;
    color: #ffffff;
    line-height: 1.125em;
}

.ag-header {
    background: -webkit-linear-gradient(#3cd3d5, #3cd3d5);
    background: -moz-linear-gradient(#3cd3d5, #3cd3d5);
    background: -o-linear-gradient(#3cd3d5, #3cd3d5);
    background: -ms-linear-gradient(#3cd3d5, #3cd3d5);
    background: linear-gradient(#3cd3d5, #3cd3d5);
}

.ag-gcn .ag-header-cell {
    border-right: 1px solid #ffffff;
    overflow: visible;
    white-space: normal;
    text-overflow: clip;
}

.ag-gcn .ag-header-group-cell {
    border-right: 1px solid #808080;
}

.ag-gcn .ag-header-group-cell-with-group {
    border-bottom: 1px solid #808080;
}

.ag-gcn .ag-header-cell-label {
    padding: 4px 2px 4px 2px;
}

.ag-gcn .ag-header-cell-text {
    padding-left: 2px;
}

.ag-gcn .ag-header-group-cell-label {
    padding: 4px;
    font-weight: 700;
}

.ag-gcn .ag-header-group-text {
    margin-right: 2px;
}

.ag-gcn .ag-header-cell-menu-button {
    padding: 2px;
    margin-top: 4px;
    border: 1px solid transparent;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    /* When using bootstrap, box-sizing was set to 'border-box' */
}

.ag-gcn .ag-header-cell-menu-button:hover {
    border: 1px solid #000;
}

.ag-gcn .ag-header-icon {
    color: #800000;
}

.ag-gcn .ag-row:hover {
    background: #32cc8d;
}

.ag-gcn .ag-row-selected {
    background-color: #32cc8d !important;
    color: black !important;
}

.ag-gcn .ag-body .ag-row-odd {
    background-color: #f0f8ff;
}

.ag-gcn .ag-body .ag-row-even {
    background-color: #e6f3ff;
}

.ag-gcn .ag-floating-top .ag-row {
    background-color: #f0f0f0;
}

.ag-gcn .ag-floating-bottom .ag-row {
    background-color: #f0f0f0;
}

.ag-gcn .ag-overlay-loading-wrapper {
    background-color: rgba(255, 255, 255, 0.5);
}

.ag-gcn .ag-overlay-loading-center {
    background-color: #fff;
    border: 1px solid #a9a9a9;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    padding: 10px;
}

.ag-gcn .ag-overlay-no-rows-center {
    background-color: #fff;
    border: 1px solid #a9a9a9;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    padding: 10px;
}

.ag-gcn .ag-body {
    background-color: #2f3e4c;
}

.ag-gcn .ag-group-cell-entire-row {
    background-color: #aaa;
    padding: 4px;
}

.ag-gcn .ag-footer-cell-entire-row {
    background-color: #aaa;
    padding: 4px;
}

.ag-gcn .ag-group-cell {
    font-style: italic;
}

.ag-gcn .ag-group-expand {
    padding-right: 2px;
}

.ag-gcn .ag-footer-cell {
    font-style: italic;
}

.ag-gcn .ag-filter-checkbox {
    position: relative;
    top: 2px;
    left: 2px;
}

.ag-gcn .ag-filter-header-container {
    border-bottom: 1px solid #d3d3d3;
}

.ag-gcn .ag-filter-apply-panel {
    border-top: 1px solid #d3d3d3;
    padding: 2px;
}

.ag-gcn .ag-filter {
    border: 1px solid #000;
    background-color: #f0f0f0;
}

.ag-gcn .ag-filter-value {
    margin-left: 4px;
}

.ag-gcn .ag-selection-checkbox {
    margin-left: 4px;
}

.ag-gcn .ag-paging-panel {
    padding: 4px;
}

.ag-gcn .ag-paging-button {
    margin-left: 4px;
    margin-right: 4px;
}

.ag-gcn .ag-paging-row-summary-panel {
    display: inline-block;
    width: 300px;
}

.ag-gcn .ag-column-not-visible {
    opacity: 0.75;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
    filter: alpha(opacity=75);
}

.ag-gcn .ag-not-dragging {
    border: 1px solid transparent;
}

.ag-gcn .ag-drop-target-above {
    border-top: 5px solid #a9a9a9;
}

.ag-gcn .ag-drop-target-below {
    border-bottom: 5px solid #a9a9a9;
}

.ag-gcn .ag-dragging {
    border: 1px dotted #a9a9a9;
}

.ag-gcn .ag-list-item-selected {
    color: #f0f0f0;
}

.ag-gcn .ag-list-item-not-selected {
    font-style: italic;
    color: #a0a0a0;
}

.ag-gcn .ag-tool-panel-container {
    background-color: #eee;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #a9a9a9;
    padding: 4px;
}

.ag-gcn .ag-list-selection {
    background-color: #fff;
    border: 1px solid #a9a9a9;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.ag-gcn .ag-popup-list .ag-list-item:hover {
    background-color: #add8e6;
}

.ag-gcn .ag-visible-icons {
    padding-left: 2px;
    padding-right: 2px;
}

.ag-gcn input[type="text"] {
    font-size: 1rem;
}

.ag-gcn-measurer-billing .ag-root {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.ag-gcn-measurer-billing .ag-cell {
    font-size: 1.4rem;
    padding: 2px;
    text-align: center;
    white-space: -moz-pre-wrap !important;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    white-space: pre-wrap;
    /* css-3 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
    white-space: -webkit-pre-wrap;
    /* Newer versions of Chrome/Safari*/
    word-break: break-all;
    white-space: normal;
}

.ag-gcn-measurer-billing .ag-pinned-header {
    background: -webkit-linear-gradient(#fff, #d3d3d3);
    background: -moz-linear-gradient(#fff, #d3d3d3);
    background: -o-linear-gradient(#fff, #d3d3d3);
    background: -ms-linear-gradient(#fff, #d3d3d3);
    background: linear-gradient(#fff, #d3d3d3);
}

.ag-gcn-measurer-billing .ag-header-container {
    background: -webkit-linear-gradient(#2f3e4c, #2f3e4c);
    background: -moz-linear-gradient(#2f3e4c, #2f3e4c);
    background: -o-linear-gradient(#2f3e4c, #2f3e4c);
    background: -ms-linear-gradient(#2f3e4c, #2f3e4c);
    background: linear-gradient(#2f3e4c, #2f3e4c);
    font-size: 1.4rem;
    font-weight: 300;
    color: #ffffff;
    line-height: 1.125em;
}

.ag-gcn-measurer-billing .ag-header-cell {
    overflow: visible;
    white-space: normal;
    text-overflow: clip;
    border-bottom: none;
}

.ag-gcn-measurer-billing .ag-header-cell-label {
    padding: 4px 2px 4px 2px;
}

.ag-gcn-measurer-billing .ag-header-cell-text {
    padding-left: 2px;
}

.ag-gcn-measurer-billing .ag-header-group-cell-label {
    padding: 4px;
    font-weight: bold;
}

.ag-gcn-measurer-billing .ag-header-group-text {
    margin-right: 2px;
}

.ag-gcn-measurer-billing .ag-header-cell-menu-button {
    padding: 2px;
    margin-top: 4px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    /* When using bootstrap, box-sizing was set to 'border-box' */
}

.ag-gcn-measurer-billing .ag-header-icon {
    color: #800000;
}

.ag-gcn-measurer-billing .ag-row:hover {
    background: #32cc8d;
}

.ag-gcn-measurer-billing .ag-row-selected {
    background-color: black !important;
    color: #ffffff !important;
}

.ag-gcn-measurer-billing .ag-body .ag-row-odd {
    background-color: #2f3e4c;
}

.ag-gcn-measurer-billing .ag-body .ag-row-even {
    background-color: #2b3946;
}

.ag-gcn-measurer-billing .ag-floating-top .ag-row {
    background-color: #f0f0f0;
}

.ag-gcn-measurer-billing .ag-floating-bottom .ag-row {
    background-color: #f0f0f0;
}

.ag-gcn-measurer-billing .ag-overlay-loading-wrapper {
    background-color: rgba(255, 255, 255, 0.5);
}

.ag-gcn-measurer-billing .ag-overlay-loading-center {
    background-color: #fff;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    padding: 10px;
}

.ag-gcn-measurer-billing .ag-overlay-no-rows-center {
    background-color: #fff;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    padding: 10px;
}

.ag-gcn-measurer-billing .ag-body {
    background-color: #2f3e4c;
}

.ag-gcn-measurer-billing .ag-group-cell-entire-row {
    background-color: #aaa;
    padding: 4px;
}

.ag-gcn-measurer-billing .ag-footer-cell-entire-row {
    background-color: #aaa;
    padding: 4px;
}

.ag-gcn-measurer-billing .ag-group-cell {
    font-style: italic;
}

.ag-gcn-measurer-billing .ag-group-expand {
    padding-right: 2px;
}

.ag-gcn-measurer-billing .ag-footer-cell {
    font-style: italic;
}

.ag-gcn-measurer-billing .ag-filter-checkbox {
    position: relative;
    top: 2px;
    left: 2px;
}

.ag-gcn-measurer-billing .ag-filter-apply-panel {
    padding: 2px;
}

.ag-gcn-measurer-billing .ag-filter {
    background-color: #f0f0f0;
}

.ag-gcn-measurer-billing .ag-filter-value {
    margin-left: 4px;
}

.ag-gcn-measurer-billing .ag-selection-checkbox {
    margin-left: 4px;
}

.ag-gcn-measurer-billing .ag-paging-panel {
    padding: 4px;
}

.ag-gcn-measurer-billing .ag-paging-button {
    margin-left: 4px;
    margin-right: 4px;
}

.ag-gcn-measurer-billing .ag-paging-row-summary-panel {
    display: inline-block;
    width: 300px;
}

.ag-gcn-measurer-billing .ag-column-not-visible {
    opacity: 0.75;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
    filter: alpha(opacity=75);
}

.ag-gcn-measurer-billing .ag-list-item-selected {
    color: #f0f0f0;
}

.ag-gcn-measurer-billing .ag-list-item-not-selected {
    font-style: italic;
    color: #a0a0a0;
}

.ag-gcn-measurer-billing .ag-tool-panel-container {
    background-color: #eee;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 4px;
}

.ag-gcn-measurer-billing .ag-list-selection {
    background-color: #fff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.ag-gcn-measurer-billing .ag-popup-list .ag-list-item:hover {
    background-color: #add8e6;
}

.ag-gcn-measurer-billing .ag-visible-icons {
    padding-left: 2px;
    padding-right: 2px;
}

.ag-gcn-measurer-billing input[type="text"] {
    font-size: 1rem;
}

#portfolio-container .main-nav {
    height: 605px;
    padding-left: 0;
}
#portfolio-container .main-nav .heading {
    margin-right: 0;
    padding-top: 15px;
    height: 55px;
    font-size: 1.2em;
    font-weight: 100;
    color: #a4c8c8;
    padding-left: 20px;
    position: relative;
}
#portfolio-container .main-nav .heading.active,
#portfolio-container .main-nav .heading:hover {
    color: white;
    background-color: black;
    border: 1px solid black;
    border-radius: 0;
}
#portfolio-container .main-nav .heading.active:after {
    border-style: solid;
    border-width: 6px 0 6px 10px;
    border-color: transparent transparent transparent #000000;
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    right: -5%;
    top: 35%;
    clear: both;
}
#portfolio-container .main-nav .heading.disabled {
    margin-right: 0;
    padding-top: 15px;
    background-color: #2f3e4c;
    font-size: 14px;
    font-style: italic;
    height: 55px;
}
#portfolio-container .content-view {
    border: none;
    background-color: #acb8ba;
}

.tab-content {
    height: 100%;
    background-color: #f7f7f7;
}

.nav-tabs .tab-label {
    font-size: 1.4rem;
}

.nav {
    margin-left: 0;
    margin-bottom: 20px;
    list-style: none;
}

.nav > li > a {
    display: block;
}

.nav > li > a:hover,
.nav > li > a:focus {
    text-decoration: none;
    background-color: #eeeeee;
}

.nav > li > a > img {
    max-width: none;
}

.nav > .pull-right {
    float: right;
}

.nav-header {
    display: block;
    padding: 3px 15px;
    font-size: 11px;
    font-weight: bold;
    line-height: 20px;
    color: #999999;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
}

.nav li + .nav-header {
    margin-top: 9px;
}

.nav-list {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0;
}

.nav-list > li > a,
.nav-list .nav-header {
    margin-left: -15px;
    margin-right: -15px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

.nav-list > li > a {
    padding: 3px 15px;
}

.nav-list > .active > a,
.nav-list > .active > a:hover,
.nav-list > .active > a:focus {
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
    background-color: #0088cc;
}

.nav-list [class^="icon-"],
.nav-list [class*=" icon-"] {
    margin-right: 2px;
}

.nav-list .divider {
    *width: 100%;
    height: 1px;
    margin: 9px 1px;
    *margin: -5px 0 5px;
    overflow: hidden;
    background-color: #e5e5e5;
    border-bottom: 1px solid #ffffff;
}

.nav-tabs,
.nav-pills {
    *zoom: 1;
}

.nav-tabs:before,
.nav-pills:before,
.nav-tabs:after,
.nav-pills:after {
    display: table;
    content: "";
    line-height: 0;
}

.nav-tabs:after,
.nav-pills:after {
    clear: both;
}

.nav-tabs > li,
.nav-pills > li {
    float: left;
}

.nav-tabs > li > a,
.nav-pills > li > a {
    padding-right: 12px;
    padding-left: 12px;
    margin-right: 2px;
    line-height: 14px;
}

.nav-tabs {
    border-bottom: 1px solid #ddd;
}

.nav-tabs > li {
    margin-bottom: -1px;
}

.nav-tabs > li > a {
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 20px;
    border: 1px solid transparent;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
    border-color: #eeeeee #eeeeee #dddddd;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    color: #fff;
    background-color: #7490aa;
    border: none;
    border-bottom-color: transparent;
    cursor: default;
}

.nav-pills > li > a {
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 2px;
    margin-bottom: 2px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.nav-pills > .active > a,
.nav-pills > .active > a:hover,
.nav-pills > .active > a:focus {
    color: #ffffff;
    background-color: #0088cc;
}

.nav-stacked > li {
    float: none;
}

.nav-stacked > li > a {
    margin-right: 0;
}

.nav-tabs.nav-stacked {
    border-bottom: 0;
}

.nav-tabs.nav-stacked > li > a {
    border: 1px solid #ddd;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.nav-tabs.nav-stacked > li:first-child > a {
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topright: 4px;
    border-top-right-radius: 4px;
    -webkit-border-top-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    border-top-left-radius: 4px;
}

.nav-tabs.nav-stacked > li:last-child > a {
    -webkit-border-bottom-right-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    border-bottom-right-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-bottom-left-radius: 4px;
}

.nav-tabs.nav-stacked > li > a:hover,
.nav-tabs.nav-stacked > li > a:focus {
    border-color: #ddd;
    z-index: 2;
}

.nav-pills.nav-stacked > li > a {
    margin-bottom: 3px;
}

.nav-pills.nav-stacked > li:last-child > a {
    margin-bottom: 1px;
}

.nav-tabs .dropdown-menu {
    -webkit-border-radius: 0 0 6px 6px;
    -moz-border-radius: 0 0 6px 6px;
    border-radius: 0 0 6px 6px;
}

.nav-pills .dropdown-menu {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}

.nav .dropdown-toggle .caret {
    border-top-color: #0088cc;
    border-bottom-color: #0088cc;
    margin-top: 6px;
}

.nav .dropdown-toggle:hover .caret,
.nav .dropdown-toggle:focus .caret {
    border-top-color: #005580;
    border-bottom-color: #005580;
}

/* move down carets for tabs */
.nav-tabs .dropdown-toggle .caret {
    margin-top: 8px;
}

.nav .active .dropdown-toggle .caret {
    border-top-color: #fff;
    border-bottom-color: #fff;
}

.nav-tabs .active .dropdown-toggle .caret {
    border-top-color: #555555;
    border-bottom-color: #555555;
}

.nav > .dropdown.active > a:hover,
.nav > .dropdown.active > a:focus {
    cursor: pointer;
}

.nav-tabs .open .dropdown-toggle,
.nav-pills .open .dropdown-toggle,
.nav > li.dropdown.open.active > a:hover,
.nav > li.dropdown.open.active > a:focus {
    color: #ffffff;
    background-color: #999999;
    border-color: #999999;
}

.nav li.dropdown.open .caret,
.nav li.dropdown.open.active .caret,
.nav li.dropdown.open a:hover .caret,
.nav li.dropdown.open a:focus .caret {
    border-top-color: #ffffff;
    border-bottom-color: #ffffff;
    opacity: 1;
    filter: alpha(opacity=100);
}

.tabbable {
    *zoom: 1;
}

.tabbable:before,
.tabbable:after {
    display: table;
    content: "";
    line-height: 0;
}

.tabbable:after {
    clear: both;
}

.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
    float: none;
}

.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
    min-width: 74px;
    margin-right: 0;
    margin-bottom: 3px;
}

.tabs-left > .nav-tabs {
    float: left;
    margin-right: 19px;
    border-right: 1px solid #ddd;
}

.tabs-left > .nav-tabs > li > a {
    margin-right: -1px;
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
}

.tabs-left > .nav-tabs > li > a:hover,
.tabs-left > .nav-tabs > li > a:focus {
    border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}

.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus {
    border-color: #ddd transparent #ddd #ddd;
    *border-right-color: #ffffff;
}
/* 
#login_modal_container {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
}

@media only screen and (max-width: 400px) {
  #login_modal_container {
    width: auto;
    margin: 0;
    position: static;
    transform: none;
  }
}
#login_footer {
  color: #4d8187;
  font-size: 12px;
  margin-top: 7px;
}
#login_footer a {
  color: #4d8187;
  text-decoration: none;
  float: right;
}
#login_footer a:hover {
  text-decoration: underline;
}

#login_modal {
  background-color: #f4f8f8;
  border-radius: 10px;
  color: #4d8187;
  text-align: center;
}
#login_modal .loginForm {
  padding-bottom: 1%;
}
#login_modal .reset,
#login_modal .signin {
  padding: 0 20px;
}
#login_modal .reset {
  text-align: left;
}
#login_modal .reset p {
  text-align: justify;
}
#login_modal .reset-confirm {
  padding: 0 40px;
}
#login_modal .reset-confirm p {
  text-align: justify;
}
#login_modal .reset-confirm p a {
  color: #26ac82;
}
#login_modal img#gs_login_logo {
  margin: 0 0 35px 0;
  border-radius: 10px 10px 0 0;
  background-color: #37b9c3;
}
#login_modal img#gs_logo {
  height: 140px;
}
#login_modal .bottom-image {
  border-radius: 0 0 5px 5px;
  padding: 20px 0;
}
#login_modal .remember-container {
  font-size: 16px;
  text-align: left;
}
#login_modal .remember-container inputa[type='checkbox'] {
  background-color: #fff;
  border: 1px solid #4d8187;
  border-radius: 3px;
  margin: 0 10px 0 0;
  padding: 7px;
  vertical-align: middle;
}
#login_modal .remember-container a {
  float: right;
}
#login_modal input[type='submit'],
#login_modal .green-button {
  background-color: #fff;
  border-radius: 10px;
  color: #4d8187;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  margin: 30px 0;
  padding: 10px 30px;
  border: 1px solid #5fd9db;
  -webkit-appearance: none;
}
#login_modal input[type='email']::-webkit-input-placeholder,
#login_modal input[type='password']::-webkit-input-placeholder {
  color: #4d8187;
}
#login_modal input[type='email']:-moz-placeholder,
#login_modal input[type='password']:-moz-placeholder {
  color: #4d8187;
}
#login_modal input[type='email']::-moz-placeholder,
#login_modal input[type='password']::-moz-placeholder {
  color: #4d8187;
}
#login_modal input[type='email']:-ms-input-placeholder,
#login_modal input[type='password']:-ms-input-placeholder {
  color: #4d8187;
}
#login_modal input[type='password'],
#login_modal input[type='email'] {
  border: 1px solid #5fd9db;
  border-radius: 5px;
  box-sizing: border-box;
  color: #4d8187;
  display: block;
  font-size: 16px;
  margin: 10px 0;
  outline: none;
  padding: 10px;
  width: 100%;
  height: 2.7em;
}
#login_modal input[type='password']:focus,
#login_modal input[type='email']:focus {
  border: 1px solid #26ac82;
}
#login_modal a {
  color: #4d8187;
  font-size: 16px;
  text-decoration: none;
} */

p.error {
    color: red;
    font-size: 14px;
}

.analysis-wizard {
    border-radius: 4px 0 0 4px;
    margin-left: -0.4em;
    background-color: "orange";
    background-image: url("../img/icons/plus-on.png");
    cursor: default;
}
.analysis-wizard.on {
    background-image: url("../img/icons/plus-on.png");
    cursor: pointer;
}

.create-proposal {
    border-radius: 4px 0 0 4px;
    margin-left: -0.4em;
    background-color: inherit;
    background-image: url("../img/icons/rerun.png");
    cursor: pointer;
}
.create-proposal.on {
    background-image: url("../img/icons/rerun.png");
    cursor: pointer;
}

.horizontal-collapse {
    height: 70px;
}

.navbar-collapse.in {
    overflow-y: hidden;
}

.horizontal-view {
    transform: translate(20px, 0) rotate(90deg);
    transform-origin: 0 0;
}

.ghx-ide-menu,
.ghx-ide-menu > li {
    list-style: none;
    margin: 0;
    padding: 0;
}

.ghx-ide-menu > li {
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.66666666666667;
    text-transform: uppercase;
    cursor: pointer;
    display: inline-block;
    line-height: 20px;
    overflow: hidden;
    width: 20px;
}

.ghx-ide-menu > li:hover {
    background: #c2c2d6;
}

.ghx-ide-menu .ghx-inner {
    display: inline-block;
    -moz-transform: translate(20px, 0) rotate(90deg);
    -ms-transform: translate(20px, 0) rotate(90deg);
    -o-transform: translate(20px, 0) rotate(90deg);
    -webkit-transform: translate(20px, 0) rotate(90deg);
    transform: translate(20px, 0) rotate(90deg);
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    white-space: nowrap;
    margin: 10px 0;
}

.ghx-ide-menu .ghx-inner:after {
    content: "";
    float: left;
    margin-top: 100%;
}

#ghx-classification-menu-column,
#ghx-version-column,
#ghx-epic-column,
#ghx-backlog-column,
#ghx-detail-view,
#ghx-pool-column {
    display: table-cell;
    height: inherit;
    vertical-align: top;
}

.msie-9 #ghx-classification-menu-column,
.msie-9 #ghx-version-column,
.msie-9 #ghx-epic-column,
.msie-9 #ghx-backlog-column,
.msie-9 #ghx-detail-view,
.msie-9 #ghx-pool-column {
    height: 100%;
}

#ghx-classification-menu-column {
    width: 5%;
}

.ghx-version-expanded.ghx-epic-expanded #ghx-classification-menu-column,
.ghx-version-expanded.ghx-no-epic-panel #ghx-classification-menu-column {
    display: none;
}

.inline-block {
    display: inline-block;
}

.project-height {
    height: 90%;
}

.portfolio-selector .odd-row-color {
    background-color: #e6f3ff;
}
.portfolio-selector .even-row-color {
    background-color: #f0f8ff;
}
.portfolio-selector div.row-container > div:nth-of-type(odd) {
    background-color: #e6f3ff;
}
.portfolio-selector div.row-container > div:nth-of-type(even) {
    background-color: #f0f8ff;
}
.portfolio-selector table {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.portfolio-selector table * {
    box-sizing: inherit;
    -moz-box-sizing: inherit;
}
.portfolio-selector thead {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.portfolio-selector tbody {
    overflow-y: scroll;
    display: inline-block;
}
.portfolio-selector thead > tr,
.portfolio-selector tbody > tr,
.portfolio-selector tfoot > tr {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.portfolio-selector thead,
.portfolio-selector tfoot {
    flex-shrink: 0;
}
.portfolio-selector th,
.portfolio-selector tbody td {
    overflow-x: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}
.portfolio-selector tfoot {
    display: inline-block;
}
.portfolio-selector tfoot td {
    width: 100%;
    display: inline-block;
}

.context-menu-marker {
    border-style: none;
    border-width: 1px;
    position: absolute;
    visibility: hidden;
    height: 10px;
}

.options-marker {
    width: 120px;
    height: 15px;
    border: 1px solid #ccc;
    cursor: pointer;
    background: white;
}

.options-marker:hover {
    background: #ccc;
}

#overview-telstra-container {
    margin-top: 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
    background-color: #fff;
}
#overview-telstra-container .graph-height {
    height: 200px;
}
#overview-telstra-container .overview-title,
#overview-telstra-container .overview-content {
    color: #6c8e8d;
    font-size: 12px;
}
#overview-telstra-container .overview-content {
    font-size: 25px;
}
#overview-telstra-container .overview-content.sm {
    font-size: 15px;
}
#overview-telstra-container .subtoolbar {
    border-bottom: 1px solid #d4dedf;
    background-color: #f3f7f7;
    height: 50px;
}
#overview-telstra-container .subtoolbar h2 {
    color: #3cd3d5;
    margin: 0 0 0 30px;
    display: inline-block;
    font-weight: normal;
    padding: 0;
    line-height: 50px;
}
#overview-telstra-container .subtoolbar .title,
#overview-telstra-container
    .subtoolbar
    #vpp-ops-container
    .right-panel
    .scrollable-panel
    .title,
#vpp-ops-container
    .right-panel
    .scrollable-panel
    #overview-telstra-container
    .subtoolbar
    .title {
    color: #6c8e8d;
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
    font-weight: 700;
    line-height: 50px;
}
#overview-telstra-container .subtoolbar ul {
    float: right;
    list-style-type: none;
    margin: 0;
    padding: 0;
}
#overview-telstra-container .subtoolbar ul li {
    cursor: pointer;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
    height: 50px;
    width: 99px;
}
#overview-telstra-container .subtoolbar ul li:hover,
#overview-telstra-container .subtoolbar ul li.active {
    background-color: #3cd3d5;
}
#overview-telstra-container .subtoolbar ul li.map {
    background-image: url("../img/header/map-off.png");
}
#overview-telstra-container .subtoolbar ul li.map:hover,
#overview-telstra-container .subtoolbar ul li.map.active {
    background-image: url("../img/header/map-on.png");
}
#overview-telstra-container .subtoolbar ul li.bar,
#overview-telstra-container .subtoolbar ul #vpp-ops-container li.light-bar,
#vpp-ops-container #overview-telstra-container .subtoolbar ul li.light-bar {
    background-image: url("../img/header/bar-off.png");
}
#overview-telstra-container .subtoolbar ul li.bar:hover,
#overview-telstra-container
    .subtoolbar
    ul
    #vpp-ops-container
    li.light-bar:hover,
#vpp-ops-container
    #overview-telstra-container
    .subtoolbar
    ul
    li.light-bar:hover,
#overview-telstra-container .subtoolbar ul li.bar.active,
#overview-telstra-container
    .subtoolbar
    ul
    #vpp-ops-container
    li.active.light-bar,
#vpp-ops-container
    #overview-telstra-container
    .subtoolbar
    ul
    li.active.light-bar {
    background-image: url("../img/header/bar-on.png");
}
#overview-telstra-container .subtoolbar ul li.graph {
    background-image: url("../img/header/graph-off.png");
}
#overview-telstra-container .subtoolbar ul li.graph:hover,
#overview-telstra-container .subtoolbar ul li.graph.active {
    background-image: url("../img/header/graph-on.png");
}
#overview-telstra-container .subtoolbar ul li.pie {
    background-image: url("../img/header/pie-off.png");
}
#overview-telstra-container .subtoolbar ul li.pie:hover,
#overview-telstra-container .subtoolbar ul li.pie.active {
    background-image: url("../img/header/pie-on.png");
}
#overview-telstra-container .map-extender {
    width: 98%;
}
#overview-telstra-container .map-extender.expand {
    width: 99%;
}
#overview-telstra-container .marker-popup-window {
    border-radius: 4px;
    border: 1px solid white;
    color: white;
    background-color: #3d5260;
    font-size: 17px;
    text-align: center;
    white-space: nowrap;
    padding: 10px;
}
#overview-telstra-container .marker-popup-window .address-info h6 {
    font-family: inherit;
    font-size: 13px;
    font-weight: 200;
}
#overview-telstra-container .marker-popup-window .total-info h4 {
    font-family: inherit;
    font-size: 25px;
    font-weight: 300;
    color: #3cd3d5;
}
#overview-telstra-container .marker-popup-window .view-vitals {
    border-radius: 5px;
    background-color: #23aa80;
    color: #ffffff;
    font-size: 15px;
    padding: 7px 7px;
    width: 130px;
    margin-left: 15%;
}
#overview-telstra-container .box-panel {
    border-bottom: 1px solid #4d6573;
    background-color: #2f3e4c;
    border-radius: 5px;
}
#overview-telstra-container .map-right,
#overview-telstra-container #overview_container .map-right-small,
#overview_container #overview-telstra-container .map-right-small {
    background-color: #4d6573;
    border: 1px solid #4d6573;
}
#overview-telstra-container .quick-schedule-panel {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
}
#overview-telstra-container .panel-retract-left-arrow {
    background-color: #2f3e4c;
}
#overview-telstra-container .panel-left-arrow {
    background-color: #2f3e4c;
}
#overview-telstra-container .panel-left-arrow.off {
    padding-top: 100%;
}
#overview-telstra-container .stacked-column-container {
    height: 45%;
    padding-top: 1em;
}
#overview-telstra-container .stacked-column-container .highcharts-container {
    padding-left: 5px;
}
#overview-telstra-container
    .stacked-column-container
    .highcharts-container
    .highcharts-button {
    display: none;
}
#overview-telstra-container .stacked-column-container.hidden {
    visibility: hidden;
}
#overview-telstra-container .piechart-container {
    height: 30%;
}
#overview-telstra-container .piechart-container.hidden {
    visibility: hidden;
}
#overview-telstra-container .piechart-container .highcharts-button {
    display: none;
}
#overview-telstra-container .project-type-container {
    border: 1px solid black;
    border-radius: 5px;
}
#overview-telstra-container .project-type-container .perf-label {
    width: 50%;
    display: inline-block;
    font-size: 16px;
    border-right: 1px solid black;
    text-align: center;
}
#overview-telstra-container .project-type-container .perf-label.active {
    background-color: #3cd3d5;
}
#overview-telstra-container .project-type-container .health-label {
    width: 50%;
    display: inline-block;
    font-size: 16px;
    margin-left: -5px;
    text-align: center;
}
#overview-telstra-container .project-type-container .health-label.active {
    background-color: #3cd3d5;
}
#overview-telstra-container #map-container .angular-google-map,
#overview-telstra-container #map-container .angular-google-map-container {
    height: 530px;
}
#overview-telstra-container .map-right.ng-hide-add,
#overview-telstra-container #overview_container .ng-hide-add.map-right-small,
#overview_container #overview-telstra-container .ng-hide-add.map-right-small,
#overview-telstra-container .map-right.ng-hide-remove,
#overview-telstra-container #overview_container .ng-hide-remove.map-right-small,
#overview_container
    #overview-telstra-container
    .ng-hide-remove.map-right-small {
    display: block !important;
}
#overview-telstra-container .map-right.ng-hide-remove.ng-hide-remove-active,
#overview-telstra-container
    #overview_container
    .ng-hide-remove.ng-hide-remove-active.map-right-small,
#overview_container
    #overview-telstra-container
    .ng-hide-remove.ng-hide-remove-active.map-right-small {
    -webkit-animation: 0.5s slide-right;
    animation: 0.5s slide-right;
}
#overview-telstra-container .map-right.ng-hide,
#overview-telstra-container #overview_container .ng-hide.map-right-small,
#overview_container #overview-telstra-container .ng-hide.map-right-small {
    right: -23%;
    background-color: white;
}
#overview-telstra-container .overview-flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
}
#overview-telstra-container .overview-flex .item1 {
    width: 75%;
    -ms-flex-grow: 1;
    flex-grow: 1;
}
#overview-telstra-container .overview-flex .item2 {
    width: 25%;
    -ms-flex-grow: 1;
    flex-grow: 1;
}
#overview-telstra-container .summary-view {
    background-color: #d8dcdf;
    padding: 5%;
    border-bottom: 1px solid lightgrey;
    border-radius: 10px;
}
#overview-telstra-container .arrow-view {
    padding-top: 750%;
    padding-left: 30%;
    color: #6c8e8d;
}

.barchart-container {
    margin-top: 101px;
    border-radius: 5px;
}
.barchart-container .highcharts-container {
    border-radius: 5px;
}
.barchart-container .highcharts-container .highcharts-button {
    display: none;
}
.barchart-container .bar-title,
.barchart-container .bar-subtitle {
    text-align: center;
    color: black;
    background-color: white;
    font-weight: 400;
}
.barchart-container .bar-subtitle {
    padding-bottom: 5px;
}

#quick-schedule-container {
    color: #6c8e8d;
    background-color: #ccc;
}
#quick-schedule-container .header-title {
    color: white;
    font-size: 12px;
}
#quick-schedule-container .quick-label {
    display: inline-block;
    font-size: 1rem;
    color: white;
}
#quick-schedule-container input[type="time"] {
    width: 97%;
    font-size: 1.4rem;
    color: #080b0d;
}
#quick-schedule-container select {
    width: 50px;
    font-size: 1rem;
}

#value-generated-container {
    height: 100%;
}
#value-generated-container label {
    font-size: 1em;
    font-weight: 500;
}
#value-generated-container .value-flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
}
#value-generated-container .value-flex .value-panel {
    position: fixed;
    bottom: 3.8em;
    color: #6c8e8d;
    background-color: #f7f8fa;
    border-top: 1px solid #2fd2d1;
    left: 0;
    right: 0;
}
#value-generated-container .value-flex .item {
    width: 33%;
    color: #6c8e8d;
    background-color: #f7f8fa;
    border-top: 1px solid #2fd2d1;
    border-right: 1px solid #d4dedf;
    -ms-flex-grow: 1;
    flex-grow: 1;
}

#vpp-summary-container .box {
    background-color: #8b979f;
    height: 75px;
    border-radius: 4px;
    padding: 3%;
}
#vpp-summary-container .title,
#vpp-summary-container #vpp-ops-container .right-panel .scrollable-panel .title,
#vpp-ops-container
    .right-panel
    .scrollable-panel
    #vpp-summary-container
    .title {
    font-size: 1rem;
    font-weight: 400;
    color: #d8dcdf;
}
#vpp-summary-container .content {
    font-size: 1.4rem;
    font-weight: 700;
    color: white;
    padding: 5%;
}

/* ----------- full screen mode ------- */
/* ----------- iPad 3 and 4 ----------- */
/* Portrait and Landscape */
/* Portrait */
/* Landscape */
/* ----------- laptops ----------- */
@media only screen and (min-device-width: 1280px) and (max-device-height: 800px) {
    #value-generated-container .barchart-container {
        margin-top: 101px;
    }
    #value-generated-container .all-sites .sort input[type="text"],
    #value-generated-container
        #footer_container
        .all-sites-small
        .sort
        input[type="text"],
    #footer_container
        #value-generated-container
        .all-sites-small
        .sort
        input[type="text"],
    #value-generated-container
        #analysis_footer_container
        .all-sites-small
        .sort
        input[type="text"],
    #analysis_footer_container
        #value-generated-container
        .all-sites-small
        .sort
        input[type="text"],
    #value-generated-container
        #vpp_footer_container
        .all-sites-small
        .sort
        input[type="text"],
    #vpp_footer_container
        #value-generated-container
        .all-sites-small
        .sort
        input[type="text"],
    #value-generated-container .all-sites .sort select,
    #value-generated-container #footer_container .all-sites-small .sort select,
    #footer_container #value-generated-container .all-sites-small .sort select,
    #value-generated-container
        #analysis_footer_container
        .all-sites-small
        .sort
        select,
    #analysis_footer_container
        #value-generated-container
        .all-sites-small
        .sort
        select,
    #value-generated-container
        #vpp_footer_container
        .all-sites-small
        .sort
        select,
    #vpp_footer_container
        #value-generated-container
        .all-sites-small
        .sort
        select {
        min-width: 200px;
    }
}
@media only screen and (min-device-width: 1280px) and (max-device-height: 720px) {
    #value-generated-container .barchart-container {
        margin-top: 101px;
    }
    #value-generated-container .all-sites .sort input[type="text"],
    #value-generated-container
        #footer_container
        .all-sites-small
        .sort
        input[type="text"],
    #footer_container
        #value-generated-container
        .all-sites-small
        .sort
        input[type="text"],
    #value-generated-container
        #analysis_footer_container
        .all-sites-small
        .sort
        input[type="text"],
    #analysis_footer_container
        #value-generated-container
        .all-sites-small
        .sort
        input[type="text"],
    #value-generated-container
        #vpp_footer_container
        .all-sites-small
        .sort
        input[type="text"],
    #vpp_footer_container
        #value-generated-container
        .all-sites-small
        .sort
        input[type="text"],
    #value-generated-container .all-sites .sort select,
    #value-generated-container #footer_container .all-sites-small .sort select,
    #footer_container #value-generated-container .all-sites-small .sort select,
    #value-generated-container
        #analysis_footer_container
        .all-sites-small
        .sort
        select,
    #analysis_footer_container
        #value-generated-container
        .all-sites-small
        .sort
        select,
    #value-generated-container
        #vpp_footer_container
        .all-sites-small
        .sort
        select,
    #vpp_footer_container
        #value-generated-container
        .all-sites-small
        .sort
        select {
        min-width: 200px;
    }
}
/* ----------- iPad 1 & 2 ----------- */
@media only screen and (min-device-width: 1024px) and (max-device-height: 768px) {
    #value-generated-container .barchart-container {
        margin-top: 101px;
    }
    #value-generated-container .all-sites .sort input[type="text"],
    #value-generated-container
        #footer_container
        .all-sites-small
        .sort
        input[type="text"],
    #footer_container
        #value-generated-container
        .all-sites-small
        .sort
        input[type="text"],
    #value-generated-container
        #analysis_footer_container
        .all-sites-small
        .sort
        input[type="text"],
    #analysis_footer_container
        #value-generated-container
        .all-sites-small
        .sort
        input[type="text"],
    #value-generated-container
        #vpp_footer_container
        .all-sites-small
        .sort
        input[type="text"],
    #vpp_footer_container
        #value-generated-container
        .all-sites-small
        .sort
        input[type="text"],
    #value-generated-container .all-sites .sort select,
    #value-generated-container #footer_container .all-sites-small .sort select,
    #footer_container #value-generated-container .all-sites-small .sort select,
    #value-generated-container
        #analysis_footer_container
        .all-sites-small
        .sort
        select,
    #analysis_footer_container
        #value-generated-container
        .all-sites-small
        .sort
        select,
    #value-generated-container
        #vpp_footer_container
        .all-sites-small
        .sort
        select,
    #vpp_footer_container
        #value-generated-container
        .all-sites-small
        .sort
        select {
        min-width: 200px;
    }
}
/* ----------- tablet landscape mode size 1024x768 ----------- */
@media only screen and (min-width: 40.063em) and (max-width: 64em) {
    #vpp-summary-container .title,
    #vpp-summary-container
        #vpp-ops-container
        .right-panel
        .scrollable-panel
        .title,
    #vpp-ops-container
        .right-panel
        .scrollable-panel
        #vpp-summary-container
        .title {
        font-size: 0.8rem;
    }
    #vpp-summary-container .content {
        font-size: 1.2rem;
    }

    #quick-schedule-container .quick-label {
        font-size: 0.9rem;
    }
}
/* ----------- standard monitor size 1920x1080 ----------- */
@media only screen and (min-device-width: 1920px) and (min-device-height: 1080px) {
    #overview-telstra-container #map-container .angular-google-map,
    #overview-telstra-container #map-container .angular-google-map-container {
        height: 780px;
    }
}
/* ----------- standard monitor size 1366x768 ----------- */
@media only screen and (max-width: 1366px) {
    #overview-telstra-container #map-container .angular-google-map,
    #overview-telstra-container #map-container .angular-google-map-container {
        height: 470px;
    }

    #value-generated-container {
        height: 100%;
    }
}
/* ----------- 1280x1024 (larger) ----------- */
/* ----------- retina ----------- */
/* This CSS here is bad... */
#billing_container .total-entries {
    color: #fff;
    padding-top: 5px;
}
#billing_container .invoice-graph .highcharts-button {
    display: none;
}
#billing_container #pwrDemandReportInvoice .highcharts-button,
#billing_container #onPeakInvoice .highcharts-button,
#billing_container #partialPeakInvoice .highcharts-button,
#billing_container #offPeakInvoice .highcharts-button,
#billing_container #noOnPeakInvoice .highcharts-button,
#billing_container #noPartialPeakInvoice .highcharts-button,
#billing_container #noOffPeakInvoice .highcharts-button {
    display: none;
}
#billing_container .billing-table-container {
    height: 90vh;
    background-color: #4d6573 !important;
    overflow-y: scroll;
    transition: height 500ms ease;
    -ms-transition: height 500ms ease;
    -moz-transition: height 500ms ease;
    font-family: "Open Sans", sans-serif;
    border: none;
}
#billing_container .billing-table-container .header-fixed {
    position: fixed;
    margin: auto;
    z-index: 10;
    width: 100%;
    height: 50px;
}
#billing_container .billing-table-container .billing-row {
    margin-top: 50px;
}
#billing_container .billing-table-container .__sort-true {
    background: no-repeat right center url("../img/icons/sort_desc.gif");
    background-color: #3cd3d5;
}
#billing_container .billing-table-container .__sort-false {
    background: no-repeat right center url("../img/icons/sort_asc.gif");
    background-color: #3cd3d5;
}
#billing_container .billing-table-container .header,
#billing_container .billing-table-container .header-small {
    font-size: 11px;
    border: 1px solid #ccc;
    padding: 0;
    padding-top: 1.1%;
    text-align: center;
}
#billing_container .billing-table-container .period,
#billing_container .billing-table-container .info,
#billing_container .billing-table-container .info-medium,
#billing_container .billing-table-container .on-peak,
#billing_container .billing-table-container .on-peak-medium,
#billing_container .billing-table-container .partial-peak-medium,
#billing_container .billing-table-container .off-peak-medium,
#billing_container .billing-table-container .on-peak-small,
#billing_container .billing-table-container .partial-peak-small,
#billing_container .billing-table-container .off-peak-small,
#billing_container .billing-table-container .partial-peak,
#billing_container .billing-table-container .off-peak,
#billing_container .billing-table-container .monthly-savings,
#billing_container .billing-table-container .monthly-savings-medium,
#billing_container .billing-table-container .actions {
    font-size: 15px;
    font-weight: 400;
    height: 60px;
    border-top: 1px solid #acc5c5;
    border-left: 1px solid #acc5c5;
    border-bottom: 1px solid #acc5c5;
    text-align: center;
    padding-top: 1%;
    padding-bottom: 2%;
    background-color: #eaf0f0;
}
#billing_container .billing-table-container .actions {
    border-right: 1px solid #acc5c5;
}
#billing_container .billing-table-container .billing-container-list {
    border-radius: 10px;
    background-color: #ffffff;
    border-spacing: 0;
    color: #3f7176;
    position: relative;
    width: 100%;
    border: none;
}
#billing_container
    .billing-table-container
    .billing-container-list
    .billing-header {
    padding: 0 30px;
}
#billing_container
    .billing-table-container
    .billing-container-list
    .billing-header
    td {
    color: #ffffff;
    background-color: #3cd3d5;
    font-size: 1em;
    font-weight: 500;
    border-bottom: 1px solid #d5e1e1;
    border-right: 1px solid #dae5e5;
    padding: 17px 32px 17px 12px;
    position: relative;
    white-space: nowrap;
    text-decoration: none;
}
#billing_container
    .billing-table-container
    .billing-container-list
    .billing-header
    td:last-child {
    border-right: none;
    text-align: center;
}
#billing_container .billing-table-container .billing-container-list-small {
    /* Force table to not be like tables anymore */
    /* Hide table headers (but not display: none;, for accessibility) */
    /* Zebra striping */
    /*
  Label the data
  */
}
#billing_container .billing-table-container .billing-container-list-small table,
#billing_container .billing-table-container .billing-container-list-small thead,
#billing_container .billing-table-container .billing-container-list-small tbody,
#billing_container .billing-table-container .billing-container-list-small th,
#billing_container .billing-table-container .billing-container-list-small td,
#billing_container .billing-table-container .billing-container-list-small tr {
    display: block;
}
#billing_container
    .billing-table-container
    .billing-container-list-small
    thead
    tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
}
#billing_container .billing-table-container .billing-container-list-small tr {
    border: 1px solid #ccc;
}
#billing_container .billing-table-container .billing-container-list-small td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    min-width: 500px;
    padding-left: 50%;
}
#billing_container
    .billing-table-container
    .billing-container-list-small
    td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    padding-right: 10px;
    white-space: nowrap;
}
#billing_container
    .billing-table-container
    .billing-container-list-small
    tr:nth-of-type(odd) {
    background: #eee;
}
#billing_container
    .billing-table-container
    .billing-container-list-small
    td:nth-of-type(1):before {
    content: "Billing Period";
}
#billing_container
    .billing-table-container
    .billing-container-list-small
    td:nth-of-type(2):before {
    content: "Site Name";
}
#billing_container
    .billing-table-container
    .billing-container-list-small
    td:nth-of-type(3):before {
    content: "On Peak";
}
#billing_container
    .billing-table-container
    .billing-container-list-small
    td:nth-of-type(4):before {
    content: "Partial Peak";
}
#billing_container
    .billing-table-container
    .billing-container-list-small
    td:nth-of-type(5):before {
    content: "Off Peak";
}
#billing_container
    .billing-table-container
    .billing-container-list-small
    td:nth-of-type(6):before {
    content: "Monthly Savings";
}
#billing_container
    .billing-table-container
    .billing-container-list-small
    td:nth-of-type(7):before {
    content: "Actions";
}
#billing_container .billing-table-container .billing-graph-container {
    color: #d5e2e2;
    text-align: center;
    background-color: #3e525f;
}
#billing_container
    .billing-table-container
    .billing-graph-container
    .power-demand-report {
    padding: 0;
}
#billing_container
    .billing-table-container
    .billing-graph-container
    .power-demand-report
    .highcharts-button {
    display: none;
}
#billing_container .billing-table-container .billing-graph-container__header {
    margin-bottom: 30px;
}
#billing_container
    .billing-table-container
    .billing-graph-container
    .peak-legend {
    position: relative;
    margin-bottom: 5px;
    width: 350px;
    left: 34%;
}
#billing_container
    .billing-table-container
    .billing-graph-container
    .close-selector {
    background-image: url("../img/icons/close-btn.png");
    background-color: #23aa80;
    background-repeat: no-repeat;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    width: 40px;
    height: 40px;
    float: right;
    margin: 10px 0 10px 0;
}
#billing_container .billing-table-container .inv-grh-container {
    height: 60px;
    background-color: #3e525f;
}
#billing_container .billing-table-container .inv-grh-container .time-selector {
    border: 1px solid grey;
}
#billing_container
    .billing-table-container
    .inv-grh-container
    .time-selector-label,
#billing_container
    .billing-table-container
    .inv-grh-container
    .savings-selector-label {
    display: inline-block;
    cursor: pointer;
    color: #908585;
    font-size: 13px;
    padding: 5px;
}
#billing_container
    .billing-table-container
    .inv-grh-container
    .time-selector-label.selected,
#billing_container
    .billing-table-container
    .inv-grh-container
    .selected.savings-selector-label {
    color: #ffffff !important;
}
#billing_container
    .billing-table-container
    .inv-grh-container
    .time-selector-small {
    margin-left: auto;
    margin-right: auto;
    padding: 0 0;
}
#billing_container
    .billing-table-container
    .inv-grh-container
    .savings-selector,
#billing_container
    .billing-table-container
    .inv-grh-container
    .savings-selector-small {
    border: 1px solid grey;
    text-align: center;
}
#billing_container
    .billing-table-container
    .inv-grh-container
    .savings-selector-small {
    padding: 0 0;
}
#billing_container .billing-table-container .actions-medium {
    padding: 0 0;
}
#billing_container .billing-table-container .info-medium {
    font-size: 15px;
}
#billing_container .billing-table-container .period {
    font-size: 12px;
    font-weight: 400;
}
#billing_container .billing-table-container .monthly-savings,
#billing_container .billing-table-container .monthly-savings-medium {
    font-size: 16px;
    font-weight: 400;
}
#billing_container .billing-table-container .monthly-savings-medium {
    font-size: 16px;
}
#billing_container .billing-table-container .status,
#billing_container
    .billing-table-container
    #carousel-container
    .site-info
    .status-all,
#carousel-container
    .site-info
    #billing_container
    .billing-table-container
    .status-all,
#carousel-container
    #billing_container
    .billing-table-container
    .site-info-medium
    .status-all,
#billing_container
    .billing-table-container
    #carousel-container
    .site-info-medium
    .status-all {
    padding: 0 24px;
}
#billing_container .billing-table-container .on-peak,
#billing_container .billing-table-container .on-peak-medium,
#billing_container .billing-table-container .partial-peak-medium,
#billing_container .billing-table-container .off-peak-medium,
#billing_container .billing-table-container .on-peak-small,
#billing_container .billing-table-container .partial-peak-small,
#billing_container .billing-table-container .off-peak-small,
#billing_container .billing-table-container .partial-peak,
#billing_container .billing-table-container .off-peak {
    font-size: 15px;
    font-weight: 400;
}
#billing_container .billing-table-container .on-peak-medium,
#billing_container .billing-table-container .partial-peak-medium,
#billing_container .billing-table-container .off-peak-medium {
    font-size: 15px;
}
#billing_container .billing-table-container .on-peak-small,
#billing_container .billing-table-container .partial-peak-small,
#billing_container .billing-table-container .off-peak-small {
    font-size: 15px;
}
#billing_container .billing-table-container .actions .graph,
#billing_container .billing-table-container .actions .download,
#billing_container .billing-table-container .actions .print {
    text-align: left;
    width: 30%;
}
#billing_container .billing-table-container .actions .graph:hover,
#billing_container .billing-table-container .actions .download:hover,
#billing_container .billing-table-container .actions .print:hover {
    background-color: #303e4b;
    border-radius: 4px;
}
#billing_container .billing-table-container .site-info,
#billing_container .billing-table-container .site-info-medium {
    line-height: 1.2em;
    font-size: 12px;
    font-weight: 400;
    margin-left: 5px;
    display: inline-block;
    vertical-align: top;
}
#billing_container .billing-table-container .site-info h1,
#billing_container .billing-table-container .site-info-medium h1 {
    font-size: 16px;
    margin-bottom: 0;
}
#billing_container .billing-table-container .site-info p,
#billing_container .billing-table-container .site-info-medium p {
    margin: 0;
    max-width: 230px;
}
#billing_container .billing-table-container .site-info-medium {
    font-size: 10px;
    line-height: 1.2em;
}
#billing_container .billing-table-container .site-info-medium p {
    font-size: 13px;
}
#billing_container .billing-table-container:nth-child(2n) {
    background-color: #eaf0f0;
}
#billing_container
    #invGrhCnt
    .inv-grh-container-small
    td.billing-graph-container {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    min-width: 500px;
}
#billing_container #invGrhCnt td:nth-of-type(1):before {
    content: "";
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    padding-right: 10px;
    white-space: nowrap;
}
#billing_container .billing-title {
    color: #ffffff;
}

/* ----------- 1920x1080 ----------- */
@media only screen and (min-device-width: 1920px) and (min-device-height: 1080px) {
    #billing_container .billing-table-container {
        height: 790px;
    }
    #billing_container .billing-table-container .header-fixed {
        width: 99.2%;
    }
}
/* ----------- standard monitor size 1366x768 ----------- */
@media only screen and (max-width: 1366px) {
    #billing_container .billing-table-container {
        height: 490px;
    }
    #billing_container .billing-table-container .header-fixed {
        width: 98.7%;
    }
}
/* ----------- standard monitor size 1280x800 ----------- */
@media only screen and (max-width: 1280px) {
    #billing_container .billing-table-container {
        height: 520px;
    }
    #billing_container .billing-table-container .header-fixed {
        width: 98.7%;
    }
}
/* ----------- iPad 1 & 2 ----------- */
@media only screen and (min-device-width: 1024px) and (max-device-height: 768px) {
    #billing_container tr.billing-site .period {
        font-size: 13px;
    }
    #billing_container tr.billing-site .site-info,
    #billing_container
        tr.billing-site
        .billing-table-container
        .site-info-medium,
    #billing_container
        .billing-table-container
        tr.billing-site
        .site-info-medium {
        font-size: 13px;
    }
    #billing_container tr.billing-site .on-peak,
    #billing_container tr.billing-site .billing-table-container .on-peak-medium,
    #billing_container .billing-table-container tr.billing-site .on-peak-medium,
    #billing_container
        tr.billing-site
        .billing-table-container
        .partial-peak-medium,
    #billing_container
        .billing-table-container
        tr.billing-site
        .partial-peak-medium,
    #billing_container
        tr.billing-site
        .billing-table-container
        .off-peak-medium,
    #billing_container
        .billing-table-container
        tr.billing-site
        .off-peak-medium,
    #billing_container tr.billing-site .billing-table-container .on-peak-small,
    #billing_container .billing-table-container tr.billing-site .on-peak-small,
    #billing_container
        tr.billing-site
        .billing-table-container
        .partial-peak-small,
    #billing_container
        .billing-table-container
        tr.billing-site
        .partial-peak-small,
    #billing_container tr.billing-site .billing-table-container .off-peak-small,
    #billing_container .billing-table-container tr.billing-site .off-peak-small,
    #billing_container tr.billing-site .partial-peak,
    #billing_container tr.billing-site .off-peak,
    #billing_container tr.billing-site .monthly-savings,
    #billing_container
        tr.billing-site
        .billing-table-container
        .monthly-savings-medium,
    #billing_container
        .billing-table-container
        tr.billing-site
        .monthly-savings-medium {
        font-size: 17px;
        font-weight: 400;
    }
}
/* ----------- TBD ----------- */
#analysis-container {
    margin-top: 35px;
}
#analysis-container .analysis-main .main-nav {
    height: 605px;
    padding-left: 0;
}
#analysis-container .analysis-main .main-nav label {
    color: #ffffff;
    font-size: 13px;
    font-weight: 100;
}
#analysis-container .analysis-main .edit {
    margin-left: 5px;
}
#analysis-container .analysis-main i {
    color: #ffffff;
}
#analysis-container .analysis-main label {
    color: #ffffff;
    font-size: 1em;
}
#analysis-container .analysis-main .sub-section label {
    color: #000000;
    font-weight: 700;
}
#analysis-container .analysis-main .heading {
    margin-right: 0;
    padding-top: 15px;
    height: 55px;
    background-color: #2f3e4c;
    font-size: 1.2em;
    font-weight: 100;
    color: #a4c8c8;
    padding-left: 20px;
    position: relative;
}
#analysis-container .analysis-main .heading.selected,
#analysis-container .analysis-main .heading:hover {
    color: white;
    background-color: black;
    border: 1px solid black;
    border-radius: 0;
}
#analysis-container .analysis-main .heading.selected:after {
    border-style: solid;
    border-width: 6px 0 6px 10px;
    border-color: transparent transparent transparent #000000;
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    right: -5%;
    top: 35%;
    clear: both;
}
#analysis-container .analysis-main .heading-sub-nav {
    color: white;
    font-size: 18px;
    background-color: #42586c;
}
#analysis-container .analysis-main .heading-sub-nav.lg {
    height: 150px;
}
#analysis-container .analysis-main .heading-sub-nav.md {
    height: 100px;
}
#analysis-container .analysis-main .heading-sub-nav.sm {
    height: 60px;
}
#analysis-container .analysis-main .heading-sub-nav .pad-left {
    padding-left: 20%;
}
#analysis-container .analysis-main .content-view {
    border: none;
    background-color: #acb8ba;
}
#analysis-container .analysis-main .selected {
    background-color: #6e8ba6;
    border-radius: 1px;
}
#analysis-container .analysis-main .analysis-row {
    width: 100%;
    max-width: 100%;
}
#analysis-container .analysis-main .export-excel {
    border-radius: 4px 0 0 4px;
    background-color: inherit;
    margin-right: 0px;
    background-image: url("../img/icons/Excel-off.png");
    background-repeat: no-repeat;
    background-position: center;
    cursor: default;
}
#analysis-container .analysis-main .export-excel.on {
    background-image: url("../img/icons/Excel-on.png");
    cursor: pointer;
}
#analysis-container .analysis-main .export-excel:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#analysis-container .analysis-main .graph-print {
    background-image: url("../img/icons/icon-print.svg");
    background-repeat: no-repeat;
    height: 55px;
}
#analysis-container .analysis-main .graph-print.active,
#analysis-container .analysis-main .graph-print:hover {
    background-image: url("../img/icons/icon-print-on.svg");
}
#analysis-container .analysis-main .graph-download {
    background-image: url("../img/icons/icon-export-download.svg");
    background-repeat: no-repeat;
    height: 55px;
}
#analysis-container .analysis-main .graph-download.active,
#analysis-container .analysis-main .graph-download:hover {
    background-image: url("../img/icons/icon-export-download-on.svg");
}

#analysis-container .analysis-main .dropdown .dropdown-toggle:empty::after {
    display: none;
}

#analysis-container .analysis-main .gcn-analysis-slider {
    z-index: 0;
}
#analysis-container .analysis-main .gcn-analysis-slider.rzslider .rz-bar {
    background: #ffe4d1;
    height: 2px;
}
#analysis-container .analysis-main .gcn-analysis-slider.rzslider .rz-selection {
    background: orange;
}
#analysis-container .analysis-main .gcn-analysis-slider.rzslider .rz-pointer {
    z-index: 0;
    color: #000000;
    width: 8px;
    height: 16px;
    top: auto;
    /* to remove the default positioning */
    bottom: 0;
    background-color: #fff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
#analysis-container
    .analysis-main
    .gcn-analysis-slider.rzslider
    .rz-pointer:after {
    display: none;
}
#analysis-container .analysis-main .gcn-analysis-slider.rzslider .rz-bubble {
    bottom: 14px;
    color: #000000;
}
#analysis-container .analysis-main .gcn-analysis-slider.rzslider .rz-limit {
    color: #000000;
}
#analysis-container .analysis-main .gcn-analysis-slider.rzslider .rz-tick {
    width: 1px;
    height: 10px;
    margin-left: 4px;
    border-radius: 0;
    background: #ffe4d1;
    top: -1px;
}
#analysis-container
    .analysis-main
    .gcn-analysis-slider.rzslider
    .rz-tick.rz-selected {
    background: orange;
}
#analysis-container .analysis-main .demand-shaving {
    background-image: url("../img/icons/nav-icon-peak-mgmt.svg");
    background-repeat: no-repeat;
    height: 92px;
}
#analysis-container .analysis-main .demand-shaving.active,
#analysis-container .analysis-main .demand-shaving:hover {
    background-image: url("../img/icons/nav-icon-peak-mgmt-on.svg");
}
#analysis-container .analysis-main .ag-grid-evergysaving,
#analysis-container .analysis-main .ag-grid-derssummary {
    height: 430px;
}
@media only screen and (min-device-width: 1920px) and (min-device-height: 1080px) {
    #analysis-container .analysis-main .ag-grid-evergysaving,
    #analysis-container .analysis-main .ag-grid-derssummary {
        height: 630px;
    }
}
#analysis-container .analysis-main .energy-saving {
    background-image: url("../img/icons/nav-icon-energy-arbitrage.svg");
    background-repeat: no-repeat;
    height: 92px;
}
#analysis-container .analysis-main .energy-saving.active,
#analysis-container .analysis-main .energy-saving:hover {
    background-image: url("../img/icons/nav-icon-energy-arbitrage-on.svg");
}
#analysis-container .analysis-main .solution-summary,
#analysis-container .analysis-main .financial-summary {
    background-color: #2f3e4c;
    padding-top: 20px;
    padding-bottom: 20px;
}
#analysis-container .analysis-main .solution-summary-light-background,
#analysis-container .analysis-main .financial-summary-light-background {
    background-color: #4d6573;
}
#analysis-container .analysis-main .solution-summary-inline-block {
    display: inline-block;
}
#analysis-container .analysis-main .solution-summary-header,
#analysis-container .analysis-main .financial-summary-header {
    color: #ffffff;
    font-size: 2em;
}
#analysis-container .analysis-main .solution-summary-overview-top,
#analysis-container .analysis-main .financial-summary-overview-top {
    background-color: #2f3e4c;
    margin-top: 1%;
    padding: 0px;
}
#analysis-container .analysis-main .finanical-solar-only {
    height: 263px;
}
#analysis-container .analysis-main .finanical-storage-addon {
    height: 475px;
}
#analysis-container .analysis-main .export-icon-fas {
    font-size: 16px;
    cursor: pointer;
    color: #ffffff;
}

#analysis-container .analysis-main .item-disable {
    color: #4a5661;
}

@media only screen and (min-device-width: 1920px) and (min-device-height: 1080px) {
    #analysis-container .analysis-main .finanical-solar-only {
        height: 388px;
    }
    #analysis-container .analysis-main .finanical-storage-addon {
        height: 601px;
    }
}

#analysis-container
    .analysis-main
    .solution-summary-overview-top
    input[type="text"] {
    height: 1.3rem;
}

/* #analysis-container .analysis-main .solution-summary-overview-top select {
    height: 2.3rem;
} */

#analysis_footer_container {
    margin-top: 1px;
    position: fixed;
    height: 3.1em;
    bottom: 0;
    left: 0;
    right: 0;
}
#analysis_footer_container .detail-grid-expand {
    height: 580px;
}
#analysis_footer_container .detail-grid-retract {
    height: 300px;
}
#analysis_footer_container .footer-flex {
    width: 100%;
    background-color: #3d5260;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3.1em;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    overflow: hidden;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
}
#analysis_footer_container .footer-flex .item {
    padding-top: 0.5%;
    -ms-flex-grow: 1;
    flex-grow: 1;
    color: #95b6b6;
    font-size: 10px;
}
#analysis_footer_container .footer-flex .left,
#analysis_footer_container .footer-flex .vitals-main .arrow.left-expanded-view,
.vitals-main #analysis_footer_container .footer-flex .arrow.left-expanded-view,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .arrow.left-expanded-view,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .left-expanded-view.arrow-expanded,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .left-expanded-view.arrow-expanded,
#analysis_footer_container
    .footer-flex
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view,
#vpp-ops-overview-graph-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view,
#analysis_footer_container
    .footer-flex
    #vitals-health-container
    .arrow.left-expanded-view,
#vitals-health-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view {
    -webkit-box-flex: 1;
    /* OLD - iOS 6-, Safari 3.1-6 */
    -moz-box-flex: 1;
    /* OLD - Firefox 19- */
    -webkit-flex: 1;
    /* Chrome */
    -ms-flex: 1;
    flex: 1;
}
#analysis_footer_container .footer-flex .left .left-panel,
#analysis_footer_container
    .footer-flex
    .vitals-main
    .arrow.left-expanded-view
    .left-panel,
.vitals-main
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .arrow.left-expanded-view
    .left-panel,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .left-expanded-view.arrow-expanded
    .left-panel,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .left-expanded-view.arrow-expanded
    .left-panel,
#analysis_footer_container
    .footer-flex
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .left-panel,
#vpp-ops-overview-graph-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel,
#analysis_footer_container
    .footer-flex
    #vitals-health-container
    .arrow.left-expanded-view
    .left-panel,
#vitals-health-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    padding-top: 0.9%;
}
#analysis_footer_container .footer-flex .left .left-panel .sort-by,
#analysis_footer_container
    .footer-flex
    .vitals-main
    .arrow.left-expanded-view
    .left-panel
    .sort-by,
.vitals-main
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .left-expanded-view.arrow-expanded
    .left-panel
    .sort-by,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .left-expanded-view.arrow-expanded
    .left-panel
    .sort-by,
#analysis_footer_container
    .footer-flex
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by,
#vpp-ops-overview-graph-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by,
#analysis_footer_container
    .footer-flex
    #vitals-health-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by,
#vitals-health-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by {
    padding-left: 1%;
    width: 80%;
}
#analysis_footer_container .footer-flex .left .left-panel .sort-by .one,
#analysis_footer_container
    .footer-flex
    .vitals-main
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .one,
.vitals-main
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .one,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .one,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .one,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .left-expanded-view.arrow-expanded
    .left-panel
    .sort-by
    .one,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .left-expanded-view.arrow-expanded
    .left-panel
    .sort-by
    .one,
#analysis_footer_container
    .footer-flex
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .one,
#vpp-ops-overview-graph-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .one,
#analysis_footer_container
    .footer-flex
    #vitals-health-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .one,
#vitals-health-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .one {
    width: 12%;
}
#analysis_footer_container .footer-flex .left .left-panel .sort-by .two,
#analysis_footer_container
    .footer-flex
    .vitals-main
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .two,
.vitals-main
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .two,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .two,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .two,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .left-expanded-view.arrow-expanded
    .left-panel
    .sort-by
    .two,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .left-expanded-view.arrow-expanded
    .left-panel
    .sort-by
    .two,
#analysis_footer_container
    .footer-flex
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .two,
#vpp-ops-overview-graph-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .two,
#analysis_footer_container
    .footer-flex
    #vitals-health-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .two,
#vitals-health-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .two {
    width: 40%;
}
#analysis_footer_container .footer-flex .left .left-panel .sort-by .three,
#analysis_footer_container
    .footer-flex
    .vitals-main
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .three,
.vitals-main
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .three,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .three,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .three,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .left-expanded-view.arrow-expanded
    .left-panel
    .sort-by
    .three,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .left-expanded-view.arrow-expanded
    .left-panel
    .sort-by
    .three,
#analysis_footer_container
    .footer-flex
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .three,
#vpp-ops-overview-graph-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .three,
#analysis_footer_container
    .footer-flex
    #vitals-health-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .three,
#vitals-health-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .three {
    width: 45%;
}
#analysis_footer_container .footer-flex .left .left-panel .sort-by img,
#analysis_footer_container
    .footer-flex
    .vitals-main
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    img,
.vitals-main
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    img,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    img,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    img,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .left-expanded-view.arrow-expanded
    .left-panel
    .sort-by
    img,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .left-expanded-view.arrow-expanded
    .left-panel
    .sort-by
    img,
#analysis_footer_container
    .footer-flex
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    img,
#vpp-ops-overview-graph-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    img,
#analysis_footer_container
    .footer-flex
    #vitals-health-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    img,
#vitals-health-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    img {
    height: 20px;
    width: 20px;
}
#analysis_footer_container .footer-flex .left .left-panel .filter-by,
#analysis_footer_container
    .footer-flex
    .vitals-main
    .arrow.left-expanded-view
    .left-panel
    .filter-by,
.vitals-main
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .arrow.left-expanded-view
    .left-panel
    .filter-by,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .left-expanded-view.arrow-expanded
    .left-panel
    .filter-by,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .left-expanded-view.arrow-expanded
    .left-panel
    .filter-by,
#analysis_footer_container
    .footer-flex
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .left-panel
    .filter-by,
#vpp-ops-overview-graph-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by,
#analysis_footer_container
    .footer-flex
    #vitals-health-container
    .arrow.left-expanded-view
    .left-panel
    .filter-by,
#vitals-health-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by {
    padding-left: 5%;
    width: 50%;
}
#analysis_footer_container .footer-flex .left .left-panel .filter-by .one,
#analysis_footer_container
    .footer-flex
    .vitals-main
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .one,
.vitals-main
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .one,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .one,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .one,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .left-expanded-view.arrow-expanded
    .left-panel
    .filter-by
    .one,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .left-expanded-view.arrow-expanded
    .left-panel
    .filter-by
    .one,
#analysis_footer_container
    .footer-flex
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .one,
#vpp-ops-overview-graph-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .one,
#analysis_footer_container
    .footer-flex
    #vitals-health-container
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .one,
#vitals-health-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .one {
    width: 25%;
}
#analysis_footer_container .footer-flex .left .left-panel .filter-by .two,
#analysis_footer_container
    .footer-flex
    .vitals-main
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .two,
.vitals-main
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .two,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .two,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .two,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .left-expanded-view.arrow-expanded
    .left-panel
    .filter-by
    .two,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .left-expanded-view.arrow-expanded
    .left-panel
    .filter-by
    .two,
#analysis_footer_container
    .footer-flex
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .two,
#vpp-ops-overview-graph-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .two,
#analysis_footer_container
    .footer-flex
    #vitals-health-container
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .two,
#vitals-health-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .two {
    width: 70%;
}
#analysis_footer_container .footer-flex .left .left-panel label,
#analysis_footer_container
    .footer-flex
    .vitals-main
    .arrow.left-expanded-view
    .left-panel
    label,
.vitals-main
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    label,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .arrow.left-expanded-view
    .left-panel
    label,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    label,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .left-expanded-view.arrow-expanded
    .left-panel
    label,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .left-expanded-view.arrow-expanded
    .left-panel
    label,
#analysis_footer_container
    .footer-flex
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .left-panel
    label,
#vpp-ops-overview-graph-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    label,
#analysis_footer_container
    .footer-flex
    #vitals-health-container
    .arrow.left-expanded-view
    .left-panel
    label,
#vitals-health-container
    #analysis_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    label {
    font-size: 11px;
    font-weight: 100;
}
#analysis_footer_container .footer-flex .right,
#analysis_footer_container .footer-flex .vitals-main .arrow.right-expanded-view,
.vitals-main #analysis_footer_container .footer-flex .arrow.right-expanded-view,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .arrow.right-expanded-view,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .arrow.right-expanded-view,
#analysis_footer_container
    .footer-flex
    #carousel-container
    .right-expanded-view.arrow-expanded,
#carousel-container
    #analysis_footer_container
    .footer-flex
    .right-expanded-view.arrow-expanded,
#analysis_footer_container
    .footer-flex
    #vpp-ops-overview-graph-container
    .arrow.right-expanded-view,
#vpp-ops-overview-graph-container
    #analysis_footer_container
    .footer-flex
    .arrow.right-expanded-view,
#analysis_footer_container
    .footer-flex
    #vitals-health-container
    .arrow.right-expanded-view,
#vitals-health-container
    #analysis_footer_container
    .footer-flex
    .arrow.right-expanded-view {
    -webkit-box-flex: 1;
    /* OLD - iOS 6-, Safari 3.1-6 */
    -moz-box-flex: 1;
    /* OLD - Firefox 19- */
    -webkit-flex: 1;
    /* Chrome */
    -ms-flex: 1;
    flex: 1;
}
#analysis_footer_container .footer-flex select {
    -webkit-appearance: none;
    border-radius: 4px;
    border: 1px solid #58788b;
    box-sizing: border-box;
    color: #fff;
    background-color: #2f3e4c;
    outline: none;
    font-size: 12px;
}
#analysis_footer_container .view-selector {
    padding-top: 0.4%;
    text-align: right;
}
#analysis_footer_container .view-selector .list,
#analysis_footer_container .view-selector .grid,
#analysis_footer_container .view-selector .upload,
#analysis_footer_container .view-selector .expander,
#analysis_footer_container .view-selector .detail,
#analysis_footer_container .view-selector .retract,
#analysis_footer_container .view-selector .export-demand,
#analysis_footer_container .view-selector .export-energy,
#analysis_footer_container .view-selector .export-excel,
#analysis_footer_container .view-selector .plus,
#analysis_footer_container .view-selector .minus,
#analysis_footer_container .view-selector .configuration,
#analysis_footer_container .view-selector .setpoint,
#analysis_footer_container .view-selector .rerun {
    background-color: #1f2a31;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
    box-sizing: border-box;
    display: inline-block;
    width: 36px;
    height: 33px;
    border: 1px solid #334550;
}
#analysis_footer_container .view-selector .list {
    background-image: url("../img/icons/list-off.png");
    margin-left: -3px;
}
#analysis_footer_container .view-selector .list.on {
    background-color: #4dc2c6;
    background-image: url("../img/icons/list-on.png");
}
#analysis_footer_container .view-selector .list:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#analysis_footer_container .view-selector .grid {
    background-image: url("../img/icons/grid-off.png");
    border-radius: 4px 0 0 4px;
}
#analysis_footer_container .view-selector .grid.on {
    background-color: #4dc2c6;
    background-image: url("../img/icons/grid-on.png");
}
#analysis_footer_container .view-selector .grid:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#analysis_footer_container .view-selector .upload {
    margin-right: 1em;
    margin-left: -0.4em;
    border-radius: 0 4px 4px 0;
    background-image: url("../img/icons/Camera-Grey.png");
    cursor: default;
}
#analysis_footer_container .view-selector .upload.on {
    background-image: url("../img/icons/Camera-White.png");
    cursor: pointer;
}
#analysis_footer_container .view-selector .upload:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#analysis_footer_container .view-selector .detail {
    border-radius: 0 4px 4px 0;
    margin-right: 1em;
    margin-left: -4px;
    background-image: url("../img/icons/Detailed-List-Off.png");
}
#analysis_footer_container .view-selector .detail.on {
    background-image: url("../img/icons/Detailed-List-On.png");
    background-color: #4dc2c6;
}
#analysis_footer_container .view-selector .detail:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#analysis_footer_container .view-selector .expander {
    border-radius: 4px 0 0 4px;
    margin-right: 2px;
    background-image: url("../img/icons/Open-Full-Screen-Grey.png");
}
#analysis_footer_container .view-selector .expander.on {
    background-image: url("../img/icons/Close-Full-Screen-Grey.png");
}
#analysis_footer_container .view-selector .expander:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#analysis_footer_container .view-selector .retract {
    border-radius: 0 4px 4px 0;
    margin-left: -0.4em;
    background-image: url("../img/icons/Show-Down.png");
}
#analysis_footer_container .view-selector .retract.on {
    background-image: url("../img/icons/Show-Up.png");
    cursor: pointer;
}
#analysis_footer_container .view-selector .retract:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#analysis_footer_container .view-selector .export-demand {
    border-radius: 4px 0 0 4px;
    background-color: inherit;
    margin-right: 0px;
    background-image: url("../img/icons/export-demand-off.png");
    cursor: default;
}
#analysis_footer_container .view-selector .export-demand.on {
    background-image: url("../img/icons/export-demand-on.png");
    cursor: pointer;
}
#analysis_footer_container .view-selector .export-demand:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#analysis_footer_container .view-selector .export-energy {
    border-radius: 4px 0 0 4px;
    background-color: inherit;
    margin-right: 0px;
    background-image: url("../img/icons/export-energy-off.png");
    cursor: default;
}
#analysis_footer_container .view-selector .export-energy.on {
    background-image: url("../img/icons/export-energy-on.png");
    cursor: pointer;
}
#analysis_footer_container .view-selector .export-energy:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#analysis_footer_container .view-selector .export-excel {
    border-radius: 4px 0 0 4px;
    background-color: inherit;
    margin-right: 0px;
    background-image: url("../img/icons/Excel-off.png");
    cursor: default;
}
#analysis_footer_container .view-selector .export-excel.on {
    background-image: url("../img/icons/Excel-on.png");
    cursor: pointer;
}
#analysis_footer_container .view-selector .export-excel:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#analysis_footer_container .view-selector .plus {
    border-radius: 4px 0 0 4px;
    margin-left: -0.4em;
    background-color: inherit;
    background-image: url("../img/icons/plus-off.png");
    cursor: default;
}
#analysis_footer_container .view-selector .plus.on {
    background-image: url("../img/icons/plus-on.png");
    cursor: pointer;
}
#analysis_footer_container .view-selector .minus {
    border-radius: 4px 0 0 4px;
    margin-left: -0.4em;
    background-color: inherit;
    background-image: url("../img/icons/minus-off.png");
    cursor: default;
}
#analysis_footer_container .view-selector .minus.on {
    background-image: url("../img/icons/minus-on.png");
    cursor: pointer;
}
#analysis_footer_container .view-selector .rerun {
    border-radius: 4px 0 0 4px;
    margin-left: -0.4em;
    background-color: inherit;
    background-image: url("../img/icons/rerun.png");
    cursor: pointer;
}
#analysis_footer_container .view-selector .rerun.on {
    background-image: url("../img/icons/rerun.png");
    cursor: pointer;
}
#analysis_footer_container .view-selector .configuration {
    border-radius: 4px 0 0 4px;
    margin-left: -0.4em;
    background-color: inherit;
    background-image: url("../img/icons/configuration-off.png");
    cursor: default;
}
#analysis_footer_container .view-selector .configuration.on {
    background-image: url("../img/icons/configuration-on.png");
    cursor: pointer;
}
#analysis_footer_container .view-selector .setpoint {
    width: 36px;
    margin-right: -10px;
    background-color: inherit;
    background-image: url("../img/icons/setpoint-off.png");
    cursor: default;
}
#analysis_footer_container .view-selector .setpoint.on {
    background-image: url("../img/icons/setpoint-on.png");
    cursor: pointer;
}
#analysis_footer_container .hide-arrow-selector {
    background-color: #23aa80;
    background-image: url("../img/dashboard/hide-arrows.png");
    background-repeat: no-repeat;
    background-position: 70px center;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    float: right;
    padding: 7px 51px 7px 10px;
    margin: 12px 12px 0 0;
}
#analysis_footer_container .hide-arrow-selector.show-bg {
    background-image: url("../img/dashboard/show-arrows.png");
}
#analysis_footer_container select {
    -webkit-appearance: none;
    border-radius: 4px;
    border: 1px solid #58788b;
    box-sizing: border-box;
    color: #fff;
    background-color: #2f3e4c;
    outline: none;
    height: 2.3em;
}
#analysis_footer_container .all-sites,
#analysis_footer_container .all-sites-small {
    background-color: #3d5260;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3.8em;
}
#analysis_footer_container .all-sites h2,
#analysis_footer_container .all-sites-small h2 {
    color: #31d7d9;
    font-weight: 100;
    margin-left: 40px;
}
#analysis_footer_container .all-sites .nav,
#analysis_footer_container .all-sites-small .nav {
    float: right;
    margin-right: 45px;
}
#analysis_footer_container .all-sites .nav .right,
#analysis_footer_container .all-sites-small .nav .right,
#analysis_footer_container
    .all-sites
    .nav
    .vitals-main
    .arrow.right-expanded-view,
.vitals-main
    #analysis_footer_container
    .all-sites
    .nav
    .arrow.right-expanded-view,
#analysis_footer_container
    .all-sites-small
    .nav
    .vitals-main
    .arrow.right-expanded-view,
.vitals-main
    #analysis_footer_container
    .all-sites-small
    .nav
    .arrow.right-expanded-view,
#analysis_footer_container
    .all-sites
    .nav
    #carousel-container
    .arrow.right-expanded-view,
#carousel-container
    #analysis_footer_container
    .all-sites
    .nav
    .arrow.right-expanded-view,
#analysis_footer_container
    .all-sites-small
    .nav
    #carousel-container
    .arrow.right-expanded-view,
#carousel-container
    #analysis_footer_container
    .all-sites-small
    .nav
    .arrow.right-expanded-view,
#analysis_footer_container
    .all-sites
    .nav
    #carousel-container
    .right-expanded-view.arrow-expanded,
#carousel-container
    #analysis_footer_container
    .all-sites
    .nav
    .right-expanded-view.arrow-expanded,
#analysis_footer_container
    .all-sites-small
    .nav
    #carousel-container
    .right-expanded-view.arrow-expanded,
#carousel-container
    #analysis_footer_container
    .all-sites-small
    .nav
    .right-expanded-view.arrow-expanded,
#analysis_footer_container
    .all-sites
    .nav
    #vpp-ops-overview-graph-container
    .arrow.right-expanded-view,
#vpp-ops-overview-graph-container
    #analysis_footer_container
    .all-sites
    .nav
    .arrow.right-expanded-view,
#analysis_footer_container
    .all-sites-small
    .nav
    #vpp-ops-overview-graph-container
    .arrow.right-expanded-view,
#vpp-ops-overview-graph-container
    #analysis_footer_container
    .all-sites-small
    .nav
    .arrow.right-expanded-view,
#analysis_footer_container
    .all-sites
    .nav
    #vitals-health-container
    .arrow.right-expanded-view,
#vitals-health-container
    #analysis_footer_container
    .all-sites
    .nav
    .arrow.right-expanded-view,
#analysis_footer_container
    .all-sites-small
    .nav
    #vitals-health-container
    .arrow.right-expanded-view,
#vitals-health-container
    #analysis_footer_container
    .all-sites-small
    .nav
    .arrow.right-expanded-view {
    margin-left: 48px;
}
#analysis_footer_container .all-sites .nav img,
#analysis_footer_container .all-sites-small .nav img {
    cursor: pointer;
}
#analysis_footer_container .all-sites .view-selector,
#analysis_footer_container .all-sites-small .view-selector {
    margin-top: 0.9em;
    text-align: right;
}
#analysis_footer_container .all-sites .view-selector .list,
#analysis_footer_container .all-sites-small .view-selector .list,
#analysis_footer_container .all-sites .view-selector .grid,
#analysis_footer_container .all-sites-small .view-selector .grid,
#analysis_footer_container .all-sites .view-selector .upload,
#analysis_footer_container .all-sites-small .view-selector .upload,
#analysis_footer_container .all-sites .view-selector .expander,
#analysis_footer_container .all-sites-small .view-selector .expander,
#analysis_footer_container .all-sites .view-selector .detail,
#analysis_footer_container .all-sites-small .view-selector .detail,
#analysis_footer_container .all-sites .view-selector .retract,
#analysis_footer_container .all-sites-small .view-selector .retract,
#analysis_footer_container .all-sites .view-selector .export-excel,
#analysis_footer_container .all-sites-small .view-selector .export-excel,
#analysis_footer_container .all-sites .view-selector .plus,
#analysis_footer_container .all-sites-small .view-selector .plus,
#analysis_footer_container .all-sites .view-selector .minus,
#analysis_footer_container .all-sites-small .view-selector .minus,
#analysis_footer_container .all-sites .view-selector .configuration,
#analysis_footer_container .all-sites-small .view-selector .configuration,
#analysis_footer_container .all-sites .view-selector .setpoint,
#analysis_footer_container .all-sites-small .view-selector .setpoint,
#analysis_footer_container .all-sites .view-selector .rerun,
#analysis_footer_container .all-sites-small .view-selector .rerun {
    background-color: #1f2a31;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
    box-sizing: border-box;
    display: inline-block;
    width: 36px;
    height: 33px;
    border: 1px solid #334550;
}
#analysis_footer_container .all-sites .view-selector .list,
#analysis_footer_container .all-sites-small .view-selector .list {
    background-image: url("../img/icons/list-off.png");
    margin-left: -3px;
}
#analysis_footer_container .all-sites .view-selector .list.on,
#analysis_footer_container .all-sites-small .view-selector .list.on {
    background-color: #4dc2c6;
    background-image: url("../img/icons/list-on.png");
}
#analysis_footer_container .all-sites .view-selector .list:hover,
#analysis_footer_container .all-sites-small .view-selector .list:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#analysis_footer_container .all-sites .view-selector .grid,
#analysis_footer_container .all-sites-small .view-selector .grid {
    background-image: url("../img/icons/grid-off.png");
    border-radius: 4px 0 0 4px;
}
#analysis_footer_container .all-sites .view-selector .grid.on,
#analysis_footer_container .all-sites-small .view-selector .grid.on {
    background-color: #4dc2c6;
    background-image: url("../img/icons/grid-on.png");
}
#analysis_footer_container .all-sites .view-selector .grid:hover,
#analysis_footer_container .all-sites-small .view-selector .grid:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#analysis_footer_container .all-sites .view-selector .upload,
#analysis_footer_container .all-sites-small .view-selector .upload {
    margin-right: 1em;
    margin-left: -0.4em;
    border-radius: 0 4px 4px 0;
    background-image: url("../img/icons/Camera-Grey.png");
    cursor: default;
}
#analysis_footer_container .all-sites .view-selector .upload.on,
#analysis_footer_container .all-sites-small .view-selector .upload.on {
    background-image: url("../img/icons/Camera-White.png");
    cursor: pointer;
}
#analysis_footer_container .all-sites .view-selector .upload:hover,
#analysis_footer_container .all-sites-small .view-selector .upload:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#analysis_footer_container .all-sites .view-selector .detail,
#analysis_footer_container .all-sites-small .view-selector .detail {
    border-radius: 0 4px 4px 0;
    margin-right: 1em;
    margin-left: -4px;
    background-image: url("../img/icons/Detailed-List-Off.png");
}
#analysis_footer_container .all-sites .view-selector .detail.on,
#analysis_footer_container .all-sites-small .view-selector .detail.on {
    background-image: url("../img/icons/Detailed-List-On.png");
    background-color: #4dc2c6;
}
#analysis_footer_container .all-sites .view-selector .detail:hover,
#analysis_footer_container .all-sites-small .view-selector .detail:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#analysis_footer_container .all-sites .view-selector .expander,
#analysis_footer_container .all-sites-small .view-selector .expander {
    border-radius: 4px 0 0 4px;
    margin-right: 2px;
    background-image: url("../img/icons/Open-Full-Screen-Grey.png");
}
#analysis_footer_container .all-sites .view-selector .expander.on,
#analysis_footer_container .all-sites-small .view-selector .expander.on {
    background-image: url("../img/icons/Close-Full-Screen-Grey.png");
}
#analysis_footer_container .all-sites .view-selector .expander:hover,
#analysis_footer_container .all-sites-small .view-selector .expander:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#analysis_footer_container .all-sites .view-selector .retract,
#analysis_footer_container .all-sites-small .view-selector .retract {
    border-radius: 0 4px 4px 0;
    margin-left: -0.4em;
    background-image: url("../img/icons/Show-Up.png");
}
#analysis_footer_container .all-sites .view-selector .retract.on,
#analysis_footer_container .all-sites-small .view-selector .retract.on {
    background-image: url("../img/icons/Show-Down.png");
    cursor: pointer;
}
#analysis_footer_container .all-sites .view-selector .retract:hover,
#analysis_footer_container .all-sites-small .view-selector .retract:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#analysis_footer_container .all-sites .view-selector .export-excel,
#analysis_footer_container .all-sites-small .view-selector .export-excel {
    border-radius: 4px 0 0 4px;
    background-color: inherit;
    margin-right: 0px;
    background-image: url("../img/icons/Excel-off.png");
    cursor: default;
}
#analysis_footer_container .all-sites .view-selector .export-excel.on,
#analysis_footer_container .all-sites-small .view-selector .export-excel.on {
    background-image: url("../img/icons/Excel-on.png");
    cursor: pointer;
}
#analysis_footer_container .all-sites .view-selector .export-excel:hover,
#analysis_footer_container .all-sites-small .view-selector .export-excel:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#analysis_footer_container .all-sites .view-selector .plus,
#analysis_footer_container .all-sites-small .view-selector .plus {
    border-radius: 4px 0 0 4px;
    margin-left: -0.4em;
    background-color: inherit;
    background-image: url("../img/icons/plus-off.png");
    cursor: default;
}
#analysis_footer_container .all-sites .view-selector .plus.on,
#analysis_footer_container .all-sites-small .view-selector .plus.on {
    background-image: url("../img/icons/plus-on.png");
    cursor: pointer;
}
#analysis_footer_container .all-sites .view-selector .minus,
#analysis_footer_container .all-sites-small .view-selector .minus {
    width: 36px;
    margin-right: -10px;
    background-color: inherit;
    background-image: url("../img/icons/minus-off.png");
    cursor: default;
}
#analysis_footer_container .all-sites .view-selector .minus.on,
#analysis_footer_container .all-sites-small .view-selector .minus.on {
    background-image: url("../img/icons/minus-on.png");
    cursor: pointer;
}
#analysis_footer_container .all-sites .view-selector .configuration,
#analysis_footer_container .all-sites-small .view-selector .configuration {
    width: 36px;
    margin-right: -10px;
    background-color: inherit;
    background-image: url("../img/icons/configuration-off.png");
    cursor: default;
}
#analysis_footer_container .all-sites .view-selector .configuration.on,
#analysis_footer_container .all-sites-small .view-selector .configuration.on {
    background-image: url("../img/icons/configuration-on.png");
    cursor: pointer;
}
#analysis_footer_container .all-sites .view-selector .setpoint,
#analysis_footer_container .all-sites-small .view-selector .setpoint {
    width: 36px;
    margin-right: -10px;
    background-color: inherit;
    background-image: url("../img/icons/setpoint-off.png");
    cursor: default;
}
#analysis_footer_container .all-sites .view-selector .setpoint.on,
#analysis_footer_container .all-sites-small .view-selector .setpoint.on {
    background-image: url("../img/icons/setpoint-on.png");
    cursor: pointer;
}
#analysis_footer_container .all-sites .hide-arrow-selector,
#analysis_footer_container .all-sites-small .hide-arrow-selector {
    background-color: #23aa80;
    background-image: url("../img/dashboard/hide-arrows.png");
    background-repeat: no-repeat;
    background-position: 70px center;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    float: right;
    padding: 7px 51px 7px 10px;
    margin: 12px 12px 0 0;
}
#analysis_footer_container .all-sites .hide-arrow-selector.show-bg,
#analysis_footer_container .all-sites-small .hide-arrow-selector.show-bg {
    background-image: url("../img/dashboard/show-arrows.png");
}
#analysis_footer_container .all-sites-small {
    height: 5.3em;
}
#analysis_footer_container .all-sites-small .view-selector {
    margin-top: 0;
    margin-left: 1.7em;
    text-align: right;
}
#analysis_footer_container .upload-container .upload {
    background-color: #4dc2c6;
    background-image: url("../img/icons/upload.png");
}
#analysis_footer_container .sites-detail-list {
    overflow-y: auto;
    position: relative;
    padding: 0 1px;
    transition: height 500ms ease;
    -ms-transition: height 500ms ease;
    -moz-transition: height 500ms ease;
}
#analysis_footer_container .sites-detail-list.hidden {
    height: 0;
}
#analysis_footer_container .sites-table-container {
    overflow-y: auto;
    position: relative;
    transition: height 500ms ease;
    -ms-transition: height 500ms ease;
    -moz-transition: height 500ms ease;
}
#analysis_footer_container .sites-table-container.hidden {
    height: 0;
}
#analysis_footer_container .sites-table-container .battery,
#analysis_footer_container .sites-table-container .total-savings {
    text-align: center;
    font-size: 1.6rem;
    font-weight: 400;
}
#analysis_footer_container .sites-table-container .site-info,
#analysis_footer_container
    .sites-table-container
    #billing_container
    .billing-table-container
    .site-info-medium,
#billing_container
    .billing-table-container
    #analysis_footer_container
    .sites-table-container
    .site-info-medium {
    margin-left: 5px;
    display: inline-block;
    vertical-align: top;
}
#analysis_footer_container .sites-table-container .site-info .name,
#analysis_footer_container
    .sites-table-container
    #billing_container
    .billing-table-container
    .site-info-medium
    .name,
#billing_container
    .billing-table-container
    #analysis_footer_container
    .sites-table-container
    .site-info-medium
    .name {
    font-size: 1.6rem;
}
#analysis_footer_container .sites-table-container .site-info h1,
#analysis_footer_container
    .sites-table-container
    #billing_container
    .billing-table-container
    .site-info-medium
    h1,
#billing_container
    .billing-table-container
    #analysis_footer_container
    .sites-table-container
    .site-info-medium
    h1 {
    font-size: 16px;
    margin-bottom: 0;
}
#analysis_footer_container .sites-table-container .site-info p,
#analysis_footer_container
    .sites-table-container
    #billing_container
    .billing-table-container
    .site-info-medium
    p,
#billing_container
    .billing-table-container
    #analysis_footer_container
    .sites-table-container
    .site-info-medium
    p {
    margin: 0;
}
#analysis_footer_container .sites-table-container .site-image {
    width: 320px;
    height: 120px;
}
#analysis_footer_container .sites-table-container .sites-container-list {
    background-color: #f1f6f6;
    border-spacing: 0;
    color: #3e7075;
    position: relative;
    width: 100%;
    border-collapse: collapse;
}
#analysis_footer_container
    .sites-table-container
    .sites-container-list
    .current_site {
    color: #ffffff !important;
    background-color: #32cc8d !important;
}
#analysis_footer_container .sites-table-container .sites-container-list .site {
    cursor: pointer;
}
#analysis_footer_container .sites-table-container .sites-container-list thead {
    width: 100%;
    height: 100px;
    font-size: 12px;
    font-weight: 600;
}
#analysis_footer_container
    .sites-table-container
    .sites-container-list
    thead
    tr {
    background: #3cd3d5;
    color: #ffffff;
}
#analysis_footer_container
    .sites-table-container
    .sites-container-list
    thead
    tr
    th {
    color: #ffffff;
    border-bottom: 1px solid #d5e1e1;
    border-right: 1px solid #dae5e5;
    padding: 17px 32px 17px 12px;
    position: relative;
    white-space: nowrap;
    font-size: 1.2em;
    font-weight: 500;
}
#analysis_footer_container
    .sites-table-container
    .sites-container-list
    thead
    tr
    th:last-child {
    border-right: none;
}
#analysis_footer_container
    .sites-table-container
    .sites-container-list
    .__sort-true {
    background: no-repeat right center url("../img/icons/sort_desc.gif");
}
#analysis_footer_container
    .sites-table-container
    .sites-container-list
    .__sort-false {
    background: no-repeat right center url("../img/icons/sort_asc.gif");
    background-color: #3cd3d5;
}
#analysis_footer_container .sites-container-carousel,
#analysis_footer_container .sites-container-carousel-large {
    padding: 20px 35px;
    position: relative;
    overflow-x: hidden;
    background-color: #3d5260;
}
#analysis_footer_container .sites-container-carousel.hidden,
#analysis_footer_container .hidden.sites-container-carousel-large {
    height: 0;
}

#carousel-container .gs-carousel {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
#carousel-container .arrow,
#carousel-container .arrow-expanded {
    margin-top: 70%;
    background-color: #2f3e4c;
    background-position: center center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    border: 1px solid #4e6a7b;
    height: 150px;
    width: 46px;
    z-index: 10;
}
#carousel-container .arrow:hover,
#carousel-container .arrow-expanded:hover {
    background-color: #4e6a7b;
}
#carousel-container .arrow.left,
#carousel-container .arrow.left-expanded-view,
#carousel-container .left-expanded-view.arrow-expanded,
#carousel-container .left.arrow-expanded {
    border-radius: 0 4px 4px 0;
    background-image: url("../img/icons/arrow-left.png");
    left: -1px;
    top: 75px;
}
#carousel-container .arrow.left-expanded-view,
#carousel-container .left-expanded-view.arrow-expanded {
    top: 215px;
}
#carousel-container .arrow.right,
#carousel-container .arrow.right-expanded-view,
#carousel-container .right-expanded-view.arrow-expanded,
#carousel-container .right.arrow-expanded {
    border-radius: 4px 0 0 4px;
    background-image: url("../img/icons/arrow-right.png");
    right: -1px;
    top: 75px;
}
#carousel-container .arrow.right-expanded-view,
#carousel-container .right-expanded-view.arrow-expanded {
    top: 215px;
}
#carousel-container .arrow-expanded {
    margin-top: 260%;
}
#carousel-container .current_site {
    background-color: #32cc8d;
}
#carousel-container .sites-scroller {
    -webkit-transition: left 500ms ease;
    -ms-transition: left 500ms ease;
    -moz-transition: left 500ms ease;
    transition: left 500ms ease;
}
#carousel-container .rotate {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
}
#carousel-container .site-carousel-multiple-rows {
    background-color: #465c69;
    color: #fff;
    font-weight: 100;
}
#carousel-container .site-carousel-multiple-rows .site-img-container {
    background-color: #6a7c87;
    text-align: center;
}
#carousel-container .site-carousel-multiple-rows .site-img-container .site-img {
    width: 100%;
    height: 100%;
}
#carousel-container .site-carousel-multiple-rows h3,
#carousel-container .site-carousel-multiple-rows h4 {
    color: #fff;
    font-size: 36px;
    font-weight: 100;
}
#carousel-container .site-carousel-multiple-rows p {
    font-size: 12px;
}
#carousel-container .site {
    width: 50%;
    height: 50%;
    background-color: #465c69;
    color: #fff;
    font-weight: 100;
    margin-top: 2%;
    margin-left: 2%;
}
#carousel-container .site .site-img {
    background-color: #6a7c87;
    text-align: center;
    width: 100%;
    height: 100%;
}
#carousel-container .site h3,
#carousel-container .site h4 {
    color: #fff;
    font-size: 36px;
    font-weight: 100;
}
#carousel-container .site p {
    font-size: 12px;
}
#carousel-container .site-info .savings,
#carousel-container
    #billing_container
    .billing-table-container
    .site-info-medium
    .savings,
#billing_container
    .billing-table-container
    #carousel-container
    .site-info-medium
    .savings {
    float: left;
    width: 70%;
}
#carousel-container .site-info .savings p,
#carousel-container
    #billing_container
    .billing-table-container
    .site-info-medium
    .savings
    p,
#billing_container
    .billing-table-container
    #carousel-container
    .site-info-medium
    .savings
    p {
    margin-bottom: 0;
}
#carousel-container .site-info .status,
#carousel-container
    #billing_container
    .billing-table-container
    .site-info-medium
    .status,
#billing_container
    .billing-table-container
    #carousel-container
    .site-info-medium
    .status,
#carousel-container .site-info .status-all,
#carousel-container
    #billing_container
    .billing-table-container
    .site-info-medium
    .status-all,
#billing_container
    .billing-table-container
    #carousel-container
    .site-info-medium
    .status-all {
    border-left: 1px solid #3c4f55;
    float: left;
    text-align: center;
    width: 30%;
}
#carousel-container .site-info .status.center,
#carousel-container
    #billing_container
    .billing-table-container
    .site-info-medium
    .status.center,
#billing_container
    .billing-table-container
    #carousel-container
    .site-info-medium
    .status.center,
#carousel-container .site-info .center.status-all,
#carousel-container
    #billing_container
    .billing-table-container
    .site-info-medium
    .center.status-all,
#billing_container
    .billing-table-container
    #carousel-container
    .site-info-medium
    .center.status-all {
    margin-left: 35%;
}
#carousel-container .site-info .status-all,
#carousel-container
    #billing_container
    .billing-table-container
    .site-info-medium
    .status-all,
#billing_container
    .billing-table-container
    #carousel-container
    .site-info-medium
    .status-all {
    width: 100%;
}
#carousel-container .left-arrow,
#carousel-container .right-arrow {
    color: #3cd3d5;
    cursor: pointer;
    font-weight: bold;
    line-height: 220px;
    padding: 0 10px;
    position: absolute;
    top: 20px;
    bottom: 20px;
    vertical-align: top;
}
#carousel-container .left-arrow:hover,
#carousel-container .right-arrow:hover {
    background-color: rgba(255, 255, 255, 0.2);
}
#carousel-container .left-arrow {
    left: 10px;
}
#carousel-container .right-arrow {
    right: 10px;
}

#vitals-health-monitoring-container label {
    font-size: 18px;
    font-weight: 300;
}

.portfolio-overview .main-nav {
    height: 605px;
    padding-left: 0;
}
.portfolio-overview .main-nav label {
    color: white;
    font-size: 13px;
    font-weight: 100;
}
.portfolio-overview .heading {
    margin-right: 0;
    padding-top: 15px;
    height: 55px;
    background-color: #374859;
    font-size: 1.2em;
    font-weight: 100;
    color: #a4c8c8;
    padding-left: 20px;
    position: relative;
}
.portfolio-overview .heading.selected,
.portfolio-overview .heading:hover {
    color: white;
    background-color: #080b0d;
    border-radius: 0;
}
.portfolio-overview .heading.selected:after {
    border-style: solid;
    border-width: 6px 0 6px 10px;
    border-color: transparent transparent transparent #000000;
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    right: -5%;
    top: 35%;
    clear: both;
}
.portfolio-overview .heading-sub-nav {
    color: white;
    font-size: 18px;
    background-color: #42586c;
}
.portfolio-overview .heading-sub-nav.lg {
    height: 150px;
}
.portfolio-overview .heading-sub-nav.md {
    height: 100px;
}
.portfolio-overview .heading-sub-nav.sm {
    height: 60px;
}
.portfolio-overview .heading-sub-nav .pad-left {
    padding-left: 20%;
}
.portfolio-overview .content-view {
    border: none;
    background-color: #acb8ba;
}

.solar-generation {
    background-image: url("../img/icons/nav-icon-solar.svg");
    background-repeat: no-repeat;
    height: 55px;
}
.solar-generation.active,
.solar-generation:hover {
    background-image: url("../img/icons/nav-icon-solar-on.svg");
}

.wiz-solar-generation {
    background-image: url("../img/icons/nav-icon-solar-on.svg");
    background-repeat: no-repeat;
    height: 55px;
}
.wiz-solar-generation.active,
.wiz-solar-generation:hover {
    background-image: url("../img/icons/nav-icon-solar.svg");
}

.wiz-selector-project {
    background-image: url("../img/icons/vpp-selector-project-icon-on.svg");
    background-repeat: no-repeat;
    height: 55px;
}
.wiz-selector-project.active,
.wiz-selector-project:hover {
    background-image: url("../img/icons/vpp-selector-project-icon-off.svg");
}

.wiz-selector-storage {
    background-image: url("../img/icons/vpp-selector-project-icon-on.svg");
    background-repeat: no-repeat;
    height: 55px;
}
.wiz-selector-storage.active,
.wiz-selector-storage:hover {
    background-image: url("../img/icons/vpp-selector-project-icon-off.svg");
}

.itc {
    background-image: url("../img/icons/nav-icon-freq-reg-on.svg");
    background-repeat: no-repeat;
    height: 55px;
}
.itc.active,
.itc:hover {
    background-image: url("../img/icons/nav-icon-freq-reg.svg");
}

.health-cycle {
    background-image: url("../img/icons/nav-icon-freq-reg-on.svg");
    background-repeat: no-repeat;
    height: 55px;
}
.health-cycle.active,
.health-cycle:hover {
    background-image: url("../img/icons/nav-icon-freq-reg.svg");
}

#vpp-ops-container {
    background-color: #4d6573;
    border: 1px solid #4d6573;
    overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
}
#vpp-ops-container .gc-margin-bottom {
    margin-bottom: 0.5%;
}
#vpp-ops-container .graph-title {
    font-size: 1.2rem;
}
#vpp-ops-container .no-scroll {
    overflow: hidden !important;
}
#vpp-ops-container .col:nth-child(1) {
    width: 49%;
    margin-top: 0.5%;
    margin-bottom: 0.5%;
    background-color: #2f3e4c;
}
#vpp-ops-container .col:nth-child(2) {
    margin-top: 1%;
    width: 3%;
    text-align: center;
}
#vpp-ops-container .col:nth-child(3) {
    width: 46%;
    margin-top: 0.5%;
    margin-bottom: 0.5%;
}
#vpp-ops-container .col:nth-child(4) {
    width: 49%;
    margin-bottom: 1%;
    border-radius: 0;
}
#vpp-ops-container .col:nth-child(5) {
    width: 3%;
}
#vpp-ops-container .col:nth-child(6) {
    width: 46%;
    overflow-y: scroll;
    background: #2f3e4c;
    margin-bottom: 1%;
    border-radius: 0;
}
#vpp-ops-container .side-graph-menu {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background: #2f3e4c;
    margin: 0 auto;
    height: 100%;
}
#vpp-ops-container .side-graph-menu .graph-export {
    background-image: url("../img/icons/icon-export.svg");
    background-repeat: no-repeat;
    height: 55px;
}
#vpp-ops-container .side-graph-menu .graph-export.active,
#vpp-ops-container .side-graph-menu .graph-export:hover {
    background-image: url("../img/icons/icon-export-on.svg");
}
#vpp-ops-container .side-graph-menu .graph-print {
    background-image: url("../img/icons/icon-print.svg");
    background-repeat: no-repeat;
    height: 55px;
}
#vpp-ops-container .side-graph-menu .graph-print.active,
#vpp-ops-container .side-graph-menu .graph-print:hover {
    background-image: url("../img/icons/icon-print-on.svg");
}
#vpp-ops-container .side-graph-menu .graph-download {
    background-image: url("../img/icons/icon-export-download.svg");
    background-repeat: no-repeat;
    height: 55px;
}
#vpp-ops-container .side-graph-menu .graph-download.active,
#vpp-ops-container .side-graph-menu .graph-download:hover {
    background-image: url("../img/icons/icon-export-download-on.svg");
}
#vpp-ops-container .side-graph-menu .energy-arbitrage {
    background-image: url("../img/icons/nav-icon-energy-arbitrage.svg");
    background-repeat: no-repeat;
    height: 55px;
}
#vpp-ops-container .side-graph-menu .energy-arbitrage.active,
#vpp-ops-container .side-graph-menu .energy-arbitrage:hover {
    background-image: url("../img/icons/nav-icon-energy-arbitrage-on.svg");
}
#vpp-ops-container .side-graph-menu .peak-shaving {
    background-image: url("../img/icons/nav-icon-peak-mgmt.svg");
    background-repeat: no-repeat;
    height: 55px;
}
#vpp-ops-container .side-graph-menu .peak-shaving.active,
#vpp-ops-container .side-graph-menu .peak-shaving:hover {
    background-image: url("../img/icons/nav-icon-peak-mgmt-on.svg");
}
#vpp-ops-container .side-graph-menu .demand-response {
    background-image: url("../img/icons/nav-icon-demand-response.svg");
    background-repeat: no-repeat;
    height: 55px;
}
#vpp-ops-container .side-graph-menu .demand-response.active,
#vpp-ops-container .side-graph-menu .demand-response:hover {
    background-image: url("../img/icons/nav-icon-demand-response-on.svg");
}
#vpp-ops-container .side-graph-menu .vpp-selector {
    background-image: url("../img/icons/vpp-selector-icon-off.svg");
    background-repeat: no-repeat;
    height: 55px;
    width: 3vw;
}
#vpp-ops-container .side-graph-menu .vpp-selector.active,
#vpp-ops-container .side-graph-menu .vpp-selector:hover {
    background-image: url("../img/icons/vpp-selector-icon-on.svg");
}
#vpp-ops-container .side-graph-menu .vpp-selector-revenue {
    background-image: url("../img/icons/vpp-selector-revenue-icon-off.svg");
    background-repeat: no-repeat;
    width: 3vw;
    height: 55px;
}
#vpp-ops-container .side-graph-menu .vpp-selector-revenue.active,
#vpp-ops-container .side-graph-menu .vpp-selector-revenue:hover {
    background-image: url("../img/icons/vpp-selector-revenue-icon-on.svg");
}
#vpp-ops-container .side-graph-menu .vpp-selector-config {
    background-image: url("../img/icons/vpp-selector-config-icon-off.svg");
    background-repeat: no-repeat;
    width: 3vw;
    height: 55px;
}
#vpp-ops-container .side-graph-menu .vpp-selector-config.active,
#vpp-ops-container .side-graph-menu .vpp-selector-config:hover {
    background-image: url("../img/icons/vpp-selector-config-icon-on.svg");
}
#vpp-ops-container .side-graph-menu .vpp-selector-project {
    background-image: url("../img/icons/vpp-selector-project-icon-off.svg");
    background-repeat: no-repeat;
    width: 3vw;
    height: 55px;
}
#vpp-ops-container .side-graph-menu .vpp-selector-project.active,
#vpp-ops-container .side-graph-menu .vpp-selector-project:hover {
    background-image: url("../img/icons/vpp-selector-project-icon-on.svg");
}
#vpp-ops-container .side-graph-menu .frequency-regulation {
    background-image: url("../img/icons/nav-icon-freq-reg.svg");
    background-repeat: no-repeat;
    height: 55px;
}
#vpp-ops-container .side-graph-menu .frequency-regulation.active,
#vpp-ops-container .side-graph-menu .frequency-regulation:hover {
    background-image: url("../img/icons/nav-icon-freq-reg-on.svg");
}
#vpp-ops-container .site-services-flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    overflow: hidden;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
}
#vpp-ops-container .site-services-flex .item1 {
    width: 30%;
    -webkit-box-ordinal-group: 0;
    -moz-box-ordinal-group: 0;
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
}
#vpp-ops-container .site-services-flex .item1:hover {
    background-color: #6e8ba6;
    border-radius: 1px;
}
#vpp-ops-container .site-services-flex .item2 {
    width: 73%;
    -webkit-box-ordinal-group: 0;
    -moz-box-ordinal-group: 0;
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
}
#vpp-ops-container .scrollable-panel {
    overflow-y: auto;
}
#vpp-ops-container .header-header-title {
    color: white;
    font-size: 16px;
    font-weight: 500;
}
#vpp-ops-container .left-panel .scrollable-panel {
    margin-top: 1%;
    border-radius: 10px;
    background: #2f3e4c;
    height: 320px;
    overflow-y: scroll;
}
#vpp-ops-container .left-panel .scrollable-panel .graph-container {
    margin-left: -2%;
}
#vpp-ops-container .left-panel .header-header-title {
    color: white;
    font-size: 13px;
    font-weight: bold;
    text-decoration: underline;
}
#vpp-ops-container .right-panel .dashboard {
    background: #2f3e4c;
    border-radius: 10px;
    padding: 0;
    color: white;
    height: 277px;
}
#vpp-ops-container .right-panel .scrollable-panel {
    margin-top: 1%;
    border-radius: 10px;
    background: #2f3e4c;
    height: 320px;
    overflow-y: scroll;
    color: white;
}
#vpp-ops-container .right-panel .scrollable-panel .graph-container {
    margin-left: -2%;
}
#vpp-ops-container .right-panel .scrollable-panel .bordered-bottom {
    border-bottom: 1px solid #3e967a;
}
#vpp-ops-container .right-panel .scrollable-panel .header-header-title {
    font-size: 13px;
    font-weight: bold;
    text-decoration: underline;
}
#vpp-ops-container .title,
#vpp-ops-container .right-panel .scrollable-panel .title {
    font-size: 13px;
    font-weight: 100;
    color: #adbecd;
}
#vpp-ops-container .sub-title {
    font-size: 7px;
    font-weight: 400;
    vertical-align: middle;
    color: white;
}
#vpp-ops-container .bar,
#vpp-ops-container .light-bar {
    margin-top: 1.7%;
    height: 3px;
    background-color: #adbecd;
}
#vpp-ops-container .light-bar {
    margin-top: 0;
    background-color: #64747e;
}
#vpp-ops-container .graph-panel {
    background-color: #2f3e4c;
    height: 100%;
}
#vpp-ops-container .selected {
    background-color: #6e8ba6;
    border-radius: 1px;
}
#vpp-ops-container .box {
    text-align: center;
    background-color: #3f5365;
    border-radius: 5px;
}
#vpp-ops-container .box .title,
#vpp-ops-container .box .right-panel .scrollable-panel .title,
#vpp-ops-container .right-panel .scrollable-panel .box .title {
    font-size: 13px;
    font-weight: 500;
    color: #8b979f;
}
#vpp-ops-container .box .data {
    font-size: 20px;
    text-align: center;
    color: white;
}
@-moz-document url-prefix() {
    #vpp-ops-container .col:nth-child(3) {
        margin-bottom: 10px;
    }
}
@supports (-ms-ime-align: auto) {
    #vpp-ops-container .col:nth-child(3) {
        margin-bottom: 10px;
    }
}

.time-selector-flex-optimizer {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    font-size: 12px;
    font-weight: 100;
}
.time-selector-flex-optimizer label {
    color: white;
}
.time-selector-flex-optimizer label.selected {
    background-color: #6e8ba6;
}
.time-selector-flex-optimizer .item {
    width: 20%;
    -webkit-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
}
.time-selector-flex-optimizer .with-border.active,
.time-selector-flex-optimizer .with-border:hover {
    border: 1px solid #3cd3d5 !important;
    border-radius: 5px !important;
}
.time-selector-flex-optimizer .selected {
    border: 1px solid #3cd3d5;
    border-radius: 5px !important;
    background-color: transparent !important;
}

.time-selector-flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    border: 1px solid white;
    font-size: 12px;
    font-weight: 100;
}
.time-selector-flex label {
    color: white;
}
.time-selector-flex label.selected {
    background-color: #6e8ba6;
}
.time-selector-flex .item {
    width: 20%;
    -webkit-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
}
.time-selector-flex .with-border.active,
.time-selector-flex .with-border:hover {
    border: 1px solid #3cd3d5;
    border-radius: 5px;
}

#vpp-configuration-container {
    padding: 0;
}
#vpp-configuration-container .vpp-config-flex {
    height: 57px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
}
#vpp-configuration-container .vpp-config-flex .item {
    width: 33%;
    margin-right: 1%;
    -webkit-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
}

#perf-summary-stats-container {
    margin-top: 1%;
}
#perf-summary-stats-container .perf-flex {
    height: 50px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
}
#perf-summary-stats-container .perf-flex .item {
    width: 50%;
    margin-right: 1%;
    -webkit-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
}

#stats-container .sub-header-title,
#demand-response-stats-container .sub-header-title {
    font-size: 10px;
    font-weight: 500;
    color: white;
}
#stats-container .sub-sub-header-title,
#demand-response-stats-container .sub-sub-header-title {
    font-size: 8px;
    font-weight: 300;
    color: white;
}
#stats-container .tbh-col-title,
#demand-response-stats-container .tbh-col-title,
#stats-container .header-title,
#demand-response-stats-container .header-title,
#stats-container .revenue-table .rev-title,
#demand-response-stats-container .revenue-table .rev-title,
#stats-container .revenue-table .rev-on-peak,
#demand-response-stats-container .revenue-table .rev-on-peak,
#stats-container .revenue-table .rev-part-peak,
#demand-response-stats-container .revenue-table .rev-part-peak,
#stats-container .revenue-table .rev-off-peak,
#demand-response-stats-container .revenue-table .rev-off-peak,
#stats-container .revenue-table .back-feed,
#demand-response-stats-container .revenue-table .back-feed,
#stats-container .revenue-table .rev-total,
#demand-response-stats-container .revenue-table .rev-total,
#stats-container .revenue-table .rev-start,
#demand-response-stats-container .revenue-table .rev-start,
#stats-container .revenue-table .rev-norm,
#demand-response-stats-container .revenue-table .rev-norm,
#stats-container .revenue-table .rev-misc,
#demand-response-stats-container .revenue-table .rev-misc {
    font-size: 13px;
    font-weight: 500;
    color: #fff;
}
#stats-container .revenue-table,
#demand-response-stats-container .revenue-table {
    color: #8b979f;
    /*
   * Basic styles, good for a large display. Everything fits in
   * one row, no wrapping. All text based cells grow equally.
   */
    /*
   * Media queries: optimize for different screen widths.
   */
    /*
   * Media breaks.
   */
    /*
   * General good-look styles
   */
}
#stats-container .revenue-table label,
#demand-response-stats-container .revenue-table label {
    color: #fff;
}
#stats-container .revenue-table.scrollable,
#demand-response-stats-container .revenue-table.scrollable {
    height: 200px;
    border: 1px solid lightgrey;
    overflow-y: scroll;
}
#stats-container .revenue-table .table-row,
#demand-response-stats-container .revenue-table .table-row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}
#stats-container .revenue-table .wrapper,
#demand-response-stats-container .revenue-table .wrapper {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}
#stats-container .revenue-table .column,
#demand-response-stats-container .revenue-table .column {
    -webkit-flex-grow: 0;
    -ms-flex-grow: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -ms-flex-shrink: 0;
    flex-shrink: 0;
    vertical-align: top;
}
#stats-container .revenue-table .rev-title,
#demand-response-stats-container .revenue-table .rev-title {
    width: 30%;
    font-weight: 500;
}
#stats-container .revenue-table .rev-on-peak,
#demand-response-stats-container .revenue-table .rev-on-peak {
    width: 15%;
}
#stats-container .revenue-table .rev-part-peak,
#demand-response-stats-container .revenue-table .rev-part-peak {
    width: 15%;
}
#stats-container .revenue-table .rev-off-peak,
#demand-response-stats-container .revenue-table .rev-off-peak {
    width: 15%;
}
#stats-container .revenue-table .back-feed,
#demand-response-stats-container .revenue-table .back-feed {
    width: 15%;
}
#stats-container .revenue-table .rev-total,
#demand-response-stats-container .revenue-table .rev-total {
    width: 10%;
}
#stats-container .revenue-table .kwh,
#demand-response-stats-container .revenue-table .kwh {
    width: 15%;
}
#stats-container .revenue-table .rev-start,
#demand-response-stats-container .revenue-table .rev-start {
    width: 21%;
}
#stats-container .revenue-table .rev-norm,
#demand-response-stats-container .revenue-table .rev-norm {
    width: 15%;
}
#stats-container .revenue-table .rev-misc,
#demand-response-stats-container .revenue-table .rev-misc {
    width: 13%;
}
#stats-container .revenue-table .table-row,
#demand-response-stats-container .revenue-table .table-row {
    border-collapse: collapse;
}
#stats-container .revenue-table .table-row.rev-table-header,
#demand-response-stats-container .revenue-table .table-row.rev-table-header {
    color: #fff;
    margin-bottom: 5px;
    font-weight: 500;
    border-bottom: 1px solid #fff;
}
#stats-container .revenue-table .table-row-data,
#demand-response-stats-container .revenue-table .table-row-data {
    margin-bottom: 1%;
    height: 45px;
    padding: 7px;
    color: #667275;
    border-bottom: 1px solid #fff;
}

#demand-response-stats-container table {
    width: 100%;
}
#demand-response-stats-container table tbody tr {
    background-color: lightgrey;
}

/* ----------- laptop and desktop devices -- Non-Retina Screens ----------- */
/* ----------- laptop and desktop devices -- Retina Screens ----------- */
/* ----------- standard monitor size 1280x800 ----------- */
/* ----------- standard monitor size 1920x1080 ----------- */
@media only screen and (min-device-width: 1920px) and (min-device-height: 1080px) {
    #vpp-ops-container .col:nth-child(4) {
        margin-bottom: 1%;
    }
    #vpp-ops-container .col:nth-child(6) {
        margin-bottom: 1%;
    }
    #vpp-ops-container .header-header-title {
        font-size: 14px;
        font-weight: 600;
    }
    #vpp-ops-container .title,
    #vpp-ops-container .right-panel .scrollable-panel .title {
        font-size: 10px;
    }
    #vpp-ops-container .site-services-flex .item1 {
        width: 50%;
    }
    #vpp-ops-container .side-graph-menu .solar-generation,
    #vpp-ops-container .side-graph-menu .energy-arbitrage,
    #vpp-ops-container .side-graph-menu .peak-shaving,
    #vpp-ops-container .side-graph-menu .demand-response,
    #vpp-ops-container .side-graph-menu .frequency-regulation {
        height: 55px;
    }

    #perf-summary-stats-container .title,
    #perf-summary-stats-container
        #vpp-ops-container
        .right-panel
        .scrollable-panel
        .title,
    #vpp-ops-container
        .right-panel
        .scrollable-panel
        #perf-summary-stats-container
        .title {
        font-size: 14px;
    }

    #vpp-configuration-container .box .title,
    #vpp-configuration-container
        .box
        #vpp-ops-container
        .right-panel
        .scrollable-panel
        .title,
    #vpp-ops-container
        .right-panel
        .scrollable-panel
        #vpp-configuration-container
        .box
        .title {
        font-size: 12px;
    }
}
/* ----------- standard monitor size 1366x768 ----------- */
@media only screen and (max-width: 1366px) {
    #vpp-ops-container .side-graph-menu .solar-generation,
    #vpp-ops-container .side-graph-menu .energy-arbitrage,
    #vpp-ops-container .side-graph-menu .peak-shaving,
    #vpp-ops-container .side-graph-menu .demand-response,
    #vpp-ops-container .side-graph-menu .frequency-regulation {
        height: 33px;
    }
}
/* ----------- standard monitor size 1280x800 ----------- */
@media only screen and (max-width: 1280px) {
    #vpp-ops-container .side-graph-menu .solar-generation,
    #vpp-ops-container .side-graph-menu .energy-arbitrage,
    #vpp-ops-container .side-graph-menu .peak-shaving,
    #vpp-ops-container .side-graph-menu .demand-response,
    #vpp-ops-container .side-graph-menu .frequency-regulation {
        height: 43px;
    }
}
/* ----------- tablet landscape mode size 1024x768 ----------- */
@media only screen and (min-width: 40.063em) and (max-width: 64em) {
    #vpp-ops-container .col {
        height: 287px;
        -webkit-flex-grow: 1;
        -ms-flex-grow: 1;
        flex-grow: 1;
        background: #2f3e4c;
        border-radius: 10px;
    }
    #vpp-ops-container .col:nth-child(1) {
        width: 98%;
        -webkit-box-ordinal-group: 0;
        -moz-box-ordinal-group: 0;
        -ms-flex-order: 0;
        -webkit-order: 0;
        order: 0;
        background: #2f3e4c;
        border-radius: 10px;
        margin-top: 1%;
        margin-left: 1%;
        margin-right: 1%;
    }
    #vpp-ops-container .col:nth-child(2) {
        width: 98%;
        -webkit-box-ordinal-group: 0;
        -moz-box-ordinal-group: 0;
        -ms-flex-order: 0;
        -webkit-order: 0;
        order: 0;
        background: #2f3e4c;
        margin-left: 1%;
        margin-right: 1%;
        margin-top: 1%;
        border-radius: 10px;
    }
    #vpp-ops-container .col:nth-child(3) {
        width: 98%;
        -webkit-box-ordinal-group: 0;
        -moz-box-ordinal-group: 0;
        -ms-flex-order: 0;
        -webkit-order: 0;
        order: 0;
        background: #2f3e4c;
        margin-top: 1%;
        margin-left: 1%;
        margin-right: 1%;
        border-radius: 10px;
    }
    #vpp-ops-container .col:nth-child(4) {
        width: 98%;
        -webkit-box-ordinal-group: 0;
        -moz-box-ordinal-group: 0;
        -ms-flex-order: 0;
        -webkit-order: 0;
        order: 0;
        margin-top: 1%;
        margin-left: 1%;
        margin-right: 1%;
    }
    #vpp-ops-container .site-services-flex {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        overflow: hidden;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        -ms-justify-content: flex-start;
        justify-content: flex-start;
    }
    #vpp-ops-container .site-services-flex .item1 {
        width: 50%;
        -webkit-box-ordinal-group: 0;
        -moz-box-ordinal-group: 0;
        -ms-flex-order: 0;
        -webkit-order: 0;
        order: 0;
    }
    #vpp-ops-container .site-services-flex .item2 {
        width: 82%;
        -webkit-box-ordinal-group: 0;
        -moz-box-ordinal-group: 0;
        -ms-flex-order: 0;
        -webkit-order: 0;
        order: 0;
    }

    #vitals-health-container .health-title {
        font-size: 0.9em;
        font-weight: 100;
        color: #8ea4b9;
    }
    #vitals-health-container .health-val {
        font-size: 1.7em;
        font-weight: 400;
        color: white;
    }
}
/* min-width 641px, medium screens */
#vpp_footer_container {
    z-index: 100;
    margin-top: 1px;
    position: fixed;
    height: 3.1em;
    background-color: #465c69;
    bottom: 0;
    left: 0;
    right: 0;
}
#vpp_footer_container .gc-footer-height-expand {
    height: 625px;
}
#vpp_footer_container .list-row-height {
    height: 120px;
}
#vpp_footer_container .detail-grid-expand {
    height: 580px;
}
#vpp_footer_container .detail-grid-retract {
    height: 300px;
}
#vpp_footer_container .footer-flex {
    width: 100%;
    background-color: #3d5260;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3.1em;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    overflow: hidden;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
}
#vpp_footer_container .footer-flex .item {
    padding-top: 0.5%;
    -ms-flex-grow: 1;
    flex-grow: 1;
    color: #95b6b6;
    font-size: 10px;
}
#vpp_footer_container .footer-flex .left,
#vpp_footer_container .footer-flex .vitals-main .arrow.left-expanded-view,
.vitals-main #vpp_footer_container .footer-flex .arrow.left-expanded-view,
#vpp_footer_container
    .footer-flex
    #carousel-container
    .arrow.left-expanded-view,
#carousel-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view,
#vpp_footer_container
    .footer-flex
    #carousel-container
    .left-expanded-view.arrow-expanded,
#carousel-container
    #vpp_footer_container
    .footer-flex
    .left-expanded-view.arrow-expanded,
#vpp_footer_container
    .footer-flex
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view,
#vpp-ops-overview-graph-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view,
#vpp_footer_container
    .footer-flex
    #vitals-health-container
    .arrow.left-expanded-view,
#vitals-health-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view {
    -webkit-box-flex: 1;
    /* OLD - iOS 6-, Safari 3.1-6 */
    -moz-box-flex: 1;
    /* OLD - Firefox 19- */
    -webkit-flex: 1;
    /* Chrome */
    -ms-flex: 1;
    flex: 1;
}
#vpp_footer_container .footer-flex .left .left-panel,
#vpp_footer_container
    .footer-flex
    .vitals-main
    .arrow.left-expanded-view
    .left-panel,
.vitals-main
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel,
#vpp_footer_container
    .footer-flex
    #carousel-container
    .arrow.left-expanded-view
    .left-panel,
#carousel-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel,
#vpp_footer_container
    .footer-flex
    #carousel-container
    .left-expanded-view.arrow-expanded
    .left-panel,
#carousel-container
    #vpp_footer_container
    .footer-flex
    .left-expanded-view.arrow-expanded
    .left-panel,
#vpp_footer_container
    .footer-flex
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .left-panel,
#vpp-ops-overview-graph-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel,
#vpp_footer_container
    .footer-flex
    #vitals-health-container
    .arrow.left-expanded-view
    .left-panel,
#vitals-health-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    padding-top: 0.9%;
}
#vpp_footer_container .footer-flex .left .left-panel .sort-by,
#vpp_footer_container
    .footer-flex
    .vitals-main
    .arrow.left-expanded-view
    .left-panel
    .sort-by,
.vitals-main
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by,
#vpp_footer_container
    .footer-flex
    #carousel-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by,
#carousel-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by,
#vpp_footer_container
    .footer-flex
    #carousel-container
    .left-expanded-view.arrow-expanded
    .left-panel
    .sort-by,
#carousel-container
    #vpp_footer_container
    .footer-flex
    .left-expanded-view.arrow-expanded
    .left-panel
    .sort-by,
#vpp_footer_container
    .footer-flex
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by,
#vpp-ops-overview-graph-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by,
#vpp_footer_container
    .footer-flex
    #vitals-health-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by,
#vitals-health-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by {
    padding-left: 1%;
    width: 80%;
}
#vpp_footer_container .footer-flex .left .left-panel .sort-by .one,
#vpp_footer_container
    .footer-flex
    .vitals-main
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .one,
.vitals-main
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .one,
#vpp_footer_container
    .footer-flex
    #carousel-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .one,
#carousel-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .one,
#vpp_footer_container
    .footer-flex
    #carousel-container
    .left-expanded-view.arrow-expanded
    .left-panel
    .sort-by
    .one,
#carousel-container
    #vpp_footer_container
    .footer-flex
    .left-expanded-view.arrow-expanded
    .left-panel
    .sort-by
    .one,
#vpp_footer_container
    .footer-flex
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .one,
#vpp-ops-overview-graph-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .one,
#vpp_footer_container
    .footer-flex
    #vitals-health-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .one,
#vitals-health-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .one {
    width: 12%;
}
#vpp_footer_container .footer-flex .left .left-panel .sort-by .two,
#vpp_footer_container
    .footer-flex
    .vitals-main
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .two,
.vitals-main
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .two,
#vpp_footer_container
    .footer-flex
    #carousel-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .two,
#carousel-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .two,
#vpp_footer_container
    .footer-flex
    #carousel-container
    .left-expanded-view.arrow-expanded
    .left-panel
    .sort-by
    .two,
#carousel-container
    #vpp_footer_container
    .footer-flex
    .left-expanded-view.arrow-expanded
    .left-panel
    .sort-by
    .two,
#vpp_footer_container
    .footer-flex
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .two,
#vpp-ops-overview-graph-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .two,
#vpp_footer_container
    .footer-flex
    #vitals-health-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .two,
#vitals-health-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .two {
    width: 40%;
}
#vpp_footer_container .footer-flex .left .left-panel .sort-by .three,
#vpp_footer_container
    .footer-flex
    .vitals-main
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .three,
.vitals-main
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .three,
#vpp_footer_container
    .footer-flex
    #carousel-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .three,
#carousel-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .three,
#vpp_footer_container
    .footer-flex
    #carousel-container
    .left-expanded-view.arrow-expanded
    .left-panel
    .sort-by
    .three,
#carousel-container
    #vpp_footer_container
    .footer-flex
    .left-expanded-view.arrow-expanded
    .left-panel
    .sort-by
    .three,
#vpp_footer_container
    .footer-flex
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .three,
#vpp-ops-overview-graph-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .three,
#vpp_footer_container
    .footer-flex
    #vitals-health-container
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .three,
#vitals-health-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .sort-by
    .three {
    width: 45%;
}
#vpp_footer_container .footer-flex .left .left-panel .filter-by,
#vpp_footer_container
    .footer-flex
    .vitals-main
    .arrow.left-expanded-view
    .left-panel
    .filter-by,
.vitals-main
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by,
#vpp_footer_container
    .footer-flex
    #carousel-container
    .arrow.left-expanded-view
    .left-panel
    .filter-by,
#carousel-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by,
#vpp_footer_container
    .footer-flex
    #carousel-container
    .left-expanded-view.arrow-expanded
    .left-panel
    .filter-by,
#carousel-container
    #vpp_footer_container
    .footer-flex
    .left-expanded-view.arrow-expanded
    .left-panel
    .filter-by,
#vpp_footer_container
    .footer-flex
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .left-panel
    .filter-by,
#vpp-ops-overview-graph-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by,
#vpp_footer_container
    .footer-flex
    #vitals-health-container
    .arrow.left-expanded-view
    .left-panel
    .filter-by,
#vitals-health-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by {
    padding-left: 5%;
    width: 50%;
}
#vpp_footer_container .footer-flex .left .left-panel .filter-by .one,
#vpp_footer_container
    .footer-flex
    .vitals-main
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .one,
.vitals-main
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .one,
#vpp_footer_container
    .footer-flex
    #carousel-container
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .one,
#carousel-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .one,
#vpp_footer_container
    .footer-flex
    #carousel-container
    .left-expanded-view.arrow-expanded
    .left-panel
    .filter-by
    .one,
#carousel-container
    #vpp_footer_container
    .footer-flex
    .left-expanded-view.arrow-expanded
    .left-panel
    .filter-by
    .one,
#vpp_footer_container
    .footer-flex
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .one,
#vpp-ops-overview-graph-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .one,
#vpp_footer_container
    .footer-flex
    #vitals-health-container
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .one,
#vitals-health-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .one {
    width: 25%;
}
#vpp_footer_container .footer-flex .left .left-panel .filter-by .two,
#vpp_footer_container
    .footer-flex
    .vitals-main
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .two,
.vitals-main
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .two,
#vpp_footer_container
    .footer-flex
    #carousel-container
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .two,
#carousel-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .two,
#vpp_footer_container
    .footer-flex
    #carousel-container
    .left-expanded-view.arrow-expanded
    .left-panel
    .filter-by
    .two,
#carousel-container
    #vpp_footer_container
    .footer-flex
    .left-expanded-view.arrow-expanded
    .left-panel
    .filter-by
    .two,
#vpp_footer_container
    .footer-flex
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .two,
#vpp-ops-overview-graph-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .two,
#vpp_footer_container
    .footer-flex
    #vitals-health-container
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .two,
#vitals-health-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    .filter-by
    .two {
    width: 70%;
}
#vpp_footer_container .footer-flex .left .left-panel label,
#vpp_footer_container
    .footer-flex
    .vitals-main
    .arrow.left-expanded-view
    .left-panel
    label,
.vitals-main
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    label,
#vpp_footer_container
    .footer-flex
    #carousel-container
    .arrow.left-expanded-view
    .left-panel
    label,
#carousel-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    label,
#vpp_footer_container
    .footer-flex
    #carousel-container
    .left-expanded-view.arrow-expanded
    .left-panel
    label,
#carousel-container
    #vpp_footer_container
    .footer-flex
    .left-expanded-view.arrow-expanded
    .left-panel
    label,
#vpp_footer_container
    .footer-flex
    #vpp-ops-overview-graph-container
    .arrow.left-expanded-view
    .left-panel
    label,
#vpp-ops-overview-graph-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    label,
#vpp_footer_container
    .footer-flex
    #vitals-health-container
    .arrow.left-expanded-view
    .left-panel
    label,
#vitals-health-container
    #vpp_footer_container
    .footer-flex
    .arrow.left-expanded-view
    .left-panel
    label {
    font-size: 11px;
    font-weight: 100;
}
#vpp_footer_container .footer-flex .right,
#vpp_footer_container .footer-flex .vitals-main .arrow.right-expanded-view,
.vitals-main #vpp_footer_container .footer-flex .arrow.right-expanded-view,
#vpp_footer_container
    .footer-flex
    #carousel-container
    .arrow.right-expanded-view,
#carousel-container
    #vpp_footer_container
    .footer-flex
    .arrow.right-expanded-view,
#vpp_footer_container
    .footer-flex
    #carousel-container
    .right-expanded-view.arrow-expanded,
#carousel-container
    #vpp_footer_container
    .footer-flex
    .right-expanded-view.arrow-expanded,
#vpp_footer_container
    .footer-flex
    #vpp-ops-overview-graph-container
    .arrow.right-expanded-view,
#vpp-ops-overview-graph-container
    #vpp_footer_container
    .footer-flex
    .arrow.right-expanded-view,
#vpp_footer_container
    .footer-flex
    #vitals-health-container
    .arrow.right-expanded-view,
#vitals-health-container
    #vpp_footer_container
    .footer-flex
    .arrow.right-expanded-view {
    -webkit-box-flex: 1;
    /* OLD - iOS 6-, Safari 3.1-6 */
    -moz-box-flex: 1;
    /* OLD - Firefox 19- */
    -webkit-flex: 1;
    /* Chrome */
    -ms-flex: 1;
    flex: 1;
}
#vpp_footer_container .footer-flex select {
    -webkit-appearance: none;
    border-radius: 4px;
    border: 1px solid #58788b;
    box-sizing: border-box;
    color: #fff;
    background-color: #2f3e4c;
    outline: none;
    font-size: 12px;
}
#vpp_footer_container .footer-flex input[type="text"] {
    background-image: url("../img/icons/search.png");
    background-repeat: no-repeat;
    background-position: 2.5px 7px;
    border-radius: 4px;
    border: 1px solid #58788b;
    box-sizing: border-box;
    color: #fff;
    background-color: #2f3e4c;
    padding-left: 2.3em;
    outline: none;
    font-size: 10px;
}
#vpp_footer_container .view-selector {
    padding-top: 0.4%;
    text-align: right;
}
#vpp_footer_container .view-selector:hover {
    background-color: #364956;
}
#vpp_footer_container .view-selector .list,
#vpp_footer_container .view-selector .grid,
#vpp_footer_container .view-selector .upload,
#vpp_footer_container .view-selector .expander,
#vpp_footer_container .view-selector .detail,
#vpp_footer_container .view-selector .retract,
#vpp_footer_container .view-selector .export-excel,
#vpp_footer_container .view-selector .plus,
#vpp_footer_container .view-selector .minus,
#vpp_footer_container .view-selector .configuration,
#vpp_footer_container .view-selector .setpoint {
    background-color: #1f2a31;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
    box-sizing: border-box;
    display: inline-block;
    width: 36px;
    height: 33px;
    border: 1px solid #334550;
}
#vpp_footer_container .view-selector .list {
    background-image: url("../img/icons/list-off.png");
    margin-left: -3px;
}
#vpp_footer_container .view-selector .list.on {
    background-color: #4dc2c6;
    background-image: url("../img/icons/list-on.png");
}
#vpp_footer_container .view-selector .list:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#vpp_footer_container .view-selector .grid {
    background-image: url("../img/icons/grid-off.png");
    border-radius: 4px 0 0 4px;
}
#vpp_footer_container .view-selector .grid.on {
    background-color: #4dc2c6;
    background-image: url("../img/icons/grid-on.png");
}
#vpp_footer_container .view-selector .grid:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#vpp_footer_container .view-selector .upload {
    margin-right: 1em;
    margin-left: -0.4em;
    border-radius: 0 4px 4px 0;
    background-image: url("../img/icons/Camera-Grey.png");
    cursor: default;
}
#vpp_footer_container .view-selector .upload.on {
    background-image: url("../img/icons/Camera-White.png");
    cursor: pointer;
}
#vpp_footer_container .view-selector .upload:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#vpp_footer_container .view-selector .detail {
    border-radius: 0 4px 4px 0;
    margin-right: 1em;
    margin-left: -4px;
    background-image: url("../img/icons/Detailed-List-Off.png");
}
#vpp_footer_container .view-selector .detail.on {
    background-image: url("../img/icons/Detailed-List-On.png");
    background-color: #4dc2c6;
}
#vpp_footer_container .view-selector .detail:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#vpp_footer_container .view-selector .expander {
    border-radius: 4px 0 0 4px;
    margin-right: 2px;
    background-image: url("../img/icons/Open-Full-Screen-Grey.png");
}
#vpp_footer_container .view-selector .expander.on {
    background-image: url("../img/icons/Close-Full-Screen-Grey.png");
}
#vpp_footer_container .view-selector .expander:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#vpp_footer_container .view-selector .retract {
    border-radius: 0 4px 4px 0;
    margin-left: -0.4em;
    background-image: url("../img/icons/Show-Down.png");
}
#vpp_footer_container .view-selector .retract.on {
    background-image: url("../img/icons/Show-Up.png");
    cursor: pointer;
}
#vpp_footer_container .view-selector .retract:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#vpp_footer_container .view-selector .export-excel {
    border-radius: 4px 0 0 4px;
    margin-right: 2px;
    background-image: url("../img/icons/Excel-off.png");
    cursor: default;
}
#vpp_footer_container .view-selector .export-excel.on {
    background-image: url("../img/icons/Excel-on.png");
    cursor: pointer;
}
#vpp_footer_container .view-selector .export-excel:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#vpp_footer_container .view-selector .plus {
    width: 36px;
    margin-right: -10px;
    background-color: inherit;
    background-image: url("../img/icons/plus-off.png");
    cursor: default;
}
#vpp_footer_container .view-selector .plus.on {
    background-image: url("../img/icons/plus-on.png");
    cursor: pointer;
}
#vpp_footer_container .view-selector .minus {
    width: 36px;
    margin-right: -10px;
    background-color: inherit;
    background-image: url("../img/icons/minus-off.png");
    cursor: default;
}
#vpp_footer_container .view-selector .minus.on {
    background-image: url("../img/icons/minus-on.png");
    cursor: pointer;
}
#vpp_footer_container .view-selector .configuration {
    width: 36px;
    margin-right: -10px;
    background-color: inherit;
    background-image: url("../img/icons/configuration-off.png");
    cursor: default;
}
#vpp_footer_container .view-selector .configuration.on {
    background-image: url("../img/icons/configuration-on.png");
    cursor: pointer;
}
#vpp_footer_container .view-selector .setpoint {
    width: 36px;
    margin-right: -10px;
    background-color: inherit;
    background-image: url("../img/icons/setpoint-off.png");
    cursor: default;
}
#vpp_footer_container .view-selector .setpoint.on {
    background-image: url("../img/icons/setpoint-on.png");
    cursor: pointer;
}
#vpp_footer_container .hide-arrow-selector {
    background-color: #23aa80;
    background-image: url("../img/dashboard/hide-arrows.png");
    background-repeat: no-repeat;
    background-position: 70px center;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    float: right;
    padding: 7px 51px 7px 10px;
    margin: 12px 12px 0 0;
}
#vpp_footer_container .hide-arrow-selector.show-bg {
    background-image: url("../img/dashboard/show-arrows.png");
}
#vpp_footer_container select {
    -webkit-appearance: none;
    border-radius: 4px;
    border: 1px solid #58788b;
    box-sizing: border-box;
    color: #fff;
    background-color: #2f3e4c;
    outline: none;
    height: 2.3em;
}
#vpp_footer_container input[type="text"] {
    display: inline-block;
    background-image: url("../img/icons/search.png");
    background-repeat: no-repeat;
    background-position: 0 4px;
    border-radius: 4px;
    border: 1px solid #58788b;
    box-sizing: border-box;
    color: #fff;
    background-color: #2f3e4c;
    padding-left: 2.3em;
    outline: none;
    height: 2.9em;
    font-size: 11px;
}
#vpp_footer_container .all-sites,
#vpp_footer_container .all-sites-small {
    background-color: #3d5260;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3.8em;
}
#vpp_footer_container .all-sites h2,
#vpp_footer_container .all-sites-small h2 {
    color: #31d7d9;
    font-weight: 100;
    margin-left: 40px;
}
#vpp_footer_container .all-sites .nav,
#vpp_footer_container .all-sites-small .nav {
    float: right;
    margin-right: 45px;
}
#vpp_footer_container .all-sites .nav .right,
#vpp_footer_container .all-sites-small .nav .right,
#vpp_footer_container .all-sites .nav .vitals-main .arrow.right-expanded-view,
.vitals-main #vpp_footer_container .all-sites .nav .arrow.right-expanded-view,
#vpp_footer_container
    .all-sites-small
    .nav
    .vitals-main
    .arrow.right-expanded-view,
.vitals-main
    #vpp_footer_container
    .all-sites-small
    .nav
    .arrow.right-expanded-view,
#vpp_footer_container
    .all-sites
    .nav
    #carousel-container
    .arrow.right-expanded-view,
#carousel-container
    #vpp_footer_container
    .all-sites
    .nav
    .arrow.right-expanded-view,
#vpp_footer_container
    .all-sites-small
    .nav
    #carousel-container
    .arrow.right-expanded-view,
#carousel-container
    #vpp_footer_container
    .all-sites-small
    .nav
    .arrow.right-expanded-view,
#vpp_footer_container
    .all-sites
    .nav
    #carousel-container
    .right-expanded-view.arrow-expanded,
#carousel-container
    #vpp_footer_container
    .all-sites
    .nav
    .right-expanded-view.arrow-expanded,
#vpp_footer_container
    .all-sites-small
    .nav
    #carousel-container
    .right-expanded-view.arrow-expanded,
#carousel-container
    #vpp_footer_container
    .all-sites-small
    .nav
    .right-expanded-view.arrow-expanded,
#vpp_footer_container
    .all-sites
    .nav
    #vpp-ops-overview-graph-container
    .arrow.right-expanded-view,
#vpp-ops-overview-graph-container
    #vpp_footer_container
    .all-sites
    .nav
    .arrow.right-expanded-view,
#vpp_footer_container
    .all-sites-small
    .nav
    #vpp-ops-overview-graph-container
    .arrow.right-expanded-view,
#vpp-ops-overview-graph-container
    #vpp_footer_container
    .all-sites-small
    .nav
    .arrow.right-expanded-view,
#vpp_footer_container
    .all-sites
    .nav
    #vitals-health-container
    .arrow.right-expanded-view,
#vitals-health-container
    #vpp_footer_container
    .all-sites
    .nav
    .arrow.right-expanded-view,
#vpp_footer_container
    .all-sites-small
    .nav
    #vitals-health-container
    .arrow.right-expanded-view,
#vitals-health-container
    #vpp_footer_container
    .all-sites-small
    .nav
    .arrow.right-expanded-view {
    margin-left: 48px;
}
#vpp_footer_container .all-sites .nav img,
#vpp_footer_container .all-sites-small .nav img {
    cursor: pointer;
}
#vpp_footer_container .all-sites .view-selector,
#vpp_footer_container .all-sites-small .view-selector {
    margin-top: 0.9em;
    text-align: right;
}
#vpp_footer_container .all-sites .view-selector .list,
#vpp_footer_container .all-sites-small .view-selector .list,
#vpp_footer_container .all-sites .view-selector .grid,
#vpp_footer_container .all-sites-small .view-selector .grid,
#vpp_footer_container .all-sites .view-selector .upload,
#vpp_footer_container .all-sites-small .view-selector .upload,
#vpp_footer_container .all-sites .view-selector .expander,
#vpp_footer_container .all-sites-small .view-selector .expander,
#vpp_footer_container .all-sites .view-selector .detail,
#vpp_footer_container .all-sites-small .view-selector .detail,
#vpp_footer_container .all-sites .view-selector .retract,
#vpp_footer_container .all-sites-small .view-selector .retract,
#vpp_footer_container .all-sites .view-selector .export-excel,
#vpp_footer_container .all-sites-small .view-selector .export-excel,
#vpp_footer_container .all-sites .view-selector .plus,
#vpp_footer_container .all-sites-small .view-selector .plus,
#vpp_footer_container .all-sites .view-selector .minus,
#vpp_footer_container .all-sites-small .view-selector .minus,
#vpp_footer_container .all-sites .view-selector .configuration,
#vpp_footer_container .all-sites-small .view-selector .configuration,
#vpp_footer_container .all-sites .view-selector .setpoint,
#vpp_footer_container .all-sites-small .view-selector .setpoint {
    background-color: #1f2a31;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
    box-sizing: border-box;
    display: inline-block;
    width: 36px;
    height: 33px;
    border: 1px solid #334550;
}
#vpp_footer_container .all-sites .view-selector .list,
#vpp_footer_container .all-sites-small .view-selector .list {
    background-image: url("../img/icons/list-off.png");
    margin-left: -3px;
}
#vpp_footer_container .all-sites .view-selector .list.on,
#vpp_footer_container .all-sites-small .view-selector .list.on {
    background-color: #4dc2c6;
    background-image: url("../img/icons/list-on.png");
}
#vpp_footer_container .all-sites .view-selector .list:hover,
#vpp_footer_container .all-sites-small .view-selector .list:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#vpp_footer_container .all-sites .view-selector .grid,
#vpp_footer_container .all-sites-small .view-selector .grid {
    background-image: url("../img/icons/grid-off.png");
    border-radius: 4px 0 0 4px;
}
#vpp_footer_container .all-sites .view-selector .grid.on,
#vpp_footer_container .all-sites-small .view-selector .grid.on {
    background-color: #4dc2c6;
    background-image: url("../img/icons/grid-on.png");
}
#vpp_footer_container .all-sites .view-selector .grid:hover,
#vpp_footer_container .all-sites-small .view-selector .grid:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#vpp_footer_container .all-sites .view-selector .upload,
#vpp_footer_container .all-sites-small .view-selector .upload {
    margin-right: 1em;
    margin-left: -0.4em;
    border-radius: 0 4px 4px 0;
    background-image: url("../img/icons/Camera-Grey.png");
    cursor: default;
}
#vpp_footer_container .all-sites .view-selector .upload.on,
#vpp_footer_container .all-sites-small .view-selector .upload.on {
    background-image: url("../img/icons/Camera-White.png");
    cursor: pointer;
}
#vpp_footer_container .all-sites .view-selector .upload:hover,
#vpp_footer_container .all-sites-small .view-selector .upload:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#vpp_footer_container .all-sites .view-selector .detail,
#vpp_footer_container .all-sites-small .view-selector .detail {
    border-radius: 0 4px 4px 0;
    margin-right: 1em;
    margin-left: -4px;
    background-image: url("../img/icons/Detailed-List-Off.png");
}
#vpp_footer_container .all-sites .view-selector .detail.on,
#vpp_footer_container .all-sites-small .view-selector .detail.on {
    background-image: url("../img/icons/Detailed-List-On.png");
    background-color: #4dc2c6;
}
#vpp_footer_container .all-sites .view-selector .detail:hover,
#vpp_footer_container .all-sites-small .view-selector .detail:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#vpp_footer_container .all-sites .view-selector .expander,
#vpp_footer_container .all-sites-small .view-selector .expander {
    border-radius: 4px 0 0 4px;
    margin-right: 2px;
    background-image: url("../img/icons/Open-Full-Screen-Grey.png");
}
#vpp_footer_container .all-sites .view-selector .expander.on,
#vpp_footer_container .all-sites-small .view-selector .expander.on {
    background-image: url("../img/icons/Close-Full-Screen-Grey.png");
}
#vpp_footer_container .all-sites .view-selector .expander:hover,
#vpp_footer_container .all-sites-small .view-selector .expander:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#vpp_footer_container .all-sites .view-selector .retract,
#vpp_footer_container .all-sites-small .view-selector .retract {
    border-radius: 0 4px 4px 0;
    margin-left: -0.4em;
    background-image: url("../img/icons/Show-Up.png");
}
#vpp_footer_container .all-sites .view-selector .retract.on,
#vpp_footer_container .all-sites-small .view-selector .retract.on {
    background-image: url("../img/icons/Show-Down.png");
    cursor: pointer;
}
#vpp_footer_container .all-sites .view-selector .retract:hover,
#vpp_footer_container .all-sites-small .view-selector .retract:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#vpp_footer_container .all-sites .view-selector .export-excel,
#vpp_footer_container .all-sites-small .view-selector .export-excel {
    border-radius: 4px 0 0 4px;
    margin-right: 2px;
    background-image: url("../img/icons/Excel-off.png");
    cursor: default;
}
#vpp_footer_container .all-sites .view-selector .export-excel.on,
#vpp_footer_container .all-sites-small .view-selector .export-excel.on {
    background-image: url("../img/icons/Excel-on.png");
    cursor: pointer;
}
#vpp_footer_container .all-sites .view-selector .export-excel:hover,
#vpp_footer_container .all-sites-small .view-selector .export-excel:hover {
    border: 1px solid white;
    border-radius: 1px;
}
#vpp_footer_container .all-sites .view-selector .plus,
#vpp_footer_container .all-sites-small .view-selector .plus {
    width: 36px;
    margin-right: -10px;
    background-color: inherit;
    background-image: url("../img/icons/plus-off.png");
    cursor: default;
}
#vpp_footer_container .all-sites .view-selector .plus.on,
#vpp_footer_container .all-sites-small .view-selector .plus.on {
    background-image: url("../img/icons/plus-on.png");
    cursor: pointer;
}
#vpp_footer_container .all-sites .view-selector .minus,
#vpp_footer_container .all-sites-small .view-selector .minus {
    width: 36px;
    margin-right: -10px;
    background-color: inherit;
    background-image: url("../img/icons/minus-off.png");
    cursor: default;
}
#vpp_footer_container .all-sites .view-selector .minus.on,
#vpp_footer_container .all-sites-small .view-selector .minus.on {
    background-image: url("../img/icons/minus-on.png");
    cursor: pointer;
}
#vpp_footer_container .all-sites .view-selector .configuration,
#vpp_footer_container .all-sites-small .view-selector .configuration {
    width: 36px;
    margin-right: -10px;
    background-color: inherit;
    background-image: url("../img/icons/configuration-off.png");
    cursor: default;
}
#vpp_footer_container .all-sites .view-selector .configuration.on,
#vpp_footer_container .all-sites-small .view-selector .configuration.on {
    background-image: url("../img/icons/configuration-on.png");
    cursor: pointer;
}
#vpp_footer_container .all-sites .view-selector .setpoint,
#vpp_footer_container .all-sites-small .view-selector .setpoint {
    width: 36px;
    margin-right: -10px;
    background-color: inherit;
    background-image: url("../img/icons/setpoint-off.png");
    cursor: default;
}
#vpp_footer_container .all-sites .view-selector .setpoint.on,
#vpp_footer_container .all-sites-small .view-selector .setpoint.on {
    background-image: url("../img/icons/setpoint-on.png");
    cursor: pointer;
}
#vpp_footer_container .all-sites .hide-arrow-selector,
#vpp_footer_container .all-sites-small .hide-arrow-selector {
    background-color: #23aa80;
    background-image: url("../img/dashboard/hide-arrows.png");
    background-repeat: no-repeat;
    background-position: 70px center;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    float: right;
    padding: 7px 51px 7px 10px;
    margin: 12px 12px 0 0;
}
#vpp_footer_container .all-sites .hide-arrow-selector.show-bg,
#vpp_footer_container .all-sites-small .hide-arrow-selector.show-bg {
    background-image: url("../img/dashboard/show-arrows.png");
}
#vpp_footer_container .all-sites-small {
    height: 5.3em;
}
#vpp_footer_container .all-sites-small .view-selector {
    margin-top: 0;
    margin-left: 1.7em;
    text-align: right;
}
#vpp_footer_container .upload-container .upload {
    background-color: #4dc2c6;
    background-image: url("../img/icons/upload.png");
}
#vpp_footer_container .sites-detail-list {
    overflow-y: auto;
    position: relative;
    padding: 0 1px;
    transition: height 500ms ease;
    -ms-transition: height 500ms ease;
    -moz-transition: height 500ms ease;
}
#vpp_footer_container .sites-detail-list.hidden {
    height: 0;
}
#vpp_footer_container .sites-table-container {
    overflow-y: auto;
    position: relative;
    transition: height 500ms ease;
    -ms-transition: height 500ms ease;
    -moz-transition: height 500ms ease;
}
#vpp_footer_container .sites-table-container.hidden {
    height: 0;
}
#vpp_footer_container .sites-table-container .grid-height {
    height: 335px;
}
#vpp_footer_container .sites-table-container .largeGrid {
    height: 550px;
}
#vpp_footer_container .sites-table-container .smallGrid {
    height: 300px;
}
#vpp_footer_container .sites-table-container .battery,
#vpp_footer_container .sites-table-container .total-savings {
    text-align: center;
    font-size: 1.6rem;
    font-weight: 400;
}
#vpp_footer_container .sites-table-container .site-info,
#vpp_footer_container
    .sites-table-container
    #billing_container
    .billing-table-container
    .site-info-medium,
#billing_container
    .billing-table-container
    #vpp_footer_container
    .sites-table-container
    .site-info-medium {
    width: 100%;
    display: inline-block;
    vertical-align: top;
}
#vpp_footer_container .sites-table-container .site-info .name,
#vpp_footer_container
    .sites-table-container
    #billing_container
    .billing-table-container
    .site-info-medium
    .name,
#billing_container
    .billing-table-container
    #vpp_footer_container
    .sites-table-container
    .site-info-medium
    .name {
    font-size: 1.6rem;
}
#vpp_footer_container .sites-table-container .site-info h1,
#vpp_footer_container
    .sites-table-container
    #billing_container
    .billing-table-container
    .site-info-medium
    h1,
#billing_container
    .billing-table-container
    #vpp_footer_container
    .sites-table-container
    .site-info-medium
    h1 {
    font-size: 16px;
    margin-bottom: 0;
}
#vpp_footer_container .sites-table-container .site-info p,
#vpp_footer_container
    .sites-table-container
    #billing_container
    .billing-table-container
    .site-info-medium
    p,
#billing_container
    .billing-table-container
    #vpp_footer_container
    .sites-table-container
    .site-info-medium
    p {
    margin: 0;
}
#vpp_footer_container .sites-table-container .site-image {
    width: 100%;
    height: 100%;
}
#vpp_footer_container .sites-table-container .sites-container-list {
    background-color: #f1f6f6;
    border-spacing: 0;
    color: #3e7075;
    position: relative;
    width: 100%;
    border-collapse: collapse;
}
#vpp_footer_container
    .sites-table-container
    .sites-container-list
    .current_site {
    color: #ffffff !important;
    background-color: #32cc8d !important;
}
#vpp_footer_container .sites-table-container .sites-container-list .site {
    cursor: pointer;
}
#vpp_footer_container .sites-table-container .sites-container-list thead {
    width: 100%;
    height: 100px;
    font-size: 12px;
    font-weight: 600;
}
#vpp_footer_container .sites-table-container .sites-container-list thead tr {
    background: #3cd3d5;
    color: #ffffff;
}
#vpp_footer_container .sites-table-container .sites-container-list thead tr th {
    color: #ffffff;
    border-bottom: 1px solid #d5e1e1;
    border-right: 1px solid #dae5e5;
    padding: 17px 32px 17px 12px;
    position: relative;
    white-space: nowrap;
    font-size: 1.2em;
    font-weight: 500;
}
#vpp_footer_container
    .sites-table-container
    .sites-container-list
    thead
    tr
    th:last-child {
    border-right: none;
}
#vpp_footer_container
    .sites-table-container
    .sites-container-list
    .__sort-true {
    background: no-repeat right center url("../img/icons/sort_desc.gif");
}
#vpp_footer_container
    .sites-table-container
    .sites-container-list
    .__sort-false {
    background: no-repeat right center url("../img/icons/sort_asc.gif");
    background-color: #3cd3d5;
}
#vpp_footer_container .sites-container-carousel,
#vpp_footer_container .sites-container-carousel-large {
    padding: 20px 35px;
    position: relative;
    overflow-x: hidden;
    background-color: #3d5260;
}
#vpp_footer_container .sites-container-carousel.hidden,
#vpp_footer_container .hidden.sites-container-carousel-large {
    height: 0;
}

/* ----------- 1920x1080 ----------- */
@media only screen and (min-device-width: 1920px) and (min-device-height: 1080px) {
    #vpp_footer_container .sites-table-container .grid-height {
        height: 905px;
    }
    #vpp_footer_container .sites-table-container .largeGrid {
        height: 700px;
    }
    #vpp_footer_container .sites-table-container .smallGrid {
        height: 250px;
    }
}
/* ----------- standard monitor size 1366x768 ----------- */
@media only screen and (max-width: 1366px) {
    #vpp_footer_container .sites-table-container .grid-height {
        height: 300px;
    }
    #vpp_footer_container .sites-table-container .largeGrid {
        height: 500px;
    }
    #vpp_footer_container .sites-table-container .smallGrid {
        height: 280px;
    }
}
/* ----------- standard monitor size 1280x800 ----------- */
@media only screen and (max-width: 1280px) {
    #vpp_footer_container .sites-table-container .grid-height {
        height: 315px;
    }
    #vpp_footer_container .sites-table-container .largeGrid {
        height: 520px;
    }
    #vpp_footer_container .sites-table-container .smallGrid {
        height: 300px;
    }
}
/* #vpp-header-container .portfolio-icons-container {
  display: flex;
  justify-content: flex-end;
}
#vpp-header-container .portfolio-icons-container .portfolio-icon {
  flex: 1 0 99px;
}
#vpp-header-container .project {
  background-image: url("../img/icons/project_icon.png");
  background-repeat: no-repeat;
  background-position: center center;
  width: 99px;
  height: 44px;
}
#vpp-header-container .project.active, #vpp-header-container .project:hover {
  background-image: url("../img/icons/project_icon_white.png");
  background-color: #3cd3d5;
}
#vpp-header-container .site {
  background-image: url("../img/icons/site_icon.png");
  background-repeat: no-repeat;
  background-position: center center;
  width: 99px;
  height: 44px;
}
#vpp-header-container .site.active, #vpp-header-container .site:hover {
  background-image: url("../img/icons/site_icon_white.png");
  background-color: #3cd3d5;
}
#vpp-header-container .meter {
  float: left;
  background-image: url("../img/icons/meter_icon.png");
  background-repeat: no-repeat;
  background-position: center center;
  width: 99px;
  height: 44px;
}
#vpp-header-container .meter.active, #vpp-header-container .meter:hover {
  background-image: url("../img/icons/meter_icon_white.png");
  background-color: #3cd3d5;
}
#vpp-header-container select#soflow, #vpp-header-container select#soflow-color {
  -webkit-appearance: button;
  -webkit-border-radius: 2px;
  -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  -webkit-padding-end: 20px;
  -webkit-padding-start: 2px;
  -webkit-user-select: none;
  background-image: url("../img/header/white_downpointing_triangle.png"), -webkit-linear-gradient(#FAFAFA, #F4F4F4 40%, #E5E5E5);
  background-position: 97% center;
  background-repeat: no-repeat;
  border: 0px solid #AAA;
  background-color: #3cd3d5;
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 214px;
  height: 1.8em;
  padding: 0;
}
#vpp-header-container select#soflow-color {
  color: #fff;
  background-image: url("../img/header/white_downpointing_triangle.png");
  background-color: #3cd3d5;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
#vpp-header-container .status_bar-small {
  height: 2.5em;
}
*/

/* #vpp-header-container .vpp-header {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  background: #3cd3d5;
  /* Old browsers * /
  background: -moz-linear-gradient(top, #3cd3d5 0%, #31c5c8 100%);
  /* FF3.6+ * /
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #3cd3d5), color-stop(100%, #31c5c8));
  /* Chrome,Safari4+ * /
  background: -webkit-linear-gradient(top, #3cd3d5 0%, #31c5c8 100%);
  /* Chrome10+,Safari5.1+ * /
  background: -o-linear-gradient(top, #3cd3d5 0%, #31c5c8 100%);
  /* Opera 11.10+ * /
  background: -ms-linear-gradient(top, #3cd3d5 0%, #31c5c8 100%);
  /* IE10+ * /
  background: linear-gradient(to bottom, #3cd3d5 0%, #31c5c8 100%);
  /* W3C * /
  -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3cd3d5', endColorstr='#31c5c8', GradientType=0);
  /* IE6-9 * /
  color: #FFFFFF;
  padding: 0 30px;
} */

/*
#vpp-header-container .vpp-header .vpp-header-section {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
#vpp-header-container .vpp-header__logo--full {
  width: 230px;
  height: 70px;
  border-right: 1px solid #5fd9db;
}
#vpp-header-container .vpp-header__logo--truncated {
  display: none;
  margin-top: 20px;
}
#vpp-header-container .vpp-header .vpp-header-item {
  border-right: 1px solid #5fd9db;
  font-size: 12px;
  text-align: center;
  width: 100px;
  height: 70px;
}
#vpp-header-container .vpp-header .vpp-header-item .header-title {
  height: 100%;
}
#vpp-header-container .vpp-header .vpp-header-item .header-title.active, #vpp-header-container .vpp-header .vpp-header-item .header-title:hover {
  background-color: #303e4b;
}
#vpp-header-container .vpp-header .vpp-header-item.active, #vpp-header-container .vpp-header .vpp-header-item:hover {
  background-color: #303e4b;
}
#vpp-header-container .vpp-header .vpp-header-menu {
  background-repeat: no-repeat;
  border-right: 1px solid #5fd9db;
  font-size: 12px;
  text-align: center;
  width: 100px;
  padding-top: 7.7%;
}
#vpp-header-container .vpp-header .overview {
  background-image: url("../img/header/overview-off.png");
  background-position: center 15px;
  background-repeat: no-repeat;
  height: 40px;
}
#vpp-header-container .vpp-header .overview.active, #vpp-header-container .vpp-header .overview:hover {
  background-image: url("../img/header/overview-on.png");
  background-color: #303e4b;
}
#vpp-header-container .vpp-header .vitals {
  background-image: url("../img/header/vitals-off.png");
  background-position: center 12px;
  background-repeat: no-repeat;
  height: 40px;
}
#vpp-header-container .vpp-header .vitals.active, #vpp-header-container .vpp-header .vitals:hover {
  background-image: url("../img/header/vitals-on.png");
  background-color: #303e4b;
}
#vpp-header-container .vpp-header .billing {
  background-position: center 10px;
  background-image: url("../img/header/billing-off.png");
  background-repeat: no-repeat;
  height: 40px;
}
#vpp-header-container .vpp-header .billing.active, #vpp-header-container .vpp-header .billing:hover {
  background-image: url("../img/header/billing-on.png");
  background-color: #303e4b;
}
#vpp-header-container .vpp-header .portfolio {
  background-position: center 10px;
  background-image: url("../img/header/Portfolio-Off.png");
  background-repeat: no-repeat;
  height: 40px;
}
#vpp-header-container .vpp-header .portfolio.active, #vpp-header-container .vpp-header .portfolio:hover {
  background-image: url("../img/header/Portfolio-On.png");
  background-color: #303e4b;
}
#vpp-header-container .vpp-header .analysis {
  background-position: center 10px;
  background-image: url("../img/header/billing-off.png");
  background-repeat: no-repeat;
  height: 40px;
}
#vpp-header-container .vpp-header .analysis.active, #vpp-header-container .vpp-header .analysis:hover {
  background-image: url("../img/header/billing-on.png");
  background-color: #303e4b;
}
#vpp-header-container .vpp-header .support {
  background-position: center 10px;
  background-image: url("../img/header/support-off.png");
  background-repeat: no-repeat;
  height: 40px;
}
#vpp-header-container .vpp-header .support.active, #vpp-header-container .vpp-header .support:hover {
  background-image: url("../img/header/support-on.png");
  background-color: #303e4b;
}
#vpp-header-container .vpp-header__links {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0 0 0 30px;
  vertical-align: top;
}
#vpp-header-container .vpp-header__links li {
  background-repeat: no-repeat;
  border-right: 1px solid #5fd9db;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  height: 60px;
  text-align: center;
  width: 100px;
  margin-left: -4px;
  padding-top: 36px;
}
#vpp-header-container .vpp-header__links li:hover, #vpp-header-container .vpp-header__links li.active {
  background-color: #303e4b;
}
#vpp-header-container .vpp-header__links li:first-child {
  border-left: 1px solid #5fd9db;
  margin-left: 0;
}
#vpp-header-container .vpp-header__links li.overview {
  background-image: url("../img/header/overview-off.png");
  background-position: center 15px;
}
#vpp-header-container .vpp-header__links li.overview.active, #vpp-header-container .vpp-header__links li.overview:hover {
  background-image: url("../img/header/overview-on.png");
}
#vpp-header-container .vpp-header__links li.vitals {
  background-image: url("../img/header/vitals-off.png");
  background-position: center 12px;
}
#vpp-header-container .vpp-header__links li.vitals.active, #vpp-header-container .vpp-header__links li.vitals:hover {
  background-image: url("../img/header/vitals-on.png");
}
#vpp-header-container .vpp-header__links li.billing {
  background-position: center 10px;
  background-image: url("../img/header/billing-off.png");
}
#vpp-header-container .vpp-header__links li.billing.active, #vpp-header-container .vpp-header__links li.billing:hover {
  background-image: url("../img/header/billing-on.png");
}
#vpp-header-container .vpp-header__links li.portfolio {
  background-position: center 10px;
  background-image: url("../img/header/Portfolio-Off.png");
}
#vpp-header-container .vpp-header__links li.portfolio.active, #vpp-header-container .vpp-header__links li.portfolio:hover {
  background-image: url("../img/header/Portfolio-On.png");
}
#vpp-header-container .vpp-header__links li.analysis {
  background-position: center 10px;
  background-image: url("../img/header/billing-off.png");
}
#vpp-header-container .vpp-header__links li.analysis.active, #vpp-header-container .vpp-header__links li.analysis:hover {
  background-image: url("../img/header/billing-on.png");
}
#vpp-header-container .vpp-header__links li.support {
  background-position: center 10px;
  background-image: url("../img/header/support-off.png");
}
#vpp-header-container .vpp-header__links li.support.active, #vpp-header-container .vpp-header__links li.support:hover {
  background-image: url("../img/header/support-on.png");
}
#vpp-header-container .vpp-header__menu {
  border-left: 1px solid #5fd9db;
  font-size: 16px;
  padding: 1.5% 0 0 1.5%;
}
#vpp-header-container .vpp-header__menu:hover {
  background-color: #5fd9db;
}
#vpp-header-container .vpp-header__menu__username {
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
}
#vpp-header-container .vpp-header__menu__email {
  font-size: 12px;
}
#vpp-header-container .vpp-header__menu p {
  margin: 0;
}
#vpp-header-container .vpp-header__menu p a {
  color: #fff;
  text-decoration: none;
}
#vpp-header-container .vpp-header__menu-small {
  border-left: 1px solid #5fd9db;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 16px;
  height: 60px;
  vertical-align: top;
}
#vpp-header-container .vpp-header__menu-small:hover {
  background-color: #5fd9db;
}
#vpp-header-container .vpp-header__menu-small__username {
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
}
#vpp-header-container .vpp-header__menu-small__email {
  font-size: 12px;
}
#vpp-header-container .vpp-header__menu-small p {
  margin: 0;
}
#vpp-header-container .vpp-header__menu-small p a {
  color: #fff;
  text-decoration: none;
}
#vpp-header-container .vpp-header .org-small {
  background-color: #3cd3d5;
}
#vpp-header-container .vpp-header .settings-small {
  background-color: white;
}
 */
#vpp-ops-overview-graph-container #vitals-main-graph {
    height: auto;
    min-height: 215px;
}
#vpp-ops-overview-graph-container .graph-title {
    font-size: 12px;
}
#vpp-ops-overview-graph-container .arrow,
#vpp-ops-overview-graph-container #carousel-container .arrow-expanded,
#carousel-container #vpp-ops-overview-graph-container .arrow-expanded {
    margin-top: 70px;
    background-color: #394b5c;
    background-position: center center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    border: 1px solid #4e6a7b;
    cursor: pointer;
    height: 100px;
    width: 46px;
    z-index: 10;
}
#vpp-ops-overview-graph-container .arrow:hover,
#vpp-ops-overview-graph-container #carousel-container .arrow-expanded:hover,
#carousel-container #vpp-ops-overview-graph-container .arrow-expanded:hover {
    background-color: #4e6a7b;
}
#vpp-ops-overview-graph-container .arrow.left,
#vpp-ops-overview-graph-container #carousel-container .left.arrow-expanded,
#carousel-container #vpp-ops-overview-graph-container .left.arrow-expanded,
#vpp-ops-overview-graph-container .arrow.left-expanded-view,
#vpp-ops-overview-graph-container
    #carousel-container
    .left-expanded-view.arrow-expanded,
#carousel-container
    #vpp-ops-overview-graph-container
    .left-expanded-view.arrow-expanded {
    border-radius: 4px;
    background-image: url("../img/icons/arrow-left.png");
    left: 17px;
    top: 75px;
    width: 20px;
}
#vpp-ops-overview-graph-container .arrow.left-expanded-view,
#vpp-ops-overview-graph-container
    #carousel-container
    .left-expanded-view.arrow-expanded,
#carousel-container
    #vpp-ops-overview-graph-container
    .left-expanded-view.arrow-expanded {
    top: 215px;
}
#vpp-ops-overview-graph-container .arrow.right,
#vpp-ops-overview-graph-container #carousel-container .right.arrow-expanded,
#carousel-container #vpp-ops-overview-graph-container .right.arrow-expanded,
#vpp-ops-overview-graph-container .arrow.right-expanded-view,
#vpp-ops-overview-graph-container
    #carousel-container
    .right-expanded-view.arrow-expanded,
#carousel-container
    #vpp-ops-overview-graph-container
    .right-expanded-view.arrow-expanded {
    border-radius: 4px;
    background-image: url("../img/icons/arrow-right.png");
    top: 75px;
    width: 20px;
}
#vpp-ops-overview-graph-container .arrow.right-expanded-view,
#vpp-ops-overview-graph-container
    #carousel-container
    .right-expanded-view.arrow-expanded,
#carousel-container
    #vpp-ops-overview-graph-container
    .right-expanded-view.arrow-expanded {
    top: 215px;
}

/* ----------- standard monitor size 1920x1080 ----------- */
@media only screen and (min-device-width: 1920px) and (min-device-height: 1080px) {
    #vpp-ops-overview-graph-container #vitals-main-graph {
        min-height: 350px;
    }
    #vpp-ops-overview-graph-container .graph-title {
        font-size: 10px;
    }
}
/* ----------- standard monitor size 1366x768 ----------- */
@media only screen and (max-width: 1366px) {
    #vpp-ops-overview-graph-container #vitals-main-graph {
        min-height: 175px;
    }
}
/* ----------- standard monitor size 1280x800 ----------- */
@media only screen and (max-width: 1280px) {
    #vpp-ops-overview-graph-container #vitals-main-graph {
        min-height: 190px;
    }
}
#vpp-dashboard-stats-container .title,
#vpp-dashboard-stats-container
    #vpp-ops-container
    .right-panel
    .scrollable-panel
    .title,
#vpp-ops-container
    .right-panel
    .scrollable-panel
    #vpp-dashboard-stats-container
    .title {
    color: white;
    font-size: 0.7rem;
    text-transform: uppercase;
}
#vpp-dashboard-stats-container #solar,
#vpp-dashboard-stats-container #storagePower,
#vpp-dashboard-stats-container #storageCapacity,
#vpp-dashboard-stats-container #statusMonitor,
#vpp-dashboard-stats-container #curtailableLoad {
    height: 119px;
}
#vpp-dashboard-stats-container #solar .dashboard-stats-val,
#vpp-dashboard-stats-container #solar .dashboard-stats-measure,
#vpp-dashboard-stats-container #solar .storage-capacity-line-1,
#vpp-dashboard-stats-container #solar .storage-capacity-line-2,
#vpp-dashboard-stats-container #solar .storage-capacity-line-3,
#vpp-dashboard-stats-container #solar .storage-capacity-line-4,
#vpp-dashboard-stats-container #storagePower .dashboard-stats-val,
#vpp-dashboard-stats-container #storagePower .dashboard-stats-measure,
#vpp-dashboard-stats-container #storagePower .storage-capacity-line-1,
#vpp-dashboard-stats-container #storagePower .storage-capacity-line-2,
#vpp-dashboard-stats-container #storagePower .storage-capacity-line-3,
#vpp-dashboard-stats-container #storagePower .storage-capacity-line-4,
#vpp-dashboard-stats-container #storageCapacity .dashboard-stats-val,
#vpp-dashboard-stats-container #storageCapacity .dashboard-stats-measure,
#vpp-dashboard-stats-container #storageCapacity .storage-capacity-line-1,
#vpp-dashboard-stats-container #storageCapacity .storage-capacity-line-2,
#vpp-dashboard-stats-container #storageCapacity .storage-capacity-line-3,
#vpp-dashboard-stats-container #storageCapacity .storage-capacity-line-4,
#vpp-dashboard-stats-container #statusMonitor .dashboard-stats-val,
#vpp-dashboard-stats-container #statusMonitor .dashboard-stats-measure,
#vpp-dashboard-stats-container #statusMonitor .storage-capacity-line-1,
#vpp-dashboard-stats-container #statusMonitor .storage-capacity-line-2,
#vpp-dashboard-stats-container #statusMonitor .storage-capacity-line-3,
#vpp-dashboard-stats-container #statusMonitor .storage-capacity-line-4,
#vpp-dashboard-stats-container #curtailableLoad .dashboard-stats-val,
#vpp-dashboard-stats-container #curtailableLoad .dashboard-stats-measure,
#vpp-dashboard-stats-container #curtailableLoad .storage-capacity-line-1,
#vpp-dashboard-stats-container #curtailableLoad .storage-capacity-line-2,
#vpp-dashboard-stats-container #curtailableLoad .storage-capacity-line-3,
#vpp-dashboard-stats-container #curtailableLoad .storage-capacity-line-4 {
    color: #fff;
    text-align: center;
}
#vpp-dashboard-stats-container #solar .dashboard-stats-val,
#vpp-dashboard-stats-container #storagePower .dashboard-stats-val,
#vpp-dashboard-stats-container #storageCapacity .dashboard-stats-val,
#vpp-dashboard-stats-container #statusMonitor .dashboard-stats-val,
#vpp-dashboard-stats-container #curtailableLoad .dashboard-stats-val {
    font-size: 12px;
    font-weight: 700;
}
#vpp-dashboard-stats-container #solar .dashboard-stats-measure,
#vpp-dashboard-stats-container #storagePower .dashboard-stats-measure,
#vpp-dashboard-stats-container #storageCapacity .dashboard-stats-measure,
#vpp-dashboard-stats-container #statusMonitor .dashboard-stats-measure,
#vpp-dashboard-stats-container #curtailableLoad .dashboard-stats-measure {
    font-size: 10px;
    font-weight: 100;
}
#vpp-dashboard-stats-container #solar .storage-capacity-line-1,
#vpp-dashboard-stats-container #storagePower .storage-capacity-line-1,
#vpp-dashboard-stats-container #storageCapacity .storage-capacity-line-1,
#vpp-dashboard-stats-container #statusMonitor .storage-capacity-line-1,
#vpp-dashboard-stats-container #curtailableLoad .storage-capacity-line-1 {
    font-size: 11px;
    font-weight: 700;
}
#vpp-dashboard-stats-container #solar .storage-capacity-line-2,
#vpp-dashboard-stats-container #storagePower .storage-capacity-line-2,
#vpp-dashboard-stats-container #storageCapacity .storage-capacity-line-2,
#vpp-dashboard-stats-container #statusMonitor .storage-capacity-line-2,
#vpp-dashboard-stats-container #curtailableLoad .storage-capacity-line-2 {
    font-size: 8px;
    font-weight: 100;
}
#vpp-dashboard-stats-container #solar .storage-capacity-line-3,
#vpp-dashboard-stats-container #storagePower .storage-capacity-line-3,
#vpp-dashboard-stats-container #storageCapacity .storage-capacity-line-3,
#vpp-dashboard-stats-container #statusMonitor .storage-capacity-line-3,
#vpp-dashboard-stats-container #curtailableLoad .storage-capacity-line-3 {
    font-size: 11px;
    font-weight: 700;
}
#vpp-dashboard-stats-container #solar .storage-capacity-line-4,
#vpp-dashboard-stats-container #storagePower .storage-capacity-line-4,
#vpp-dashboard-stats-container #storageCapacity .storage-capacity-line-4,
#vpp-dashboard-stats-container #statusMonitor .storage-capacity-line-4,
#vpp-dashboard-stats-container #curtailableLoad .storage-capacity-line-4 {
    font-size: 7px;
    font-weight: 100;
}
#vpp-dashboard-stats-container .db-flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
}
#vpp-dashboard-stats-container .db-flex .item {
    margin-right: 1%;
    -webkit-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    width: 25%;
}
#vpp-dashboard-stats-container .db-flex .item:nth-child(4) {
    margin-right: 0;
}
#vpp-dashboard-stats-container .stats-box {
    background-color: #2f3e4c;
    border-right: 0.1px solid #4d6573;
}

/* ----------- 1920x1080 ----------- */
@media only screen and (min-device-width: 1920px) and (min-device-height: 1080px) {
    #vpp-dashboard-stats-container #solar,
    #vpp-dashboard-stats-container #storagePower,
    #vpp-dashboard-stats-container #storageCapacity,
    #vpp-dashboard-stats-container #statusMonitor,
    #vpp-dashboard-stats-container #curtailableLoad {
        height: 190px;
    }
    #vpp-dashboard-stats-container .db-flex .item {
        margin-right: 0.1%;
    }

    #dashboard-stats-container .title,
    #dashboard-stats-container
        #vpp-ops-container
        .right-panel
        .scrollable-panel
        .title,
    #vpp-ops-container
        .right-panel
        .scrollable-panel
        #dashboard-stats-container
        .title {
        font-size: 12px;
    }
}
/* ----------- standard monitor size 1366x768 ----------- */
@media only screen and (max-width: 1366px) {
    #vpp-dashboard-stats-container #solar,
    #vpp-dashboard-stats-container #storagePower,
    #vpp-dashboard-stats-container #storageCapacity,
    #vpp-dashboard-stats-container #statusMonitor,
    #vpp-dashboard-stats-container #curtailableLoad {
        height: 105px;
    }
    #vpp-dashboard-stats-container #solar .storage-capacity-line-1,
    #vpp-dashboard-stats-container #storagePower .storage-capacity-line-1,
    #vpp-dashboard-stats-container #storageCapacity .storage-capacity-line-1,
    #vpp-dashboard-stats-container #statusMonitor .storage-capacity-line-1,
    #vpp-dashboard-stats-container #curtailableLoad .storage-capacity-line-1 {
        font-size: 9px;
        font-weight: 700;
    }
    #vpp-dashboard-stats-container #solar .storage-capacity-line-2,
    #vpp-dashboard-stats-container #storagePower .storage-capacity-line-2,
    #vpp-dashboard-stats-container #storageCapacity .storage-capacity-line-2,
    #vpp-dashboard-stats-container #statusMonitor .storage-capacity-line-2,
    #vpp-dashboard-stats-container #curtailableLoad .storage-capacity-line-2 {
        font-size: 6px;
        font-weight: 100;
    }
    #vpp-dashboard-stats-container #solar .storage-capacity-line-3,
    #vpp-dashboard-stats-container #storagePower .storage-capacity-line-3,
    #vpp-dashboard-stats-container #storageCapacity .storage-capacity-line-3,
    #vpp-dashboard-stats-container #statusMonitor .storage-capacity-line-3,
    #vpp-dashboard-stats-container #curtailableLoad .storage-capacity-line-3 {
        font-size: 9px;
        font-weight: 700;
    }
    #vpp-dashboard-stats-container #solar .storage-capacity-line-4,
    #vpp-dashboard-stats-container #storagePower .storage-capacity-line-4,
    #vpp-dashboard-stats-container #storageCapacity .storage-capacity-line-4,
    #vpp-dashboard-stats-container #statusMonitor .storage-capacity-line-4,
    #vpp-dashboard-stats-container #curtailableLoad .storage-capacity-line-4 {
        font-size: 5px;
        font-weight: 100;
    }
}
/* ----------- standard monitor size 1280x800 ----------- */
@media only screen and (max-width: 1280px) {
    #vpp-dashboard-stats-container #solar,
    #vpp-dashboard-stats-container #storagePower,
    #vpp-dashboard-stats-container #storageCapacity,
    #vpp-dashboard-stats-container #statusMonitor,
    #vpp-dashboard-stats-container #curtailableLoad {
        height: 112px;
    }
    #vpp-dashboard-stats-container #solar .storage-capacity-line-1,
    #vpp-dashboard-stats-container #storagePower .storage-capacity-line-1,
    #vpp-dashboard-stats-container #storageCapacity .storage-capacity-line-1,
    #vpp-dashboard-stats-container #statusMonitor .storage-capacity-line-1,
    #vpp-dashboard-stats-container #curtailableLoad .storage-capacity-line-1 {
        font-size: 10px;
        font-weight: 700;
    }
    #vpp-dashboard-stats-container #solar .storage-capacity-line-2,
    #vpp-dashboard-stats-container #storagePower .storage-capacity-line-2,
    #vpp-dashboard-stats-container #storageCapacity .storage-capacity-line-2,
    #vpp-dashboard-stats-container #statusMonitor .storage-capacity-line-2,
    #vpp-dashboard-stats-container #curtailableLoad .storage-capacity-line-2 {
        font-size: 7px;
        font-weight: 100;
    }
    #vpp-dashboard-stats-container #solar .storage-capacity-line-3,
    #vpp-dashboard-stats-container #storagePower .storage-capacity-line-3,
    #vpp-dashboard-stats-container #storageCapacity .storage-capacity-line-3,
    #vpp-dashboard-stats-container #statusMonitor .storage-capacity-line-3,
    #vpp-dashboard-stats-container #curtailableLoad .storage-capacity-line-3 {
        font-size: 10px;
        font-weight: 700;
    }
    #vpp-dashboard-stats-container #solar .storage-capacity-line-4,
    #vpp-dashboard-stats-container #storagePower .storage-capacity-line-4,
    #vpp-dashboard-stats-container #storageCapacity .storage-capacity-line-4,
    #vpp-dashboard-stats-container #statusMonitor .storage-capacity-line-4,
    #vpp-dashboard-stats-container #curtailableLoad .storage-capacity-line-4 {
        font-size: 5px;
        font-weight: 100;
    }
}
#vpp-status-bar {
    background-color: #303e4b;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    color: #96b6b6;
    height: 4.6em;
}
#vpp-status-bar--primary {
    color: #3cd3d5;
}
#vpp-status-bar .settings {
    color: #3cd3d5;
}
#vpp-status-bar .portfolio-menus,
#vpp-status-bar .analysis-menus {
    background-repeat: no-repeat;
    background-position: center 5px;
    height: 4.7em;
    width: 99px;
    padding-top: 3em;
    text-align: center;
    font-size: 12px;
    color: #f2f2f2;
}

.analysis-vert-menu-customer {
    background-image: url("../img/icons/nav-customer.svg");
    background-repeat: no-repeat;
    height: 55px;
}
.analysis-vert-menu-customer.active,
.analysis-vert-menu-customer:hover {
    background-image: url("../img/icons/nav-customer-on.svg");
}

.analysis-vert-menu-portfolio {
    background-image: url("../img/icons/nav-portfolio.svg");
    background-repeat: no-repeat;
    height: 55px;
}
.analysis-vert-menu-portfolio.active,
.analysis-vert-menu-portfolio:hover {
    background-image: url("../img/icons/nav-portfolio-on.svg");
}

.analysis-vert-menu-service {
    background-image: url("../img/icons/nav-services.svg");
    background-repeat: no-repeat;
    height: 55px;
}
.analysis-vert-menu-service.active,
.analysis-vert-menu-service:hover {
    background-image: url("../img/icons/nav-services-on.svg");
}

.analysis-vert-menu-data {
    background-image: url("../img/icons/nav-icon-demand-response-on.svg");
    background-repeat: no-repeat;
    height: 55px;
}
.analysis-vert-menu-data.active,
.analysis-vert-menu-data:hover {
    background-image: url("../img/icons/nav-icon-demand-response.svg");
}

.analysis-vert-menu-project {
    background-image: url("../img/icons/nav-project-on.svg");
    background-repeat: no-repeat;
    height: 55px;
}
.analysis-vert-menu-project.active,
.analysis-vert-menu-project:hover {
    background-image: url("../img/icons/nav-project.svg");
}

.analysis-vert-menu-proposal {
    background-image: url("../img/icons/nav-proposal-on.svg");
    background-repeat: no-repeat;
    height: 55px;
}
.analysis-vert-menu-proposal.active,
.analysis-vert-menu-proposal:hover {
    background-image: url("../img/icons/nav-proposal.svg");
}

#sites-view-container .selection-panel {
    height: 640px;
    background-color: #4c657b;
}
#sites-view-container .project-panel {
    height: 1200px;
    background-color: #4c657b;
}
#sites-view-container .details-panel {
    height: 640px;
    overflow-y: scroll;
    font-size: 1.3em;
    background-color: #6e8ba6;
}
#sites-view-container .row-legend {
    height: 50px;
    padding: 10px 0 0 0;
    text-transform: uppercase;
}
#sites-view-container .patient-container {
    width: 100%;
    height: 600px;
    overflow-y: scroll;
    margin-bottom: 100px;
}
#sites-view-container .row-data-site span.dropdown {
    padding-left: 20px;
}
#sites-view-container .row-data-vpp,
#sites-view-container .row-data-site {
    border-radius: 4px;
    height: 30px;
    transition: all 0.2s ease;
}
#sites-view-container .row-data-vpp > div,
#sites-view-container .row-data-site > div {
    line-height: 2em;
    white-space: nowrap;
}
#sites-view-container .row-data-vpp .legend,
#sites-view-container .row-data-site .legend {
    text-transform: uppercase;
    padding: 0 5px 0 0;
    min-width: 56px;
    width: auto;
    float: left;
}
#sites-view-container .row-data-vpp span.dropdown,
#sites-view-container .row-data-site span.dropdown {
    height: 30px;
    padding-top: 6px;
    margin-right: 16px;
    line-height: 0.7em;
    font-size: 1.3em;
    float: left;
}
#sites-view-container .row-data-vpp .label,
#sites-view-container .row-data-site .label {
    margin-top: 2px;
}
#sites-view-container .analysis-sites-view-main .main-nav {
    height: 605px;
    padding-left: 0;
}
#sites-view-container .analysis-sites-view-main .main-nav label {
    color: white;
    font-size: 13px;
    font-weight: 100;
}
#sites-view-container .analysis-sites-view-main .heading {
    margin-right: 0;
    padding-top: 15px;
    height: 55px;
    background-color: #2f3e4c;
    font-size: 1.2em;
    font-weight: 100;
    color: #a4c8c8;
    padding-left: 20px;
    position: relative;
}
#sites-view-container .analysis-sites-view-main .heading.selected,
#sites-view-container .analysis-sites-view-main .heading:hover {
    color: white;
    background-color: black;
    border: 1px solid black;
    border-radius: 0;
}
#sites-view-container .analysis-sites-view-main .heading.selected:after {
    border-style: solid;
    border-width: 6px 0 6px 10px;
    border-color: transparent transparent transparent #000000;
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    right: -5%;
    top: 35%;
    clear: both;
}
#sites-view-container .analysis-sites-view-main .heading-sub-nav {
    color: white;
    font-size: 18px;
    background-color: #42586c;
}
#sites-view-container .analysis-sites-view-main .heading-sub-nav.lg {
    height: 150px;
}
#sites-view-container .analysis-sites-view-main .heading-sub-nav.md {
    height: 100px;
}
#sites-view-container .analysis-sites-view-main .heading-sub-nav.sm {
    height: 60px;
}
#sites-view-container .analysis-sites-view-main .heading-sub-nav .pad-left {
    padding-left: 20%;
}
#sites-view-container .analysis-sites-view-main .content-view {
    border: none;
    background-color: #acb8ba;
}

/* .settings_container {
  margin-top: 5%;
  border-radius: 5px;
}
.settings_container .user_info {
  background-color: #ffffff;
  padding-left: 20px;
  padding-bottom: .5em;
}
.settings_container .user_info .name {
  margin: 5px 0;
  padding: 0;
}
.settings_container .user_info .email {
  margin: 5px 0;
  padding: 0;
}
.settings_container .user_info .organization {
  margin: 5px 0;
  padding: 0;
}
.settings_container .password_info {
  padding-left: 20px;
  padding-right: 20px;
  background-color: white;
}
.settings_container .password_info .password-label {
  color: #3cd3d5;
  font-size: 15px;
}
.settings_container .password_info .password-text {
  font-size: 13px;
  float: right;
  padding-bottom: 10px;
}
.settings_container .password_info input[type=submit], .settings_container .password_info .green-button {
  background-color: #26AC82;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  margin: 60px 0;
  padding: 10px 20px;
  -webkit-appearance: none;
}
.settings_container .password_info input[type=password] {
  border: 1px solid grey;
  border-radius: 5px;
  box-sizing: border-box;
  color: #4d8187;
  display: block;
  font-size: 16px;
  margin: 10px 0;
  outline: none;
  padding: 10px;
  width: 100%;
}
.settings_container .password_info input[type=password]:focus {
  border: 1px solid #26ac82;
}
.settings_container .submit-btn {
  text-align: center;
} */

/* #support_container {
  margin-top: 8%;
  border-radius: 4px;
  background-color: #f4f8f8;
}
#support_container .support {
  margin-left: 0;
  margin-right: 0;
}
#support_container .__text {
  margin-top: .8em;
  color: #4d8187;
  font-size: 15px;
}
#support_container .__text .phone-number {
  font-size: 15px;
  font-weight: 400;
}
#support_container .email-container {
  padding-bottom: 20px;
}
#support_container .email-container .app-version {
  font-weight: 100;
  font-size: 12px;
  float: right;
}
#support_container .email-container .app-note {
  padding-top: 10px;
  padding-bottom: 10px;
}
#support_container .email-container .app-aaa {
  font-weight: 100;
  font-size: 12px;
  float: left;
}
#support_container .email-container .email-us {
  color: #3cd3d5;
  margin-top: .8em;
  margin-bottom: .8em;
  font-weight: 200;
}
#support_container .email-container .subject {
  width: 100%;
  height: 2.3em;
  background-color: #000000;
  color: #ffffff;
  font-size: 1.0em;
  font-weight: 100;
  margin: 0;
  border-radius: 5px;
}
#support_container .email-container input[type=submit], #support_container .email-container .green-button {
  background-color: #26AC82;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  margin-left: 40%;
  padding: 10px 20px;
  -webkit-appearance: none;
}
#support_container .email-container input[type=password], #support_container .email-container input[type=email] {
  border: 1px solid #5fd9db;
  border-radius: 5px;
  box-sizing: border-box;
  color: #4d8187;
  display: block;
  font-size: 13px;
  margin: 10px 0;
  outline: none;
  padding: 10px;
  width: 100%;
}
#support_container .email-container input[type=password]:focus, #support_container .email-container input[type=email]:focus {
  border: 1px solid #26ac82;
}
#support_container .email-container textarea {
  border: 1px solid #5fd9db;
  border-radius: 5px;
  color: #4d8187;
  display: block;
  font-size: 13px;
  margin: 10px 0;
  outline: none;
  padding: 10px;
  width: 100%;
}
#support_container .email-container textarea:focus {
  border: 1px solid #26ac82;
}
#support_container form.ng-submitted .ng-invalid {
  border-color: red;
  border-width: 2px;
}
#support_container .errors {
  color: red;
}
 */
#vitals-alerts-container {
    margin-top: 5%;
    overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
}
#vitals-alerts-container .col {
    height: 550px;
    -webkit-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    background: #2f3e4c;
}
#vitals-alerts-container .col:nth-child(1) {
    width: 48%;
    -webkit-box-ordinal-group: 0;
    -moz-box-ordinal-group: 0;
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
    background: #2f3e4c;
    margin-left: 1%;
    margin-right: 1%;
}
#vitals-alerts-container .col:nth-child(2) {
    width: 19%;
    height: 522px;
    -webkit-box-ordinal-group: 0;
    -moz-box-ordinal-group: 0;
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
    background: #ffffff;
    margin-right: 1%;
}
#vitals-alerts-container .details {
    height: 5%;
    background-color: #cccccc;
    color: #999999;
}
#vitals-alerts-container .info,
#vitals-alerts-container
    #billing_container
    .billing-table-container
    .info-medium,
#billing_container
    .billing-table-container
    #vitals-alerts-container
    .info-medium {
    margin-top: 5%;
    padding: 1%;
    border: 1px solid lightgrey;
    border-radius: 5px;
}
#vitals-alerts-container .info .info-title,
#vitals-alerts-container
    #billing_container
    .billing-table-container
    .info-medium
    .info-title,
#billing_container
    .billing-table-container
    #vitals-alerts-container
    .info-medium
    .info-title {
    color: #999999;
    font-size: 0.9em;
}
#vitals-alerts-container .info .info-val,
#vitals-alerts-container
    #billing_container
    .billing-table-container
    .info-medium
    .info-val,
#billing_container
    .billing-table-container
    #vitals-alerts-container
    .info-medium
    .info-val {
    color: #666666;
    font-size: 1em;
    font-weight: 400;
}
#vitals-alerts-container .grid-height {
    height: 95%;
}
#vitals-alerts-container .total-pad {
    padding-top: 1%;
    padding-left: 5%;
}
#vitals-alerts-container .vpp-alerts-section:nth-child(1) {
    border-radius: 10px 10px 0 0;
}
#vitals-alerts-container .vpp-alerts-section:nth-child(4) {
    border-radius: 0 0 10px 10px;
}

#vitals-health-container {
    margin-top: 5%;
    overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
}
#vitals-health-container .arrow,
#vitals-health-container #carousel-container .arrow-expanded,
#carousel-container #vitals-health-container .arrow-expanded {
    margin-top: 70px;
    background-color: #374955;
    background-position: center center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    border: 1px solid #4e6a7b;
    cursor: pointer;
    height: 100px;
    width: 46px;
    z-index: 10;
}
#vitals-health-container .arrow:hover,
#vitals-health-container #carousel-container .arrow-expanded:hover,
#carousel-container #vitals-health-container .arrow-expanded:hover {
    background-color: #4e6a7b;
}
#vitals-health-container .arrow.left,
#vitals-health-container #carousel-container .left.arrow-expanded,
#carousel-container #vitals-health-container .left.arrow-expanded,
#vitals-health-container .arrow.left-expanded-view,
#vitals-health-container #carousel-container .left-expanded-view.arrow-expanded,
#carousel-container
    #vitals-health-container
    .left-expanded-view.arrow-expanded {
    border-radius: 4px;
    background-image: url("../img/icons/arrow-left.png");
    left: 17px;
    top: 75px;
    width: 20px;
}
#vitals-health-container .arrow.left-expanded-view,
#vitals-health-container #carousel-container .left-expanded-view.arrow-expanded,
#carousel-container
    #vitals-health-container
    .left-expanded-view.arrow-expanded {
    top: 215px;
}
#vitals-health-container .arrow.right,
#vitals-health-container #carousel-container .right.arrow-expanded,
#carousel-container #vitals-health-container .right.arrow-expanded,
#vitals-health-container .arrow.right-expanded-view,
#vitals-health-container
    #carousel-container
    .right-expanded-view.arrow-expanded,
#carousel-container
    #vitals-health-container
    .right-expanded-view.arrow-expanded {
    border-radius: 4px;
    background-image: url("../img/icons/arrow-right.png");
    top: 75px;
    width: 20px;
}
#vitals-health-container .arrow.right-expanded-view,
#vitals-health-container
    #carousel-container
    .right-expanded-view.arrow-expanded,
#carousel-container
    #vitals-health-container
    .right-expanded-view.arrow-expanded {
    top: 215px;
}
#vitals-health-container .health-flex {
    height: 90px;
    margin-top: 3%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
}
#vitals-health-container .health-flex .item {
    margin-top: 2%;
    width: 45%;
    padding: 2%;
    background-color: #374859;
    border-radius: 5px;
    -webkit-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
}
#vitals-health-container .health-flex .item:nth-child(1) {
    margin-right: 2%;
}
#vitals-health-container .health-flex-col {
    margin-top: 5%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-flow: column;
    -ms-flex-flow: column;
    flex-flow: column;
}
#vitals-health-container .health-flex-col .item-col {
    width: 55%;
    height: 15%;
    margin-top: 2%;
    padding: 2%;
    background-color: #3f5365;
    border-radius: 5px;
    -webkit-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
}
#vitals-health-container .health-flex-col .item-col:hover,
#vitals-health-container .health-flex-col .item-col.selected {
    background-color: #607e9b;
}
#vitals-health-container .health-title {
    font-size: 0.9em;
    font-weight: 100;
    color: #8ea4b9;
}
#vitals-health-container .health-val {
    font-size: 2em;
    font-weight: 400;
    color: white;
}
#vitals-health-container .col {
    height: 420px;
    -webkit-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    background: #2f3e4c;
    border-radius: 10px;
}
#vitals-health-container .col:nth-child(1) {
    width: 48%;
    -webkit-box-ordinal-group: 0;
    -moz-box-ordinal-group: 0;
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
    background: #2f3e4c;
    border-radius: 10px;
    margin-top: 1%;
    margin-left: 1%;
    margin-right: 1%;
}
#vitals-health-container .col:nth-child(2) {
    width: 19%;
    -webkit-box-ordinal-group: 0;
    -moz-box-ordinal-group: 0;
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
    background: #2f3e4c;
    margin-right: 1%;
    margin-top: 1%;
    border-radius: 10px;
}
#vitals-health-container .vpp-health-section {
    height: 25%;
    border: 1px solid white;
    background-color: #2f3e4c;
}
#vitals-health-container .selected {
    background-color: #3f5365;
}

#grid-support-container .val {
    font-size: 8px;
}
#grid-support-container .solar {
    display: flex;
    width: 130px;
    padding-left: 19%;
    padding-top: 15%;
}
#grid-support-container .solar .arrow,
#grid-support-container .solar #carousel-container .arrow-expanded,
#carousel-container #grid-support-container .solar .arrow-expanded {
    position: relative;
    width: 130px;
    background-repeat: no-repeat;
}
#grid-support-container .solar .arrow .num,
#grid-support-container .solar #carousel-container .arrow-expanded .num,
#carousel-container #grid-support-container .solar .arrow-expanded .num,
#grid-support-container .solar .arrow .site-details .quad-info .num-medium,
.site-details .quad-info #grid-support-container .solar .arrow .num-medium,
#grid-support-container
    .solar
    #carousel-container
    .arrow-expanded
    .site-details
    .quad-info
    .num-medium,
.site-details
    .quad-info
    #grid-support-container
    .solar
    #carousel-container
    .arrow-expanded
    .num-medium,
#carousel-container
    #grid-support-container
    .solar
    .arrow-expanded
    .site-details
    .quad-info
    .num-medium,
.site-details
    .quad-info
    #carousel-container
    #grid-support-container
    .solar
    .arrow-expanded
    .num-medium {
    position: absolute;
    bottom: 1.4rem;
    left: 1.1rem;
    color: #fff;
    width: 90px;
}
#grid-support-container .solar-img {
    width: 40px;
}
#grid-support-container .utility-img {
    width: 23px;
}
#grid-support-container .controller-img {
    width: 40px;
}
#grid-support-container .storage-img {
    width: 40px;
}
#grid-support-container .utility {
    display: flex;
    width: 55px;
    padding-top: 6%;
}
#grid-support-container .utility .arrow,
#grid-support-container .utility #carousel-container .arrow-expanded,
#carousel-container #grid-support-container .utility .arrow-expanded {
    position: relative;
    width: 130px;
    background-repeat: no-repeat;
}
#grid-support-container .utility .arrow .num,
#grid-support-container .utility #carousel-container .arrow-expanded .num,
#carousel-container #grid-support-container .utility .arrow-expanded .num,
#grid-support-container .utility .arrow .site-details .quad-info .num-medium,
.site-details .quad-info #grid-support-container .utility .arrow .num-medium,
#grid-support-container
    .utility
    #carousel-container
    .arrow-expanded
    .site-details
    .quad-info
    .num-medium,
.site-details
    .quad-info
    #grid-support-container
    .utility
    #carousel-container
    .arrow-expanded
    .num-medium,
#carousel-container
    #grid-support-container
    .utility
    .arrow-expanded
    .site-details
    .quad-info
    .num-medium,
.site-details
    .quad-info
    #carousel-container
    #grid-support-container
    .utility
    .arrow-expanded
    .num-medium {
    position: absolute;
    bottom: 1.1rem;
    left: 0.8rem;
    color: #fff;
    width: 90px;
}
#grid-support-container .building-arrow {
    width: 140px;
    padding-top: 3%;
}
#grid-support-container .load .arrow,
#grid-support-container .load #carousel-container .arrow-expanded,
#carousel-container #grid-support-container .load .arrow-expanded {
    position: relative;
    background: url("../img/icons/icon-grid-support-load.svg");
    width: 40px;
    height: 50px;
    background-repeat: no-repeat;
}
#grid-support-container .load .arrow .num,
#grid-support-container .load #carousel-container .arrow-expanded .num,
#carousel-container #grid-support-container .load .arrow-expanded .num,
#grid-support-container .load .arrow .site-details .quad-info .num-medium,
.site-details .quad-info #grid-support-container .load .arrow .num-medium,
#grid-support-container
    .load
    #carousel-container
    .arrow-expanded
    .site-details
    .quad-info
    .num-medium,
.site-details
    .quad-info
    #grid-support-container
    .load
    #carousel-container
    .arrow-expanded
    .num-medium,
#carousel-container
    #grid-support-container
    .load
    .arrow-expanded
    .site-details
    .quad-info
    .num-medium,
.site-details
    .quad-info
    #carousel-container
    #grid-support-container
    .load
    .arrow-expanded
    .num-medium {
    position: absolute;
    bottom: 1.1rem;
    left: 0.8rem;
    color: #fff;
    width: 90px;
}
#grid-support-container .storage {
    display: flex;
    width: 150px;
    padding-left: 19%;
}
#grid-support-container .storage .arrow,
#grid-support-container .storage #carousel-container .arrow-expanded,
#carousel-container #grid-support-container .storage .arrow-expanded {
    position: relative;
    width: 130px;
    background-repeat: no-repeat;
}
#grid-support-container .storage .arrow .num,
#grid-support-container .storage #carousel-container .arrow-expanded .num,
#carousel-container #grid-support-container .storage .arrow-expanded .num,
#grid-support-container .storage .arrow .site-details .quad-info .num-medium,
.site-details .quad-info #grid-support-container .storage .arrow .num-medium,
#grid-support-container
    .storage
    #carousel-container
    .arrow-expanded
    .site-details
    .quad-info
    .num-medium,
.site-details
    .quad-info
    #grid-support-container
    .storage
    #carousel-container
    .arrow-expanded
    .num-medium,
#carousel-container
    #grid-support-container
    .storage
    .arrow-expanded
    .site-details
    .quad-info
    .num-medium,
.site-details
    .quad-info
    #carousel-container
    #grid-support-container
    .storage
    .arrow-expanded
    .num-medium {
    position: absolute;
    bottom: 0.6rem;
    left: 1.4rem;
    color: #fff;
    width: 90px;
}

@media only screen and (min-device-width: 1920px) and (min-device-height: 1080px) {
    #grid-support-container .solar {
        padding-left: 9%;
    }
    #grid-support-container .storage {
        padding-left: 9%;
    }
    #grid-support-container .storage .arrow .num,
    #grid-support-container .storage #carousel-container .arrow-expanded .num,
    #carousel-container #grid-support-container .storage .arrow-expanded .num,
    #grid-support-container
        .storage
        .arrow
        .site-details
        .quad-info
        .num-medium,
    .site-details
        .quad-info
        #grid-support-container
        .storage
        .arrow
        .num-medium,
    #grid-support-container
        .storage
        #carousel-container
        .arrow-expanded
        .site-details
        .quad-info
        .num-medium,
    .site-details
        .quad-info
        #grid-support-container
        .storage
        #carousel-container
        .arrow-expanded
        .num-medium,
    #carousel-container
        #grid-support-container
        .storage
        .arrow-expanded
        .site-details
        .quad-info
        .num-medium,
    .site-details
        .quad-info
        #carousel-container
        #grid-support-container
        .storage
        .arrow-expanded
        .num-medium {
        bottom: 0.8rem;
    }
    #grid-support-container .building-arrow {
        width: 230px;
    }
}
.gc-tbl-container table {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 335px;
    /* this can vary */
}
.gc-tbl-container .largeTable {
    height: 550px;
}
.gc-tbl-container .smallTable {
    height: 300px;
}
.gc-tbl-container table * {
    box-sizing: inherit;
    -moz-box-sizing: inherit;
}
.gc-tbl-container thead {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: #3cd3d5 !important;
}
.gc-tbl-container thead tr th {
    color: #fff !important;
    font-size: 0.9em !important;
    text-align: center;
    border: 1px solid #ccc;
    font-weight: 400 !important;
}
.gc-tbl-container tbody {
    display: inline-block;
}
.gc-tbl-container tbody tr td {
    border-right: 0.5px solid #ccc;
    font-size: 0.8em !important;
}
.gc-tbl-container tbody tr:nth-child(even) {
    background-color: #f0f8ff;
}
.gc-tbl-container tbody tr:nth-child(odd) {
    background-color: #e6f3ff;
}
.gc-tbl-container thead > tr,
.gc-tbl-container tbody > tr,
.gc-tbl-container tfoot > tr {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.gc-tbl-container thead,
.gc-tbl-container tfoot {
    flex-shrink: 0;
}
.gc-tbl-container th,
.gc-tbl-container tbody td {
    text-overflow: ellipsis;
    display: inline-block;
}
.gc-tbl-container tfoot {
    display: inline-block;
}
.gc-tbl-container tfoot td {
    width: 100%;
    display: inline-block;
}

/* ----------- 1920x1080 ----------- */
/* ----------- standard monitor size 1366x768 ----------- */
/* ----------- standard monitor size 1280x800 ----------- */
#peak-shaving-graph {
    height: auto;
    min-height: 260px;
}

/* ----------- 1920x1080 ----------- */
@media only screen and (min-device-width: 1920px) and (min-device-height: 1080px) {
    #peak-shaving-graph {
        min-height: 300px;
    }
}
/* ----------- standard monitor size 1366x768 ----------- */
@media only screen and (max-width: 1366px) {
    #peak-shaving-graph {
        min-height: 182px;
    }
}
/* ----------- standard monitor size 1280x800 ----------- */
@media only screen and (max-width: 1280px) {
    #peak-shaving-graph {
        min-height: 190px;
    }
}
#vpp-ops-forecast-graph-container {
    background-color: #4d6573;
}

.forecast-menu-item:hover {
    background-color: #6e8ba6;
}

/* ----------- standard monitor size 1920x1080 ----------- */
/* ----------- standard monitor size 1366x768 ----------- */
/* ----------- standard monitor size 1280x800 ----------- */
#site-context-menu-container input[type="submit"] {
    border-radius: 4px;
}

#vpp-configuration-container {
    padding-top: 1%;
}
#vpp-configuration-container .config-label {
    font-size: 1.5rem;
    font-weight: bold;
}
#vpp-configuration-container input[type="text"] {
    font-size: 0.9em;
    height: 2.2em;
    border-radius: 2px;
}

#performance-graph,
#performance-daily-graph {
    height: 100%;
}

.performance-daily-snapshots {
    font-size: 1.2rem;
}

#vpp-ops-Mon-projs-container .analysis-vert-menu-project {
    background-image: url("../img/icons/nav-project.svg");
    background-repeat: no-repeat;
    height: 86px;
}
#vpp-ops-Mon-projs-container .analysis-vert-menu-project.active,
#vpp-ops-Mon-projs-container .analysis-vert-menu-project:hover {
    background-image: url("../img/icons/nav-project-on.svg");
}
#vpp-ops-Mon-projs-container .solar-generation {
    background-image: url("../img/icons/nav-icon-solar.svg");
    background-repeat: no-repeat;
    height: 86px;
}
#vpp-ops-Mon-projs-container .solar-generation.active,
#vpp-ops-Mon-projs-container .solar-generation:hover {
    background-image: url("../img/icons/nav-icon-solar-on.svg");
}
#vpp-ops-Mon-projs-container .demand-shaving {
    background-image: url("../img/icons/nav-icon-peak-mgmt.svg");
    background-repeat: no-repeat;
    height: 86px;
}
#vpp-ops-Mon-projs-container .demand-shaving.active,
#vpp-ops-Mon-projs-container .demand-shaving:hover {
    background-image: url("../img/icons/nav-icon-peak-mgmt-on.svg");
}
#vpp-ops-Mon-projs-container .energy-saving {
    background-image: url("../img/icons/nav-icon-energy-arbitrage.svg");
    background-repeat: no-repeat;
    height: 86px;
}
#vpp-ops-Mon-projs-container .energy-saving.active,
#vpp-ops-Mon-projs-container .energy-saving:hover {
    background-image: url("../img/icons/nav-icon-energy-arbitrage-on.svg");
}
#vpp-ops-Mon-projs-container .energy-arbitrage {
    background-image: url("../img/icons/nav-icon-energy-arbitrage.svg");
    background-repeat: no-repeat;
    height: 86px;
}
#vpp-ops-Mon-projs-container .energy-arbitrage.active,
#vpp-ops-Mon-projs-container .energy-arbitrage:hover {
    background-image: url("../img/icons/nav-icon-energy-arbitrage-on.svg");
}
#vpp-ops-Mon-projs-container .peak-shaving {
    background-image: url("../img/icons/nav-icon-peak-mgmt.svg");
    background-repeat: no-repeat;
    height: 86px;
}
#vpp-ops-Mon-projs-container .peak-shaving.active,
#vpp-ops-Mon-projs-container .peak-shaving:hover {
    background-image: url("../img/icons/nav-icon-peak-mgmt-on.svg");
}
#vpp-ops-Mon-projs-container .demand-response {
    background-image: url("../img/icons/nav-icon-demand-response.svg");
    background-repeat: no-repeat;
    height: 86px;
}
#vpp-ops-Mon-projs-container .demand-response.active,
#vpp-ops-Mon-projs-container .demand-response:hover {
    background-image: url("../img/icons/nav-icon-demand-response-on.svg");
}
#vpp-ops-Mon-projs-container .frequency-regulation {
    background-image: url("../img/icons/nav-icon-freq-reg.svg");
    background-repeat: no-repeat;
    height: 86px;
}
#vpp-ops-Mon-projs-container .frequency-regulation.active,
#vpp-ops-Mon-projs-container .frequency-regulation:hover {
    background-image: url("../img/icons/nav-icon-freq-reg-on.svg");
}
#vpp-ops-Mon-projs-container .graph-print {
    background-image: url("../img/icons/icon-print.svg");
    background-repeat: no-repeat;
    height: 86px;
}
#vpp-ops-Mon-projs-container .graph-print.active,
#vpp-ops-Mon-projs-container .graph-print:hover {
    background-image: url("../img/icons/icon-print-on.svg");
}
#vpp-ops-Mon-projs-container .graph-download {
    background-image: url("../img/icons/icon-export-download.svg");
    background-repeat: no-repeat;
    height: 86px;
}
#vpp-ops-Mon-projs-container .graph-download.active,
#vpp-ops-Mon-projs-container .graph-download:hover {
    background-image: url("../img/icons/icon-export-download-on.svg");
}

.proposal-info {
    background-image: url("../img/icons/proposal-info-off.svg");
    background-repeat: no-repeat;
    height: 55px;
}
.proposal-info.active,
.proposal-info:hover {
    background-image: url("../img/icons/proposal-info-on.svg");
}

.proposals-panel-extended {
    width: 98%;
}

.proposals-panel-condensed {
    width: 73%;
}

.proposals-sb {
    width: 3%;
}

.proposals-details {
    width: 24%;
}

.gnc-select-grid {
    display: inline-block;
    vertical-align: top;
    margin-right: -4px;
}

.resizable {
    position: relative;
}
.resizable.no-transition {
    transition: none !important;
}

.rg-right,
.rg-left,
.rg-top,
.rg-bottom {
    display: block;
    width: 14px;
    height: 14px;
    line-height: 14px;
    position: absolute;
    z-index: 1;
}
.rg-right span,
.rg-left span,
.rg-top span,
.rg-bottom span {
    position: absolute;
    box-sizing: border-box;
    display: block;
    border: 1px solid #ccc;
}

.rg-right span,
.rg-left span {
    border-width: 0 1px;
    top: 50%;
    margin-top: -10px;
    margin: -10px 0 0 3.5px;
    height: 20px;
    width: 7px;
}

.rg-top span,
.rg-bottom span {
    border-width: 1px 0;
    left: 50%;
    margin: 3.5px 0 0 -10px;
    width: 20px;
    height: 7px;
}

.rg-top {
    cursor: row-resize;
    width: 100%;
    top: 0;
    left: 0;
    margin-top: -7px;
}

.rg-right {
    cursor: col-resize;
    height: 100%;
    right: 0;
    top: 0;
    margin-right: -14px;
}

.rg-bottom {
    cursor: row-resize;
    width: 100%;
    bottom: 0;
    left: 0;
    margin-bottom: -7px;
}

.rg-left {
    cursor: col-resize;
    height: 100%;
    left: 0;
    top: 0;
    margin-left: -14px;
}

.sites-table-container .rg-right {
    margin-right: 1px;
    height: 40px;
}
.sites-table-container .rg-right span:hover {
    border-color: blue;
}

.vitals-schedule .graph-height {
    height: 15vh;
}

/* ----------- standard monitor size 1920x1080 ----------- */
/* ----------- standard monitor size 1366x768 ----------- */
/* ----------- standard monitor size 1280x800 ----------- */
.gcn-slider.rzslider .rz-bar {
    background: #8c8d8e;
    height: 4px;
}

.gcn-slider.rzslider .rz-selection {
    background: orange;
}

.gcn-slider.rzslider .rz-pointer {
    width: 8px;
    height: 16px;
    top: auto;
    /* to remove the default positioning */
    bottom: 0;
    background-color: #333;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.gcn-slider.rzslider .rz-pointer:after {
    display: none;
}

.gcn-slider.rzslider .rz-bubble {
    bottom: 14px;
    color: #3d4446;
}

.gcn-slider.rzslider .rz-limit {
    color: #667275;
}

.gcn-slider.rzslider .rz-tick {
    width: 1px;
    height: 10px;
    margin-left: 4px;
    border-radius: 0;
    background: #ffe4d1;
    top: -1px;
}

.gcn-slider.rzslider .rz-tick.rz-selected {
    background: orange;
}

.forecast-default {
    background-image: url("../img/icons/nav-icon-forecast-default-off.svg");
    background-repeat: no-repeat;
    height: 10vh;
}
.forecast-default.active,
.forecast-default:hover {
    background-image: url("../img/icons/nav-icon-forecast-default-on.svg");
}

.forecast-all-versions {
    background-image: url("../img/icons/nav-icon-forecast-all-versions-off.svg");
    background-repeat: no-repeat;
    height: 10vh;
}
.forecast-all-versions.active,
.forecast-all-versions:hover {
    background-image: url("../img/icons/nav-icon-forecast-all-versions-on.svg");
}

.vpp-selector {
    background-image: url("../img/icons/vpp-selector-icon-off.svg");
    background-repeat: no-repeat;
    height: 6vh;
    width: 3.5vw;
}
.vpp-selector.active,
.vpp-selector:hover {
    background-image: url("../img/icons/vpp-selector-icon-on.svg");
}

.vpp-selector-revenue {
    background-image: url("../img/icons/vpp-selector-revenue-icon-off.svg");
    background-repeat: no-repeat;
    width: 3.5vw;
    height: 6vh;
}
.vpp-selector-revenue.active,
.vpp-selector-revenue:hover {
    background-image: url("../img/icons/vpp-selector-revenue-icon-on.svg");
}

.vpp-selector-config {
    background-image: url("../img/icons/vpp-selector-config-icon-off.svg");
    background-repeat: no-repeat;
    width: 3.5vw;
    height: 6vh;
}
.vpp-selector-config.active,
.vpp-selector-config:hover {
    background-image: url("../img/icons/vpp-selector-config-icon-on.svg");
}

.vpp-selector-project {
    background-image: url("../img/icons/vpp-selector-project-icon-off.svg");
    background-repeat: no-repeat;
    width: 3.5vw;
    height: 6vh;
}
.vpp-selector-project.active,
.vpp-selector-project:hover {
    background-image: url("../img/icons/vpp-selector-project-icon-on.svg");
}

#vpp-selector-sidebar {
    background: #2f3e4c;
    height: 100%;
}

.vpp-selector-grid-header .ui-grid-header-cell .ui-grid-cell-contents {
    height: 45px;
}

.graph-export {
    background-image: url("../img/icons/icon-export.svg");
    background-repeat: no-repeat;
    height: 86px;
}
.graph-export.active,
.graph-export:hover {
    background-image: url("../img/icons/icon-export-on.svg");
}

.schedule-graph-view {
    background-repeat: no-repeat;
    height: 50px;
}
.schedule-graph-view.active {
    background-image: url("../img/icons/vpp-selector-project-icon-on.svg");
}
.schedule-graph-view.not-selected {
    background-image: url("../img/icons/vpp-selector-project-icon-off.svg");
}
.schedule-graph-view.not-selected:hover {
    background-image: url("../img/icons/vpp-selector-project-icon-on.svg");
}

.schedule-grid-view {
    background-repeat: no-repeat;
    height: 50px;
}
.schedule-grid-view.active {
    background-image: url("../img/icons/vpp-selector-project-icon-on.svg");
}
.schedule-grid-view.not-selected {
    background-image: url("../img/icons/vpp-selector-project-icon-off.svg");
}
.schedule-grid-view.not-selected:hover {
    background-image: url("../img/icons/vpp-selector-project-icon-on.svg");
}

.pmt-wizard {
    width: 500px;
    height: 500px;
}

.selected-step-wizard {
    border: 3px solid #3cd3d5;
}

.visited-step-wizard {
    border: 2px solid black;
}

.pmt-wizard__selected_value {
    font-size: 1em;
    text-align: left;
}

.pmtWizardToolTip {
    background-image: url("../img/icons/questionmark_dark20-02-01.png");
    background-repeat: no-repeat;
    height: 25px;
}
.pmtWizardToolTip.active,
.pmtWizardToolTip:hover {
    background-image: url("../img/icons/questionmark_dark20-02-01.png");
}

.pmtWizardTypeAhead {
    font-size: 1.25rem !important;
    padding: 0px !important;
}

.pmtWizardStepTitle {
    font-size: 1.7rem !important;
    text-align: center;
}

#pmt-wizard-container input {
    font-size: 1.25rem !important;
}
#pmt-wizard-container label {
    margin-top: 0px;
    margin-bottom: 0px;
}
#pmt-wizard-container .pmt-wizard-customer {
    background-image: url("../img/icons/pmt-wizard-cust-off.svg");
    background-repeat: no-repeat;
    height: 53px;
}
#pmt-wizard-container .pmt-wizard-customer.active,
#pmt-wizard-container .pmt-wizard-customer:hover {
    background-image: url("../img/icons/pmt-wizard-cust-on.svg");
}
#pmt-wizard-container .pmt-wizard-service {
    background-image: url("../img/icons/pmt-wizard-service-off.svg");
    background-repeat: no-repeat;
    height: 53px;
}
#pmt-wizard-container .pmt-wizard-service.active,
#pmt-wizard-container .pmt-wizard-service:hover {
    background-image: url("../img/icons/pmt-wizard-service-on.svg");
}
#pmt-wizard-container .pmt-wizard-add-meter {
    background-image: url("../img/icons/pmt-wizard-proposals-off.svg");
    background-repeat: no-repeat;
    height: 53px;
}
#pmt-wizard-container .pmt-wizard-add-meter.active,
#pmt-wizard-container .pmt-wizard-add-meter:hover {
    background-image: url("../img/icons/pmt-wizard-proposals-on.svg");
}
#pmt-wizard-container .pmt-wizard-generators {
    background-image: url("../img/icons/pmt-wizard-gen-off.svg");
    background-repeat: no-repeat;
    height: 53px;
}
#pmt-wizard-container .pmt-wizard-generators.active,
#pmt-wizard-container .pmt-wizard-generators:hover {
    background-image: url("../img/icons/pmt-wizard-gen-on.svg");
}
#pmt-wizard-container .pmt-wizard-project {
    background-image: url("../img/icons/pmt-wizard-proj-off.svg");
    background-repeat: no-repeat;
    height: 53px;
}
#pmt-wizard-container .pmt-wizard-project.active,
#pmt-wizard-container .pmt-wizard-project:hover {
    background-image: url("../img/icons/pmt-wizard-proj-on.svg");
}
#pmt-wizard-container .pmt-wizard-proposals {
    background-image: url("../img/icons/pmt-wizard-proposals-off.svg");
    background-repeat: no-repeat;
    width: 3.5vw;
    height: 53px;
}
#pmt-wizard-container .pmt-wizard-proposals.active,
#pmt-wizard-container .pmt-wizard-proposals:hover {
    background-image: url("../img/icons/pmt-wizard-proposals-on.svg");
}

#tariffRateInfo
    .tariff-rateinfo-grid-header
    .ui-grid-header-cell
    .ui-grid-cell-contents {
    height: 30px;
}
#tariffRateInfo label {
    font-size: 18px;
}
#tariffRateInfo ul {
    list-style: none;
    margin-left: 0px;
    font-size: 18px;
    font-weight: 300;
}
#tariffRateInfo li::before {
    content: "*";
    color: gray;
    padding-right: 10px;
}

.gcn-tooltip {
    font-size: 1em;
    display: inline-block;
    height: 18px;
    width: 16px;
    background-image: url("../img/icons/questionmark_dark20-02-01.png");
    background-repeat: no-repeat;
}
.gcn-tooltip.active,
.gcn-tooltip:hover {
    background-image: url("../img/icons/questionmark_dark20-02-01.png");
}

.gcn-proposals-tooltip {
    font-size: 1em;
    display: inline-block;
    height: 18px;
    width: 16px;
}

.tooltip-hover {
    position: relative;
}

.tooltip-hover .tooltiptext {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 1s;
}

.tooltip-hover .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip-hover:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

/* .browser-warning {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  color: black;
  background-color: lightyellow;
} */

.ui-grid-row:nth-child(odd) .ui-grid-cell {
    background-color: #f0f8ff;
}

.ui-grid-row:nth-child(even) .ui-grid-cell {
    background-color: #e6f3ff;
}

.ui-grid-row:last-child .ui-grid-cell {
    border-bottom-color: #d4d4d4;
    border-bottom-style: solid;
}

.ui-grid-row.ui-grid-row-selected > [ui-grid-row] > .ui-grid-cell {
    background-color: #aed9ff;
}

.ui-grid-cell-focus {
    outline: 1px solid black;
    background-color: #001324;
}

.ui-grid-category {
    text-align: center;
    border-right: 0px;
    box-shadow: -1px 1px #d4d4d4;
}

.ui-grid-header-cell .ui-grid-cell-contents {
    height: 48px;
    white-space: normal;
    -ms-text-overflow: clip;
    -o-text-overflow: clip;
    text-overflow: clip;
    overflow: visible;
    background-color: #3cd3d5;
}

.ui-grid-filter-container {
    padding: 0px !important;
    background-color: #3cd3d5;
    text-align: right;
}

/*------------------------------------*\
    #SETTINGS | Variables
\*------------------------------------*/
/* COLORS ----------------------------*/
/* SPEEDS ----------------------------*/
/* SIZES -----------------------------*/
/* OTHER VARIABLES -------------------*/
/*------------------------------------*\
    #BASE | Raw styles
\*------------------------------------*/
/**
 * The very core styling of the button.
 * These styles are shared by every instance of the button.
 * Styles placed here should NOT care about placement in the screen,
 * options chosen by the user or state of the button.
 */
.mfb-component,
.mfb-component--tl,
.mfb-component--tr,
.mfb-component--bl,
.mfb-component--br,
.mfb-component--center {
    box-sizing: border-box;
    margin: 25px;
    position: fixed;
    white-space: nowrap;
    z-index: 30;
    padding-left: 0;
    list-style: none;
}

.mfb-component *,
.mfb-component--tl *,
.mfb-component--tr *,
.mfb-component--bl *,
.mfb-component--br *,
.mfb-component--center *,
.mfb-component *:before,
.mfb-component--tl *:before,
.mfb-component--tr *:before,
.mfb-component--bl *:before,
.mfb-component--br *:before,
.mfb-component--center *:before,
.mfb-component *:after,
.mfb-component--tl *:after,
.mfb-component--tr *:after,
.mfb-component--bl *:after,
.mfb-component--br *:after,
.mfb-component--center *:after {
    box-sizing: inherit;
}

/*------------------------------------*\
    #BASE | Modifiers
\*------------------------------------*/
/**
 * These styles depends on the placement of the button.
 * Styles can be:
 * 1. Top-left:  modified by the " --tl " suffix.
 * 2. Top-right: modified by the " --tr " suffix.
 * 3. Bottom-left:  modified by the " --bl " suffix.
 * 4. Bottom-right: modified by the " --br " suffix.
 */
.mfb-component--tl {
    left: 0;
    top: 0;
}

.mfb-component--tr {
    right: 0;
    top: 0;
}

.mfb-component--bl {
    left: 0;
    bottom: 0;
}

.mfb-component--br {
    right: 0;
    bottom: 0;
}

.mfb-component--center {
    right: 0;
    top: 50%;
}

/*------------------------------------*\
    #BUTTONS | Base
\*------------------------------------*/
.mfb-component__button,
.mfb-component__button--main,
.mfb-component__button--child {
    background-color: #000;
    display: inline-block;
    position: relative;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
    cursor: pointer;
    outline: none;
    padding: 0;
    position: relative;
    -webkit-user-drag: none;
    font-weight: bold;
    color: #f1f1f1;
}

/**
 * This is the unordered list for the list items that contain
 * the child buttons.
 *
 */
.mfb-component__list {
    list-style: none;
    margin: 0;
    padding: 0;
}
.mfb-component__list > li {
    display: block;
    position: absolute;
    top: 0;
    padding: 10px 0;
    margin: -10px 0;
}

/**
 * This is the basic styles for all the icons inside the main button
 */
.mfb-component__icon,
.mfb-component__main-icon--active,
.mfb-component__main-icon--resting,
.mfb-component__child-icon {
    position: absolute;
    font-size: 18px;
    text-align: center;
    line-height: 66px;
    width: 100%;
}

.mfb-component__wrap {
    padding: 25px;
    margin: -25px;
}

.mfb-component:hover-xxx .mfb-component__icon,
.mfb-component--tl:hover-xxx .mfb-component__icon,
.mfb-component--tr:hover-xxx .mfb-component__icon,
.mfb-component--bl:hover-xxx .mfb-component__icon,
.mfb-component--br:hover-xxx .mfb-component__icon,
.mfb-component--center:hover-xxx .mfb-component__icon,
.mfb-component:hover-xxx .mfb-component__main-icon--active,
.mfb-component--tl:hover-xxx .mfb-component__main-icon--active,
.mfb-component--tr:hover-xxx .mfb-component__main-icon--active,
.mfb-component--bl:hover-xxx .mfb-component__main-icon--active,
.mfb-component--br:hover-xxx .mfb-component__main-icon--active,
.mfb-component--center:hover-xxx .mfb-component__main-icon--active,
.mfb-component:hover-xxx .mfb-component__main-icon--resting,
.mfb-component--tl:hover-xxx .mfb-component__main-icon--resting,
.mfb-component--tr:hover-xxx .mfb-component__main-icon--resting,
.mfb-component--bl:hover-xxx .mfb-component__main-icon--resting,
.mfb-component--br:hover-xxx .mfb-component__main-icon--resting,
.mfb-component--center:hover-xxx .mfb-component__main-icon--resting,
.mfb-component:hover-xxx .mfb-component__child-icon,
.mfb-component--tl:hover-xxx .mfb-component__child-icon,
.mfb-component--tr:hover-xxx .mfb-component__child-icon,
.mfb-component--bl:hover-xxx .mfb-component__child-icon,
.mfb-component--br:hover-xxx .mfb-component__child-icon,
.mfb-component--center:hover-xxx .mfb-component__child-icon {
    -webkit-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
}

/*------------------------------------*\
    #BUTTONS | Modifiers
\*------------------------------------*/
.mfb-component__button--main {
    height: 66px;
    width: 66px;
    z-index: 20;
}

.mfb-component__button--child {
    height: 66px;
    width: 66px;
}

.mfb-component__main-icon--active,
.mfb-component__main-icon--resting {
    -webkit-transform: scale(1) rotate(360deg);
    transform: scale(1) rotate(360deg);
    -webkit-transition: -webkit-transform 150ms cubic-bezier(0.4, 0, 1, 1);
    transition: transform 150ms cubic-bezier(0.4, 0, 1, 1);
}

.mfb-component__main-icon--active {
    opacity: 0;
}

.mfb-component:hover-xxx .mfb-component__main-icon,
.mfb-component--tl:hover-xxx .mfb-component__main-icon,
.mfb-component--tr:hover-xxx .mfb-component__main-icon,
.mfb-component--bl:hover-xxx .mfb-component__main-icon,
.mfb-component--br:hover-xxx .mfb-component__main-icon,
.mfb-component--center:hover-xxx .mfb-component__main-icon {
    -webkit-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
}
.mfb-component:hover-xxx .mfb-component__main-icon--resting,
.mfb-component--tl:hover-xxx .mfb-component__main-icon--resting,
.mfb-component--tr:hover-xxx .mfb-component__main-icon--resting,
.mfb-component--bl:hover-xxx .mfb-component__main-icon--resting,
.mfb-component--br:hover-xxx .mfb-component__main-icon--resting,
.mfb-component--center:hover-xxx .mfb-component__main-icon--resting {
    opacity: 0;
}
.mfb-component:hover-xxx .mfb-component__main-icon--active,
.mfb-component--tl:hover-xxx .mfb-component__main-icon--active,
.mfb-component--tr:hover-xxx .mfb-component__main-icon--active,
.mfb-component--bl:hover-xxx .mfb-component__main-icon--active,
.mfb-component--br:hover-xxx .mfb-component__main-icon--active,
.mfb-component--center:hover-xxx .mfb-component__main-icon--active {
    opacity: 1;
}

.mfb-menu-hidden {
    visibility: hidden;
}

/*------------------------------------*\
    #BUTTONS | Animations
\*------------------------------------*/
/**
 * FOUNTAIN
 * When hovering the main button the child buttons
 * jump into view from outside the viewport
 */
.mfb-component--tl.mfb-fountain .mfb-component__list li,
.mfb-component--tr.mfb-fountain .mfb-component__list li {
    transform: scale(0);
}
.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(1) {
    transform: translateY(-70px) scale(0);
    transition: all 0.5s;
    transition-delay: 0.15s;
}
.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(2) {
    transform: translateY(-140px) scale(0);
    transition: all 0.5s;
    transition-delay: 0.1s;
}
.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(3) {
    transform: translateY(-210px) scale(0);
    transition: all 0.5s;
    transition-delay: 0.05s;
}
.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(4) {
    transform: translateY(-280px) scale(0);
    transition: all 0.5s;
    transition-delay: 0s;
}
.mfb-component--tl.mfb-fountain:hover .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-fountain:hover .mfb-component__list li:nth-child(1) {
    transform: translateY(70px) scale(1);
    transition-delay: 0.05s;
}
.mfb-component--tl.mfb-fountain:hover .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-fountain:hover .mfb-component__list li:nth-child(2) {
    transform: translateY(140px) scale(1);
    transition-delay: 0.1s;
}
.mfb-component--tl.mfb-fountain:hover .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-fountain:hover .mfb-component__list li:nth-child(3) {
    transform: translateY(210px) scale(1);
    transition-delay: 0.15s;
}
.mfb-component--tl.mfb-fountain:hover .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-fountain:hover .mfb-component__list li:nth-child(4) {
    transform: translateY(280px) scale(1);
    transition-delay: 0.2s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li,
.mfb-component--br.mfb-fountain .mfb-component__list li,
.mfb-component--center.mfb-fountain .mfb-component__list li {
    transform: scale(0);
}
.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(1),
.mfb-component--center.mfb-fountain .mfb-component__list li:nth-child(1) {
    transform: translateY(70px) scale(0);
    transition: all 0.5s;
    transition-delay: 0.15s;
}
.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(2),
.mfb-component--center.mfb-fountain .mfb-component__list li:nth-child(2) {
    transform: translateY(140px) scale(0);
    transition: all 0.5s;
    transition-delay: 0.1s;
}
.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(3),
.mfb-component--center.mfb-fountain .mfb-component__list li:nth-child(3) {
    transform: translateY(210px) scale(0);
    transition: all 0.5s;
    transition-delay: 0.05s;
}
.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(4),
.mfb-component--center.mfb-fountain .mfb-component__list li:nth-child(4) {
    transform: translateY(280px) scale(0);
    transition: all 0.5s;
    transition-delay: 0s;
}
.mfb-component--bl.mfb-fountain:hover .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-fountain:hover .mfb-component__list li:nth-child(1),
.mfb-component--center.mfb-fountain:hover .mfb-component__list li:nth-child(1) {
    transform: translateY(-70px) scale(1);
    transition-delay: 0.05s;
}
.mfb-component--bl.mfb-fountain:hover .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-fountain:hover .mfb-component__list li:nth-child(2),
.mfb-component--center.mfb-fountain:hover .mfb-component__list li:nth-child(2) {
    transform: translateY(-140px) scale(1);
    transition-delay: 0.1s;
}
.mfb-component--bl.mfb-fountain:hover .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-fountain:hover .mfb-component__list li:nth-child(3),
.mfb-component--center.mfb-fountain:hover .mfb-component__list li:nth-child(3) {
    transform: translateY(-210px) scale(1);
    transition-delay: 0.15s;
}
.mfb-component--bl.mfb-fountain:hover .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-fountain:hover .mfb-component__list li:nth-child(4),
.mfb-component--center.mfb-fountain:hover .mfb-component__list li:nth-child(4) {
    transform: translateY(-280px) scale(1);
    transition-delay: 0.2s;
}

/**
 * SLIDE IN + FADE
 * When hovering the main button, the child buttons slide out from beneath
 * the main button while transitioning from transparent to opaque.
 *
 */
.mfb-component--tl.mfb-slidein .mfb-component__list li,
.mfb-component--tr.mfb-slidein .mfb-component__list li {
    opacity: 0;
    transition: all 0.5s;
}
.mfb-component--tl.mfb-slidein:hover .mfb-component__list li,
.mfb-component--tr.mfb-slidein:hover .mfb-component__list li {
    opacity: 1;
}
.mfb-component--tl.mfb-slidein:hover .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-slidein:hover .mfb-component__list li:nth-child(1) {
    -webkit-transform: translateY(70px);
    transform: translateY(70px);
}
.mfb-component--tl.mfb-slidein:hover .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-slidein:hover .mfb-component__list li:nth-child(2) {
    -webkit-transform: translateY(140px);
    transform: translateY(140px);
}
.mfb-component--tl.mfb-slidein:hover .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-slidein:hover .mfb-component__list li:nth-child(3) {
    -webkit-transform: translateY(210px);
    transform: translateY(210px);
}
.mfb-component--tl.mfb-slidein:hover .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-slidein:hover .mfb-component__list li:nth-child(4) {
    -webkit-transform: translateY(280px);
    transform: translateY(280px);
}

.mfb-component--bl.mfb-slidein .mfb-component__list li,
.mfb-component--br.mfb-slidein .mfb-component__list li,
.mfb-component--center.mfb-slidein .mfb-component__list li {
    opacity: 0;
    transition: all 0.5s;
}
.mfb-component--bl.mfb-slidein:hover .mfb-component__list li,
.mfb-component--br.mfb-slidein:hover .mfb-component__list li,
.mfb-component--center.mfb-slidein:hover .mfb-component__list li {
    opacity: 1;
}
.mfb-component--bl.mfb-slidein:hover .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-slidein:hover .mfb-component__list li:nth-child(1),
.mfb-component--center.mfb-slidein:hover .mfb-component__list li:nth-child(1) {
    -webkit-transform: translateY(-70px);
    transform: translateY(-70px);
}
.mfb-component--bl.mfb-slidein:hover .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-slidein:hover .mfb-component__list li:nth-child(2),
.mfb-component--center.mfb-slidein:hover .mfb-component__list li:nth-child(2) {
    -webkit-transform: translateY(-140px);
    transform: translateY(-140px);
}
.mfb-component--bl.mfb-slidein:hover .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-slidein:hover .mfb-component__list li:nth-child(3),
.mfb-component--center.mfb-slidein:hover .mfb-component__list li:nth-child(3) {
    -webkit-transform: translateY(-210px);
    transform: translateY(-210px);
}
.mfb-component--bl.mfb-slidein:hover .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-slidein:hover .mfb-component__list li:nth-child(4),
.mfb-component--center.mfb-slidein:hover .mfb-component__list li:nth-child(4) {
    -webkit-transform: translateY(-280px);
    transform: translateY(-280px);
}

/**
 * ZOOM-IN
 * When hovering the main button, the child buttons grow
 * from zero to normal size.
 *
 */
.mfb-component--tl.mfb-zoomin .mfb-component__list li,
.mfb-component--tr.mfb-zoomin .mfb-component__list li {
    transform: scale(0);
}
.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(1) {
    transform: translateY(70px) scale(0);
    transition: all 0.5s;
    transition-delay: 0.15s;
}
.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(2) {
    transform: translateY(140px) scale(0);
    transition: all 0.5s;
    transition-delay: 0.1s;
}
.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(3) {
    transform: translateY(210px) scale(0);
    transition: all 0.5s;
    transition-delay: 0.05s;
}
.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(4) {
    transform: translateY(280px) scale(0);
    transition: all 0.5s;
    transition-delay: 0s;
}
.mfb-component--tl.mfb-zoomin:hover .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-zoomin:hover .mfb-component__list li:nth-child(1) {
    transform: translateY(70px) scale(1);
    transition-delay: 0.05s;
}
.mfb-component--tl.mfb-zoomin:hover .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-zoomin:hover .mfb-component__list li:nth-child(2) {
    transform: translateY(140px) scale(1);
    transition-delay: 0.1s;
}
.mfb-component--tl.mfb-zoomin:hover .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-zoomin:hover .mfb-component__list li:nth-child(3) {
    transform: translateY(210px) scale(1);
    transition-delay: 0.15s;
}
.mfb-component--tl.mfb-zoomin:hover .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-zoomin:hover .mfb-component__list li:nth-child(4) {
    transform: translateY(280px) scale(1);
    transition-delay: 0.2s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li,
.mfb-component--br.mfb-zoomin .mfb-component__list li,
.mfb-component--center.mfb-zoomin .mfb-component__list li {
    transform: scale(0);
}
.mfb-component--bl.mfb-zoomin .mfb-component__list--closed li:nth-child(1),
.mfb-component--br.mfb-zoomin .mfb-component__list--closed li:nth-child(1),
.mfb-component--center.mfb-zoomin .mfb-component__list--closed li:nth-child(1) {
    transform: translateY(-70px) scale(0);
    transition: all 0.5s;
    transition-delay: 0.15s;
}
.mfb-component--bl.mfb-zoomin .mfb-component__list--closed li:nth-child(2),
.mfb-component--br.mfb-zoomin .mfb-component__list--closed li:nth-child(2),
.mfb-component--center.mfb-zoomin .mfb-component__list--closed li:nth-child(2) {
    transform: translateY(-140px) scale(0);
    transition: all 0.5s;
    transition-delay: 0.1s;
}
.mfb-component--bl.mfb-zoomin .mfb-component__list--closed li:nth-child(3),
.mfb-component--br.mfb-zoomin .mfb-component__list--closed li:nth-child(3),
.mfb-component--center.mfb-zoomin .mfb-component__list--closed li:nth-child(3) {
    transform: translateY(-210px) scale(0);
    transition: all 0.5s;
    transition-delay: 0.05s;
}
.mfb-component--bl.mfb-zoomin .mfb-component__list--closed li:nth-child(4),
.mfb-component--br.mfb-zoomin .mfb-component__list--closed li:nth-child(4),
.mfb-component--center.mfb-zoomin .mfb-component__list--closed li:nth-child(4) {
    transform: translateY(-280px) scale(0);
    transition: all 0.5s;
    transition-delay: 0s;
}
.mfb-component--bl.mfb-zoomin .mfb-component__list--open li:nth-child(1),
.mfb-component--br.mfb-zoomin .mfb-component__list--open li:nth-child(1),
.mfb-component--center.mfb-zoomin .mfb-component__list--open li:nth-child(1) {
    transform: translateY(-70px) scale(1);
    transition-delay: 0.05s;
}
.mfb-component--bl.mfb-zoomin .mfb-component__list--open li:nth-child(2),
.mfb-component--br.mfb-zoomin .mfb-component__list--open li:nth-child(2),
.mfb-component--center.mfb-zoomin .mfb-component__list--open li:nth-child(2) {
    transform: translateY(-140px) scale(1);
    transition-delay: 0.1s;
}
.mfb-component--bl.mfb-zoomin .mfb-component__list--open li:nth-child(3),
.mfb-component--br.mfb-zoomin .mfb-component__list--open li:nth-child(3),
.mfb-component--center.mfb-zoomin .mfb-component__list--open li:nth-child(3) {
    transform: translateY(-210px) scale(1);
    transition-delay: 0.15s;
}
.mfb-component--bl.mfb-zoomin .mfb-component__list--open li:nth-child(4),
.mfb-component--br.mfb-zoomin .mfb-component__list--open li:nth-child(4),
.mfb-component--center.mfb-zoomin .mfb-component__list--open li:nth-child(4) {
    transform: translateY(-280px) scale(1);
    transition-delay: 0.2s;
}
.mfb-component--bl.mfb-zoomin:hover-xxx .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-zoomin:hover-xxx .mfb-component__list li:nth-child(1),
.mfb-component--center.mfb-zoomin:hover-xxx
    .mfb-component__list
    li:nth-child(1) {
    transform: translateY(-70px) scale(1);
    transition-delay: 0.05s;
}
.mfb-component--bl.mfb-zoomin:hover-xxx .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-zoomin:hover-xxx .mfb-component__list li:nth-child(2),
.mfb-component--center.mfb-zoomin:hover-xxx
    .mfb-component__list
    li:nth-child(2) {
    transform: translateY(-140px) scale(1);
    transition-delay: 0.1s;
}
.mfb-component--bl.mfb-zoomin:hover-xxx .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-zoomin:hover-xxx .mfb-component__list li:nth-child(3),
.mfb-component--center.mfb-zoomin:hover-xxx
    .mfb-component__list
    li:nth-child(3) {
    transform: translateY(-210px) scale(1);
    transition-delay: 0.15s;
}
.mfb-component--bl.mfb-zoomin:hover-xxx .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-zoomin:hover-xxx .mfb-component__list li:nth-child(4),
.mfb-component--center.mfb-zoomin:hover-xxx
    .mfb-component__list
    li:nth-child(4) {
    transform: translateY(-280px) scale(1);
    transition-delay: 0.2s;
}

/*------------------------------------*\
    #LABELS | base
\*------------------------------------*/
/**
 * These are the labels associated to each button,
 * exposed only when hovering the related button.
 * They are called labels but are in fact data-attributes of
 * each button (an anchor tag).
 */
.m-item-block {
    text-align: center;
}

.m-item-label {
    padding-top: 60%;
}

[data-mfb-label]:after {
    content: attr(data-mfb-label);
    opacity: 0;
    transition: all 0.5s;
    background: rgba(0, 0, 0, 0.4);
    padding: 4px 10px;
    border-radius: 3px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 13px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    margin-top: -10.5px;
    transition: all 0.5s;
}

[data-mfb-label]:hover:after {
    content: attr(data-mfb-label);
    opacity: 1;
    transition: all 0.3s;
}

/*------------------------------------*\
    #LABELS | Modifiers
\*------------------------------------*/
.mfb-component--br [data-mfb-label]:after,
.mfb-component--tr [data-mfb-label]:after,
.mfb-component--center [data-mfb-label]:after {
    content: attr(data-mfb-label);
    right: 70px;
}

.mfb-component--tl [data-mfb-label]:after,
.mfb-component--bl [data-mfb-label]:after {
    content: attr(data-mfb-label);
    left: 70px;
}

/*------------------------------------*\
    #DEVELOPMENT | In development
\*------------------------------------*/
/**
 * This part is where unfinished code should stay.
 * When a feature is ready(sh) move these styles to their proper place.
 */
/*------------------------------------*\
    #DEVELOPMENT | Debuggers
\*------------------------------------*/
/**
 * These are mainly helpers for development. They do not have to end up
 * in production but it's handy to keep them when developing.
 */
/**
 * Apply this class to the html tag when developing the slide-in button
 */
html.mfb-slide-in {
    border-top: 5px solid teal;
}

html.mfb-debug * {
    border: 1px solid red;
}

.mfb-component--tl {
    animation: fromTop 1s 1;
    -webkit-animation: fromTop 1s 1;
}

.mfb-component--tr {
    animation: fromTop 1.3s 1;
    -webkit-animation: fromTop 1.3s 1;
}

.mfb-component--br {
    animation: fromBottom 1.6s 1;
    -webkit-animation: fromBottom 1.6s 1;
}

.mfb-component--bl {
    animation: fromBottom 1.9s 1;
    -webkit-animation: fromBottom 1.9s 1;
}

@keyframes fromBottom {
    0% {
        transform: translateY(250px);
    }
    100% {
        transform: translateY(0);
    }
}
@keyframes fromTop {
    0% {
        transform: translateY(-250px);
    }
    100% {
        transform: translateY(0);
    }
}
@-webkit-keyframes fromBottom {
    0% {
        transform: translateY(250px);
    }
    100% {
        transform: translateY(0);
    }
}
@-webkit-keyframes fromTop {
    0% {
        transform: translateY(-250px);
    }
    100% {
        transform: translateY(0);
    }
}
.gcn-grid-main {
    font-family: "Roboto", sans-serif;
}
.gcn-grid-main-menu-nav {
    position: fixed;
    width: 58px;
    z-index: 1000;
}
.gcn-grid-main-position-right {
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}
.gcn-grid-main-nav {
    position: relative;
    width: 57px;
    background: #2f3e4c;
    border-radius: 30px;
    padding: 15px 5px 10px;
    margin-bottom: 10px;
}
.gcn-grid-main-nav-item {
    position: relative;
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 10px 0;
}
.gcn-grid-main-menu-link {
    text-decoration: none !important;
}
.gcn-grid-main-icon-block {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 20px;
    line-height: 1;
    color: #fff;
}
.gcn-grid-main-menu-name {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    font-size: 11px;
    font-weight: 400;
    text-transform: capitalize;
    color: #fff;
    line-height: 1;
    padding: 0;
}

.gcn-floating-menu-main-wrapper a:focus {
    outline: 0;
}

.gcn-floating-menu-main-wrapper * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    outline: 0;
}

.gcn-floating-menu-main-wrapper a,
.gcn-floating-menu-main-wrapper span,
.gcn-floating-menu-main-wrapper * {
    font-family: "Roboto", sans-serif;
}

.gcn-floating-menu-main {
    font-family: "Roboto", sans-serif;
}

.gcn-floating-menu-main-wrapper li:focus,
.gcn-floating-menu-main-wrapper a:focus,
.gcn-floating-menu-main-wrapper span:focus,
.gcn-floating-menu-main-wrapper i:focus {
    outline: 0;
}

.gcn-floating-menu-link {
    text-decoration: none !important;
}

.gcn-floating-menu-nav ul {
    margin: 0;
    list-style: none;
}

.gcn-floating-position-left.gcn-floating-menu-nav ul,
.gcn-floating-position-right.gcn-floating-menu-nav ul {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
}

.gcn-floating-menu-nav.gcn-floating-position-left ul,
.gcn-floating-position-top-left ul,
.gcn-floating-position-bottom-left ul {
    left: 0;
}

.gcn-floating-menu-nav.gcn-floating-position-right ul,
.gcn-floating-position-top-right ul,
.gcn-floating-position-bottom-right ul {
    right: 0;
}

.gcn-floating-position-top-left ul,
.gcn-floating-position-top-right ul {
    top: 7%;
    position: fixed;
}

.gcn-floating-position-bottom-left ul,
.gcn-floating-position-bottom-right ul {
    bottom: 7%;
    position: fixed;
}

/*template 5 start*/
.gcn-floating-bar-alg .gcn-floating-menu-nav {
    position: fixed;
    z-index: 2;
}

.gcn-floating-bar-alg .gcn-floating-position-bottom-right,
.gcn-floating-bar-alg .gcn-floating-position-bottom-left {
    bottom: 5%;
}

.gcn-floating-bar-alg .gcn-floating-position-top-right,
.gcn-floating-bar-alg .gcn-floating-position-top-left {
    top: 5%;
    padding-top: 70px;
}

.gcn-floating-bar-alg .gcn-floating-position-top-right ul,
.gcn-floating-bar-alg .gcn-floating-position-top-left ul {
    padding-top: 48px;
    padding-bottom: 3px;
}

.gcn-floating-bar-alg .gcn-floating-position-right,
.gcn-floating-bar-alg .gcn-floating-position-left {
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.gcn-floating-bar-alg .gcn-floating-position-right,
.gcn-floating-bar-alg .gcn-floating-position-top-right,
.gcn-floating-bar-alg .gcn-floating-position-bottom-right {
    right: 10px;
}

.gcn-floating-bar-alg .gcn-floating-position-left,
.gcn-floating-bar-alg .gcn-floating-position-top-left,
.gcn-floating-bar-alg .gcn-floating-position-bottom-left {
    left: 10px;
}

.gcn-floating-bar-alg .gcn-floating-nav-strech-trigger,
.gcn-floating-bar-alg .gcn-floating-nav-close-trigger {
    position: relative;
    display: block;
    text-align: center;
    outline: 0;
}

.gcn-floating-bar-alg
    .gcn-floating-position-top-right
    .gcn-floating-nav-strech-trigger,
.gcn-floating-bar-alg
    .gcn-floating-position-top-left
    .gcn-floating-nav-strech-trigger {
    position: absolute;
    top: 0;
}

.gcn-floating-bar-alg
    .gcn-floating-position-top-right
    .gcn-floating-nav-strech-trigger {
    right: 0;
}

.gcn-floating-bar-alg
    .gcn-floating-position-top-left
    .gcn-floating-nav-strech-trigger {
    left: 0;
}

.gcn-floating-bar-alg
    .gcn-floating-position-top-right
    .gcn-floating-nav-close-trigger,
.gcn-floating-bar-alg
    .gcn-floating-position-top-left
    .gcn-floating-nav-close-trigger {
    position: absolute;
    top: 10px;
    right: 0;
    left: 0;
}

.gcn-floating-bar-alg .gcn-floating-nav-strech-trigger span {
    display: block;
    width: 55px;
    height: 55px;
    line-height: 55px;
    border-radius: 50%;
    background: #222;
    font-size: 20px;
    color: #fff;
}

.gcn-floating-bar-alg .gcn-floating-nav-strech-trigger span i {
    line-height: 55px;
}

.gcn-floating-bar-alg .gcn-floating-menu-nav ul li {
    margin-bottom: 10px;
}

.gcn-floating-bar-alg .gcn-floating-menu-nav ul li,
.gcn-floating-bar-alg .gcn-floating-menu-nav ul li a,
.gcn-floating-bar-alg
    .gcn-floating-menu-nav
    ul
    li
    a
    span.gcn-floating-icon-block {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.gcn-floating-bar-alg
    .gcn-floating-menu-nav
    ul
    li
    a
    span.gcn-floating-icon-block
    i,
.gcn-floating-bar-alg .gcn-floating-nav-close-trigger span {
    font-size: 20px;
    width: 100%;
    line-height: 1;
    color: #fff;
}

.gcn-floating-bar-alg
    .gcn-floating-menu-nav
    ul
    li
    a
    span.gcn-floating-menu-name {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    font-size: 11px;
    font-weight: 400;
    text-transform: capitalize;
    color: #fff;
    line-height: 1;
    padding: 0;
}

.gcn-floating-bar-alg
    .gcn-floating-menu-nav
    ul
    li
    a:hover
    span.gcn-floating-icon-block
    i,
.gcn-floating-bar-alg
    .gcn-floating-menu-nav
    ul
    li
    a:hover
    span.gcn-floating-menu-name,
.gcn-floating-bar-alg
    .gcn-floating-menu-nav
    ul
    li
    a.gcn-floating-bar-active-nav
    span.gcn-floating-icon-block
    i,
.gcn-floating-bar-alg
    .gcn-floating-menu-nav
    ul
    li
    a.gcn-floating-bar-active-nav
    span.gcn-floating-menu-name {
    color: #3cd3d5;
}

.gcn-floating-bar-alg .gcn-floating-menu-nav ul li a {
    color: #fff;
    text-align: center;
    position: relative;
    line-height: 1;
    z-index: 9;
}

.gcn-floating-bar-alg ul {
    width: 57px;
    background: #2f3e4c;
    position: relative;
    border-radius: 30px;
    padding: 15px 5px 10px;
    margin-bottom: 10px;
}

.gcn-floating-bar-alg .gcn-floating-menu-nav ul li {
    position: relative;
}

.gcn-floating-bar-alg
    .gcn-floating-menu-nav
    ul
    li
    > span.gcn-floating-bar-tooltip-title {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    font-size: 10px;
    background: #222;
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px 5px 20px;
    white-space: nowrap;
    opacity: 0;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    line-height: 1;
}

.gcn-floating-bar-alg
    .gcn-floating-menu-nav
    ul
    li
    span.gcn-floating-bar-tooltip-title:after {
    position: absolute;
    content: "\f129";
    font-family: FontAwesome;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 8px;
    font-family: 10px;
    color: #eee;
}

.gcn-floating-bar-alg
    .gcn-floating-menu-nav
    ul
    li
    span.gcn-floating-bar-tooltip-title:before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.gcn-floating-bar-alg
    .gcn-floating-position-left
    ul
    li
    span.gcn-floating-bar-tooltip-title:before,
.gcn-floating-bar-alg
    .gcn-floating-position-top-left
    ul
    li
    span.gcn-floating-bar-tooltip-title:before,
.gcn-floating-bar-alg
    .gcn-floating-menu-nav.gcn-floating-position-bottom-left
    ul
    li
    span.gcn-floating-bar-tooltip-title:before {
    border-width: 4px 6px 4px 0;
    border-color: transparent #222 transparent transparent;
    left: -5px;
}

.gcn-floating-bar-alg
    .gcn-floating-position-right
    ul
    li
    span.gcn-floating-bar-tooltip-title:before,
.gcn-floating-bar-alg
    .gcn-floating-position-top-right
    ul
    li
    span.gcn-floating-bar-tooltip-title:before,
.gcn-floating-bar-alg
    .gcn-floating-menu-nav.gcn-floating-position-bottom-right
    ul
    li
    span.gcn-floating-bar-tooltip-title:before {
    border-width: 4px 0 4px 6px;
    border-color: transparent transparent transparent #222;
    right: -5px;
}

.gcn-floating-bar-alg
    .gcn-floating-position-right
    ul
    li
    span.gcn-floating-bar-tooltip-title,
.gcn-floating-bar-alg
    .gcn-floating-position-top-right
    ul
    li
    span.gcn-floating-bar-tooltip-title,
.gcn-floating-bar-alg
    .gcn-floating-menu-nav.gcn-floating-position-bottom-right
    ul
    li
    span.gcn-floating-bar-tooltip-title {
    right: 62px;
    -webkit-transition: all 0.25s linear;
    transition: all 0.25s linear;
    backface-visibility: hidden;
}

.gcn-floating-bar-alg
    .gcn-floating-position-right
    ul
    li
    a:hover
    + span.gcn-floating-bar-tooltip-title,
.gcn-floating-bar-alg
    .gcn-floating-position-top-right
    ul
    li
    a:hover
    + span.gcn-floating-bar-tooltip-title,
.gcn-floating-bar-alg
    .gcn-floating-menu-nav.gcn-floating-position-bottom-right
    ul
    li
    a:hover
    + span.gcn-floating-bar-tooltip-title {
    right: 57px;
    opacity: 1;
}

.gcn-floating-bar-alg
    .gcn-floating-position-left
    ul
    li
    span.gcn-floating-bar-tooltip-title,
.gcn-floating-bar-alg
    .gcn-floating-position-top-left
    ul
    li
    span.gcn-floating-bar-tooltip-title,
.gcn-floating-bar-alg
    .gcn-floating-position-bottom-left
    ul
    li
    span.gcn-floating-bar-tooltip-title {
    left: 62px;
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
    backface-visibility: hidden;
}

.gcn-floating-bar-alg
    .gcn-floating-position-left
    ul
    li
    a:hover
    + span.gcn-floating-bar-tooltip-title,
.gcn-floating-bar-alg
    .gcn-floating-position-top-left
    ul
    li
    a:hover
    + span.gcn-floating-bar-tooltip-title,
.gcn-floating-bar-alg
    .gcn-floating-position-bottom-left
    ul
    li
    a:hover
    + span.gcn-floating-bar-tooltip-title {
    left: 57px;
    opacity: 1;
}

/*template 5 end*/
/*template 1 to 10*/
.gcn-floating-bar-alg ul li,
.gcn-floating-bar-alg ul li a:focus {
    outline: 0;
}

.selector-search {
    display: flex;
}
.selector-search .search-icon {
    background-color: #2f3e4c;
    border-left: none;
    position: absolute;
    right: 1px;
    top: 5px;
}
.selector-search input[type="text"] {
    border-radius: 4px 0 0 4px;
    border: 1px solid #58788b;
    border-right: none;
    box-sizing: border-box;
    color: #fff;
    background-color: #2f3e4c !important;
    outline: none;
    height: 2.9em;
    font-size: 11px;
}

.pmt-compare-select {
    padding-bottom: 0px;
    padding-top: 0px;
    height: 24px !important;
}

.gnc-vitalSchdule-Container .ag-header-cell-label {
    justify-content: center !important;
    text-align: center !important;
    font-weight: bold !important;
}
.gnc-vitalSchdule-Container .ag-body-viewport.ag-layout-normal {
    position: relative !important;
    font-size: 12px !important;
    font-family: "Open Sans", sans-serif !important;
}
.gnc-vitalSchdule-Container .ag-full-width-container {
    display: none;
}
.gnc-vitalSchdule-Container .ag-row-odd {
    background-color: #f0f8ff !important;
}
.gnc-vitalSchdule-Container .ag-row-even {
    background-color: #e6f3ff !important;
}
.gnc-vitalSchdule-Container .ag-row-hover {
    background-color: #ecf0f1 !important;
}
.gnc-vitalSchdule-Container .ag-row-selected {
    background-color: #aed9ff !important;
}
.gnc-vitalSchdule-Container .ag-cell-focus {
    border: 1px solid transparent !important;
    outline: none;
}
.gnc-vitalSchdule-Container .grid-selected .ag-row-selected {
    border-top: 1px solid #001324 !important;
    border-left: 1px solid #001324 !important;
    border-bottom: 1px solid #001324 !important;
    border-right: 1px solid #001324 !important;
    background-color: #aed9ff !important;
}
.gnc-vitalSchdule-Container .with-border {
    border: 1px solid transparent;
}

.es-datetime input[type="text"] {
    font-size: 0.8em;
    height: 2em;
    border-radius: 4px;
}

.es-datetime-invalid {
    border: 1px solid red !important;
}

.es-datetime--embed-mode {
    font-size: 0.9em;
    color: white;
    text-decoration: underline;
}

.es-datetime-overlay {
    /* A dark translucent div that covers the whole screen */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.es-datetime--greyed-out {
    opacity: 0.6;
    background-color: lightgray;
}

.es-datetime-dialog {
    /* A centered div above the overlay with a box shadow. */
    z-index: 10000 !important;
    position: fixed;
    /* Center the dialog */
    background-color: #fff;
    border: 1px solid #ccc;
}

.es-datetime-header {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
}

.es-datetime-footer {
    display: grid;
    grid-template-columns: auto;
}

.es-datetime-dialog-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    height: 100%;
    text-align: left;
}
.es-datetime-dialog-content--item {
    color: black;
    justify-self: center;
}
.es-datetime-dialog-content--item--selected {
    border-radius: 50%;
    border: 1px solid #ccc;
    background-color: dodgerblue;
    color: white;
}
.es-datetime-dialog-content--item-year {
    color: black;
    padding: 2%;
    justify-self: center;
}
.es-datetime-dialog-content--item-year-selected {
    border: 0.5px solid #ccc;
    border-radius: 50%;
}
.es-datetime-dialog-content--notcurmonth {
    color: #b3b3b3;
}

.es-datetime-dialog-content-year-month {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    height: 100%;
    text-align: left;
}
.es-datetime-dialog-content-year-month--item {
    padding: 2%;
    justify-self: center;
}
.es-datetime-dialog-content-year-month--selected {
    border-radius: 50%;
    background-color: dodgerblue;
    color: white;
    width: 25px;
    padding-left: 15%;
}
.es-datetime-dialog-content-year-month--notcurmonth {
    color: #ccc;
}

.es-datetime-dialog-time {
    /* A centered div above the overlay with a box shadow. */
    z-index: 10000 !important;
    position: fixed;
    margin-top: 3.9%;
    /* Center the dialog */
    background-color: #fff;
    border: 1px solid #ccc;
}

.es-datetime-dialog-content--time {
    text-align: left;
}
.es-datetime-dialog-content--time--item-selected {
    color: blue;
}
.es-datetime-dialog-content--time--item:hover {
    background-color: #ccc;
}

.es-datetime-close {
    position: absolute;
    top: 3px;
    right: 5px;
    padding: 5px;
    cursor: pointer;
    font-size: 120%;
    display: inline-block;
    font-weight: bold;
    font-family: "arial", "sans-serif";
}

.schedule-grid {
    height: 65vh;
}

.schedule-grid-header .ui-grid-header-cell .ui-grid-cell-contents {
    height: 20px;
}

.schedule-performance {
    height: 65vh;
}

.schedule-performance-header .ui-grid-header-cell .ui-grid-cell-contents {
    height: 20px;
}

#timezone-selector select {
    height: 100%;
    font-size: 12px;
    color: #fff;
    border-radius: 5px;
    background-color: inherit;
    background-image: url("../img/header/white_downpointing_triangle.png");
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
}
#timezone-selector .tz-greyed-out {
    opacity: 0.6;
}

#tos_modal_container {
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
}

@media only screen and (max-width: 70vw) {
    #tos_modal_container {
        width: auto;
        margin: 0;
        position: static;
        transform: none;
    }
}
#tos_footer {
    color: #4d8187;
    font-size: 12px;
    margin-top: 7px;
}
#tos_footer a {
    color: #4d8187;
    text-decoration: none;
    float: right;
}
#tos_footer a:hover {
    text-decoration: underline;
}

#tos_modal {
    background-color: #f4f8f8;
    border-radius: 10px;
    color: #4d8187;
    text-align: center;
    height: 90vh;
}
#tos_modal .pdf_container {
    height: calc(90vh - 230px);
    border: 3px solid #5fd9db;
}

#iap_container {
    margin-top: 1%;
    border-radius: 4px;
    background-color: #f4f8f8;
}
#iap_container .page-head-text {
    margin-top: 0.8em;
    color: #4d8187;
    font-size: 15px;
}
#iap_container .open {
    display: block !important;
}
#iap_container .hide {
    display: none !important;
}
#iap_container .width100 {
    width: 100% !important;
}
#iap_container .equal-width {
    width: 33.33%;
}
#iap_container .same-width {
    width: 50%;
}
#iap_container .reverse-direction {
    flex-direction: row-reverse;
}
#iap_container .radio-selection {
    padding-left: 0px;
    width: 100%;
    padding-right: 10px;
}
#iap_container .space-even {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#iap_container .warning-txt-font {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
}
#iap_container .pl-none {
    padding-left: 0px;
}
#iap_container .pl15 {
    padding-left: 15px;
}
#iap_container .h80-scroll-x {
    height: 80px;
    overflow-x: auto;
}
#iap_container .h100-scroll-x {
    height: 100px;
    overflow-x: auto;
}
#iap_container .h200-scroll-x {
    height: 200px;
    overflow-x: auto;
}
#iap_container .scroll-x {
    height: 625px;
    overflow-x: auto;
}
#iap_container .full-width {
    width: 100% !important;
}
#iap_container .static-data {
    padding: 0px 15px 0px 15px;
}
#iap_container .static-data .ipa-form-tab {
    display: flex;
}
#iap_container .static-data .ipa-form-tab .ipa-form-lbl {
    width: 25% !important;
}
#iap_container .static-data .ipa-form-tab .ipa-form-lbl-txt {
    width: 75% !important;
}
#iap_container .static-data .ipa-form-tab .ipa-form-lbl-txt .lbl-txt {
    padding: 5px 5px 5px;
    margin: 0px 5px 5px;
    display: inline-block;
    font-weight: 500;
    background-color: #5fd9db;
    border-radius: 5px;
}
#iap_container .dropdown-search-ul {
    display: block;
    height: 100px;
    overflow-x: auto;
    right: 0;
    left: 0;
    width: 100%;
    margin: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: none;
    position: static;
    box-shadow: none;
}
#iap_container .modal {
    position: fixed;
    display: block;
    background: rgba(0, 0, 0, 0.5);
}
#iap_container .modal .modal-dialog .modal-content .modal-header {
    display: flex;
}
#iap_container .modal .modal-dialog .modal-content .modal-header h4 {
    color: #2f3e4c;
    text-transform: capitalize;
    font-weight: 400;
}
#iap_container .modal .modal-dialog .modal-content .modal-header .modal-title {
    width: 97%;
}
#iap_container .modal .modal-dialog .modal-content .modal-body {
    text-transform: capitalize;
    height: 250px;
    overflow-x: auto;
}
#iap_container .modal .modal-dialog .modal-content .modal-body .warning-txt {
    font: 500 16px/16px "Halvetica";
    color: #000000;
}
#iap_container .dropdown-search-li span {
    padding: 5px 5px 5px !important;
}
#iap_container .dropdown-search-li span:first-child {
    width: 85%;
    text-overflow: clip;
    overflow-x: hidden;
}
#iap_container .dropdown-search-li span:last-child {
    width: 15%;
}
#iap_container .dropdown-search-li span:hover {
    color: #f5f5f5;
    text-decoration: none;
    background-color: #2f3e4c;
}
#iap_container .dropdown-search-li a {
    padding: 5px 5px 5px !important;
}
#iap_container .dropdown-search-li a:first-child {
    width: 85%;
    text-overflow: clip;
    overflow-x: hidden;
}
#iap_container .dropdown-search-li a:last-child {
    width: 15%;
}
#iap_container .dropdown-search-li .text-danger:hover {
    color: #a94442;
}
#iap_container input[type="submit"],
#iap_container .green-button {
    background-color: #26ac82;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    padding: 10px 20px;
    -webkit-appearance: none;
    margin-right: 20%;
}
#iap_container .space-even {
    display: flex !important;
    align-items: center !important;
    margin: 0 auto;
    padding-bottom: 20px;
}
#iap_container .iap-content {
    margin-left: 0;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
#iap_container .iap-content .ipa-detail-container .ipa-info-txt {
    color: #3cd3d5;
    font-weight: 200;
}
#iap_container
    .iap-content
    .ipa-detail-container
    .ipa-form-data
    .ipa-form-data-container:first-child {
    width: 40%;
}
#iap_container
    .iap-content
    .ipa-detail-container
    .ipa-form-data
    .ipa-form-data-container:last-child {
    width: 60%;
}
#iap_container .iap-content .ipa-detail-container .ipa-form-data .ipa-form-tab {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
}
#iap_container
    .iap-content
    .ipa-detail-container
    .ipa-form-data
    .ipa-form-tab
    .ipa-form-data-info {
    width: 75%;
}
#iap_container
    .iap-content
    .ipa-detail-container
    .ipa-form-data
    .ipa-form-tab
    .ipa-form-lbl-head {
    width: 100%;
    display: flex;
}
#iap_container .iap-content .ipa-detail-container .ipa-form-data .subject {
    border-radius: 4px;
    border: 1px solid #58788b;
    box-sizing: border-box;
    color: #fff;
    background-color: #2f3e4c;
    outline: none;
    font-size: 12px;
    width: 150px;
}
#iap_container .iap-content .ipa-detail-container .ipa-form-data .ipa-form-lbl {
    margin-right: 10px;
    width: 25%;
}

.tariff-container {
    width: calc(100%);
    height: calc(100vh - 80px);
    white-space: nowrap;
    box-sizing: border-box;
    background: #e6f3ff;
    justify-content: space-between;
    align-content: stretch;
}
.tariff-container .selector-container-tariff {
    width: 100%;
    height: 100%;
}
.tariff-container .rg-right {
    margin-right: 1px;
    height: 40px;
}
.tariff-container .tariff_enabled {
    color: green;
    font-size: 18px;
}
.tariff-container .tariff_disabled {
    color: red;
    font-size: 18px;
}
.tariff-container .selected-selector__selected:hover .tariff_enabled,
.tariff-container .selected-selector__last:hover .tariff_enabled {
    color: green;
    font-size: 18px;
    cursor: pointer;
}
.tariff-container .selected-selector__selected:hover .tariff_disabled,
.tariff-container .selected-selector__last:hover .tariff_disabled {
    color: red;
    font-size: 18px;
    cursor: pointer;
}
.tariff-container .ag-header-cell-label {
    justify-content: center !important;
    text-align: center !important;
    font-weight: bold !important;
}
.tariff-container .ag-body-viewport.ag-layout-normal {
    position: relative !important;
    font-size: 14px !important;
    font-family: "Open Sans", sans-serif !important;
}
.tariff-container .ag-full-width-container {
    display: none;
}
.tariff-container .ag-row-odd {
    background-color: #f0f8ff !important;
}
.tariff-container .ag-row-even {
    background-color: #e6f3ff !important;
}
.tariff-container .ag-row-hover {
    background-color: #ecf0f1 !important;
}
.tariff-container .ag-row-selected {
    background-color: #aed9ff !important;
}
.tariff-container .ag-cell-focus {
    border: 1px solid transparent !important;
    outline: none;
}
.tariff-container .grid-selected .ag-row-selected {
    border-top: 1px solid #001324 !important;
    border-left: 1px solid #001324 !important;
    border-bottom: 1px solid #001324 !important;
    border-right: 1px solid #001324 !important;
    background-color: #aed9ff !important;
}

#pmt-wizard-container_react {
  background: white;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  width: 100%;
  margin-top: 10px;
  overflow: auto;
  min-height: 500px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
#pmt-wizard-container_react .nav-tabs {
  font-size: 16px;
  font-weight: bold;
}

#pmt-wizard-container_react .nav-tabs > li a.nav-link.disabled {
  color: lightgrey;
}

#pmt-wizard-container_react .nav-tabs > li a.nav-link.active {
  color: #FFF;
  background-color: #7490aa;
}

#pmt-wizard-main, .pmt-wizard-main {
  position: relative;
  overflow: auto;
  min-height: 600px;
}
#pmt-wizard-main .pmt-wizard-content, .pmt-wizard-main .pmt-wizard-content {
  overflow: auto;
  height: 75vh;
  min-height: 500px;
}
#pmt-wizard-main .pmt-wizard-custom-modal, .pmt-wizard-main .pmt-wizard-custom-modal {
  width: 400px;
}
#pmt-wizard-main .pmt-wizard-modal-content, .pmt-wizard-main .pmt-wizard-modal-content {
  overflow: auto;
  max-height: 64vh;
}
#pmt-wizard-main .pmt-wizard-modal-content .well, .pmt-wizard-main .pmt-wizard-modal-content .well {
  margin-bottom: 1px;
}
#pmt-wizard-main .wizard-toggle-label, .pmt-wizard-main .wizard-toggle-label {
  background: white !important;
  color: blue !important;
  border: white;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-size: 16px !important;
}
#pmt-wizard-main .wizard-toggle-label.active, .pmt-wizard-main .wizard-toggle-label.active {
  background: #e6e6e6 !important;
}
#pmt-wizard-main .pmt-wizard-row, .pmt-wizard-main .pmt-wizard-row {
  padding-bottom: 5px;
}
#pmt-wizard-main .pmt-wizard-head, .pmt-wizard-main .pmt-wizard-head {
  font-size: 20px;
  padding-bottom: 20px;
  font-weight: bold;
  text-align: center;
}
#pmt-wizard-main .pmt-wizard-head1, .pmt-wizard-main .pmt-wizard-head1 {
  font-size: 16px;
  font-weight: bold;
}
#pmt-wizard-main .btn-default.active, #pmt-wizard-main .btn-default:active, .pmt-wizard-main .btn-default.active, .pmt-wizard-main .btn-default:active {
  color: white;
  background-color: black;
  border-color: #adadad;
}
#pmt-wizard-main .btn-group > .btn, .pmt-wizard-main .btn-group > .btn {
  padding-bottom: 3px;
  padding-top: 3px;
  min-width: 69px;
  padding-left: 10px;
  padding-right: 10px;
}
#pmt-wizard-main .pmt-wizard-btn-grey, .pmt-wizard-main .pmt-wizard-btn-grey {
  color: white;
  background: grey;
}
#pmt-wizard-main h4, #pmt-wizard-main h5, #pmt-wizard-main h6, .pmt-wizard-main h4, .pmt-wizard-main h5, .pmt-wizard-main h6 {
  color: black;
}
#pmt-wizard-main input, .pmt-wizard-main input {
  font-size: 0.8rem !important;
  height: 28px;
}
#pmt-wizard-main .gcn-ft-size, .pmt-wizard-main .gcn-ft-size {
  height: 28px;
}
#pmt-wizard-main .react-datepicker-wrapper, .pmt-wizard-main .react-datepicker-wrapper {
  width: 100%;
}
#pmt-wizard-main .react-datepicker-wrapper .react-datepicker__input-container, .pmt-wizard-main .react-datepicker-wrapper .react-datepicker__input-container {
  width: 100%;
}

.pmt-wizard-main {
  min-height: 200px;
}

.react-select__control {
  min-height: 20px !important;
  border-color: #cccccc !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) !important;
}
.react-select__control .css-1g6gooi {
  margin: 0px;
  padding-top: 0px;
}

.react-select__input {
  height: 20px;
}

.react-select__indicators {
  display: none;
}

.react-select__menu {
  z-index: 1000 !important;
}

.react-select__dropdown-indicator {
  padding: 3px !important;
}

#pmt-wizard-main .btn-group.btn-group-toggle .btn.active.btn-primary {
    color: #fff;
    background-color: #000;
    border-color: #adadad
}

#pmt-wizard-main .btn-group.btn-group-toggle .btn.btn-primary {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

#pmt-wizard-main .pmt-wizard-content label  {
    font-weight: 700;
}

#pmt-wizard-main .pmt-wizard-content .checkbox-inline {
    padding-left: 20;
}

.gs-ag-grid-flag-btn-off {
   cursor: pointer; 
   color: lavenderblush;
   padding-right: 5px;
}

.gs-ag-grid-flag-btn-on {
    cursor: pointer;
    padding-right: 5px;
}